import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '@uidotdev/usehooks';
import i18n from 'locale';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authApi, axiosInstance, usersApi } from 'api';
import { CurrentUser } from 'types/CurrentUser';
import { PageLoading } from 'components/PageLoading';
import { AuthPayload } from 'types/payloads/AuthPayload';
import { Memberships } from 'types/Memberships';
import { useGlobal } from './globalContext';

const AUTH_REFRESH_TOKEN_NAME = 'authRefreshToken';
const AUTH_REMEMBER_TOKEN_NAME = 'authRememberToken';

type AuthContextType = {
	loading: boolean;
	currentUser: CurrentUser | undefined;
	currentMemberships: Memberships[];
	currentUserRefetch: () => void;
	authenticate: (
		tokens: { refreshToken: string; accessToken: string; rememberToken?: string },
		onSuccess?: () => void
	) => void;
	logout: () => void;
	isLogged: boolean;
	ready: boolean;
	rememberToken: string | null;
};

export const AuthContext = createContext<AuthContextType>({
	loading: true,
	currentUser: undefined,
	currentMemberships: [],
	currentUserRefetch: () => {},
	authenticate: () => {},
	logout: () => {},
	isLogged: false,
	ready: false,
	rememberToken: null,
});

// eslint-disable-next-line @typescript-eslint/ban-types
type AuthStateProviderProps = PropsWithChildren<{}>;

export const AuthContextProvider = ({ children }: AuthStateProviderProps) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { toastRef } = useGlobal();

	// manage tokens
	const getRefreshTokenFromLocalStorage = () => {
		const result = window.localStorage.getItem(AUTH_REFRESH_TOKEN_NAME);
		if (result && result.length > 0) {
			return result;
		}
		return null;
	};
	const saveRefreshTokenInLocalStorage = (refreshToken: string) => {
		if (refreshToken) {
			window.localStorage.setItem(AUTH_REFRESH_TOKEN_NAME, refreshToken);
		}
	};
	const removeRefreshTokenFromLocalStorage = () => window.localStorage.removeItem(AUTH_REFRESH_TOKEN_NAME);

	// local state
	const [destroyProcess, setDestroyProcess] = useState(false);
	const [refreshTokenLoading, setRefreshTokenLoading] = useState<boolean>(Boolean(getRefreshTokenFromLocalStorage()));
	const [localRefreshToken, setLocalRefreshToken] = useState<string | null>(getRefreshTokenFromLocalStorage());
	const prevLocalRefreshToken = usePrevious(localRefreshToken);
	const [localAccessToken, setLocalAccessToken] = useState<string | null>(null);
	const prevLocalAccessToken = usePrevious(localRefreshToken);
	const [axiosInterceptors, setAxiosInterceptors] = useState<{ request?: number; response?: number }>({
		request: undefined,
		response: undefined,
	});
	const [ready, setReady] = useState(false);
	// const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);

	// computed
	const hasInterceptors = Boolean(
		axiosInterceptors.request !== undefined && axiosInterceptors.response !== undefined
	);

	// axios interceptor ejector
	const ejectInterceptors = useCallback(() => {
		setAxiosInterceptors(({ request, response }) => {
			if (request !== undefined) {
				axiosInstance.interceptors.request.eject(request);
			}
			if (response !== undefined) {
				axiosInstance.interceptors.response.eject(response);
			}
			return {
				request: undefined,
				response: undefined,
			};
		});
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
	}, []);

	useEffect(() => {
		if (destroyProcess) {
			removeRefreshTokenFromLocalStorage();
			setLocalRefreshToken(null);
			setLocalAccessToken(null);
			removeCurrentUser();
			// setCurrentUser(null);
			ejectInterceptors();
			setTimeout(() => {
				// window.localStorage.clear();
				setDestroyProcess(false);
			}, 1000);
		}
		// eslint-disable-next-line @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps
	}, [destroyProcess, ejectInterceptors]);

	const logout = useCallback(() => {
		// destroySession();
		setDestroyProcess(true);
		navigate({ pathname: '/login', search: '?logout=true' });
		toastRef?.current?.show({ severity: 'info', detail: t('auth.pageAuthRequired') });
		queryClient.clear(); // IMPORTANT - clear all stored cache data

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toastRef]);

	// on success, sets new access token to local state
	const [refreshTokenRequestCache, setRefreshTokenRequestCache] = useState<Promise<AuthPayload> | undefined>(
		undefined
	);
	const refreshTokenRequest = useCallback((refreshToken: string) => {
		if (refreshTokenRequestCache) {
			return refreshTokenRequestCache;
		}

		const promise: Promise<AuthPayload> = authApi
			.refreshToken(refreshToken)
			.then((response) => {
				const { accessToken } = response;
				saveRefreshTokenInLocalStorage(refreshToken);
				setLocalAccessToken(accessToken);
				// do not set refresh token oading to falst in this place
				// to do faster first user loading
				return response;
			})
			.catch(() => {
				toastRef?.current?.show({
					severity: 'info',
					detail: t('auth.loggedOut'),
				});
				setDestroyProcess(true);
				return undefined;
			})
			.finally(() => {
				setRefreshTokenRequestCache(undefined);
			}) as Promise<AuthPayload>;

		setRefreshTokenRequestCache(promise);

		return promise;
	}, []);

	// runs after refresh token update
	useEffect(() => {
		// if is set and is diffrent from previous
		if (!localRefreshToken) {
			setReady(true);
			setRefreshTokenLoading(false);
		} else if (!destroyProcess && localRefreshToken && localRefreshToken !== prevLocalRefreshToken) {
			refreshTokenRequest(localRefreshToken);
		}
	}, [destroyProcess, localRefreshToken, prevLocalRefreshToken, refreshTokenRequest]);

	// runs afrer access token update
	useEffect(() => {
		if (!destroyProcess && localAccessToken && localAccessToken !== prevLocalAccessToken) {
			setAxiosInterceptors(({ request: prevRequest, response: prevResponse }) => {
				// add request interceptor
				// if (prevRequest) {
				// 	axiosInstance.interceptors.request.eject(prevRequest);
				// }
				// const request = axiosInstance.interceptors.request.use((config) => {
				// 	// eslint-disable-next-line no-param-reassign
				// 	config.headers = {
				// 		...config.headers,
				// 		Authorization: `Bearer ${localAccessToken}`,
				// 	};
				// 	return config;
				// });
				// request interceptor not working propertly
				// use pseudo-interceptor
				const request = (() => {
					axiosInstance.defaults.headers.common.Authorization = `Bearer ${localAccessToken}`;
					return (prevRequest || 0) + 1;
				})();

				// add response interceptor
				if (prevResponse) {
					axiosInstance.interceptors.response.eject(prevResponse);
				}
				const response = axiosInstance.interceptors.response.use(
					(response) => response,
					async (error) => {
						const previousRequest = error.config;
						if (error) {
							if (
								error.response?.status === 401 &&
								!previousRequest.retry &&
								previousRequest.url !== 'auth/refresh'
							) {
								ejectInterceptors();
								setLocalAccessToken(null);
								// setRefreshTokenLoading(true);
								previousRequest.retry = true;

								// remove default auth header
								delete axiosInstance.defaults.headers.common.Authorization;

								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								const { accessToken } = await refreshTokenRequest(localRefreshToken!);

								// sets new auth header
								axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
								previousRequest.headers.Authorization = `Bearer ${accessToken}`;

								return axiosInstance(previousRequest).then((response) => {
									setRefreshTokenLoading(false);
									return response;
								});
								// .catch(() => {
								// 	toastRef?.current?.show({
								// 		severity: 'error',
								// 		detail: t('auth.authErrorOccurred'),
								// 	});
								// 	setDestroyProcess(true);
								// });
							}
						}

						return Promise.reject(error);
					}
				);

				// result
				return { request, response };
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localAccessToken, prevLocalAccessToken]);

	const {
		data: currentUser,
		refetch: currentUserRefetch,
		remove: removeCurrentUser,
		isLoading: currentUserLoading,
		isFetching: currentUserFetching,
	} = useQuery(['auth', 'me'], authApi.me, {
		retry: false,
		enabled: Boolean(!destroyProcess && hasInterceptors && localAccessToken && localRefreshToken), // enable for auto-fetch
		// enabled: false, // disable for manual fetch
		onSuccess: (data) => {
			setRefreshTokenLoading(false);
			setReady(true);
			if (successAuthenticateCallback) {
				successAuthenticateCallback();
				setSuccessAuthenticateCallback(undefined);
			}
			if (i18n.language !== data.language) {
				i18n.changeLanguage(data.language);
			}
		},
		onError: () => {
			setReady(true);
		},
	});

	const { data: currentMemberships } = useQuery(['auth', 'memberships'], usersApi.getMemberships, {
		retry: false,
		enabled: Boolean(!destroyProcess && hasInterceptors && localAccessToken && localRefreshToken), // enable for auto-fetch
		initialData: [],
	});

	// manual fetch
	// useEffect(() => {
	// 	if (!currentUser) {
	// 		if (!destroyProcess && hasInterceptors && localAccessToken && localRefreshToken) {
	// 			currentUserRefetch();
	// 		}
	// 	}
	// }, [
	// 	currentUser,
	// 	currentUserRefetch,
	// 	destroyProcess,
	// 	hasInterceptors,
	// 	localAccessToken,
	// 	localRefreshToken,
	// 	prevLocalAccessToken,
	// ]);

	const [successAuthenticateCallback, setSuccessAuthenticateCallback] = useState<() => void>();
	const authenticate = (
		{
			refreshToken,
			accessToken,
			rememberToken,
		}: { refreshToken: string; accessToken: string; rememberToken?: string },
		onSuccess?: () => void
	) => {
		if (destroyProcess) {
			// eslint-disable-next-line no-console
			console.error('[auth] cannot authenticatein destroy process mode');
		} else {
			if (onSuccess) {
				setSuccessAuthenticateCallback(onSuccess);
			}
			setLocalAccessToken(accessToken);
			setLocalRefreshToken(refreshToken);

			if (rememberToken) {
				window.localStorage.setItem(AUTH_REMEMBER_TOKEN_NAME, rememberToken);
			}
		}
	};

	// computed state
	const loading = refreshTokenLoading || currentUserLoading || currentUserFetching || destroyProcess;
	// const loading =  destroyProcess;
	const isLogged = ready && Boolean(currentUser);

	// useEffect(() => {
	// 	const refreshToken = getRefreshTokenFromLocalStorage();
	// 	if (refreshToken) {
	// 		setRefreshTokenLoading(Boolean(refreshToken));
	// 		setLocalRefreshToken(refreshToken);
	// 	}
	// }, []);

	const rememberToken = window.localStorage.getItem(AUTH_REMEMBER_TOKEN_NAME);

	if (!ready || refreshTokenLoading) {
		return <PageLoading text={t('auth.authorizationMessage')} />;
	}

	return (
		<AuthContext.Provider
			value={{
				loading,
				currentUser,
				currentMemberships,
				currentUserRefetch,
				authenticate,
				logout,
				isLogged,
				ready,
				rememberToken,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
