import { httpClient } from 'api';
import { SendUserFilePayload } from 'types/payloads/SendUserFilePayload';
import { SessionFile } from 'types/SessionFile';

export const userFilesApi = {
	uploadUserFile: (membershipId: number, applicationId: number, pairId: number, payload: SendUserFilePayload) => {
		const formData = new FormData();
		Object.entries(payload).map(([k, v]) => formData.append(k, v));
		return httpClient.post(`user-files/${membershipId}/${applicationId}/${pairId}`, formData);
	},
	deleteUserFile: (id: number) => httpClient.delete(`user-files/${id}`),
	editUserFile: (id: number, payload: SendUserFilePayload) => {
		const formData = new FormData();
		Object.entries(payload).map(([k, v]) => formData.append(k, v));
		return httpClient.put(`user-files/${id}`, formData);
	},
	getUserfile: (id: number) =>
		httpClient.get<{ userFile: SessionFile; signedUrl: string | null }>(`user-files/get/${id}`),
	// getUserfileUrl: (id: number) => httpClient.get<string>(`user-files/get/${id}/url`),
	getUserfiles: (membershipId: number, applicationId: number, pairId: number, params?: any) =>
		httpClient.get<SessionFile[]>(`user-files/${membershipId}/${applicationId}/${pairId}`, params),
};
