import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { usePanel } from 'contexts/panelContext';
import { useGlobal } from 'contexts/globalContext';
import { userPanelApi } from 'api';
import { SessionType } from 'types/Session';
import { Goal } from 'types/Goal';
import { QuestionnaireAnswer, SessionQuestionnaire } from 'types/SessionQuestionnaire';
import {
	CreateSessionQuestionnairePayload,
	UpdateSessionQuestionnairePayload,
} from 'types/payloads/SessionQuestionnairePayload';
import { ButtonGroupYesNo } from 'components/_new/ButtonGroup';
import { colors } from 'theme';
import { Box, BoxSection } from 'components/_new/Box';
import { StepSlider } from 'components/_new/Slider';
import { Icon } from 'components/_new/Icon';
import { Line } from 'components/_new/Line';
import { Button } from 'components/_new/Button';
import { InputTextArea } from 'components/_new/InputTextArea';
import { MessageBox } from 'components/_new/MessageBox';
import { usePair } from 'contexts/pairContext';
import { RouterUrlParams } from '../../../../App';
import { useSessionsData } from '../Sessions';

interface ProgressSurveyFormObject {
	goals: QuestionnaireAnswer;
	menteeEngagement: number;
	mentorEngagement: number;
	menteeSatisfaction: number;
	standards?: boolean;
	schedule?: boolean;
	contract?: boolean;
	goalChanges?: string;
}
interface Props {
	pairId?: number;
	sessionType: SessionType;
	questionnairesData?: SessionQuestionnaire[];
	refetchQuestionnaires?: any;
}

const ProgressSurveyModule: FC<Props> = ({ sessionType, questionnairesData, refetchQuestionnaires }) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams() as RouterUrlParams;

	const { toastRef } = useGlobal();
	const { currentProgramMembership, panelType } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();
	const { sessiosRefetch } = useSessionsData();

	const currentQuestionnaire = questionnairesData?.find((questionnaire) => questionnaire.forMentorOnly === false);
	const questionnaireAnswers = currentQuestionnaire?.questionnaireAnswers;
	const questionaireGoalsAnswers = questionnaireAnswers?.goals as QuestionnaireAnswer;
	const { data: goalsData, isSuccess: goalsSuccess } = useQuery(
		['goals', { programMembershipId: currentProgramMembership.id, pairId }],
		() => userPanelApi.getGoals(pairId, currentProgramMembership.id),
		{
			refetchOnWindowFocus: false,
			enabled: true,
			onSuccess: (data) => {
				reset({
					goals: {
						...data?.goals.reduce(
							(goalsValueObject: QuestionnaireAnswer, goal) => ({
								...goalsValueObject,
								[`goal-${goal.id}`]:
									(questionaireGoalsAnswers && questionaireGoalsAnswers[`goal-${goal.id}`]) ?? 0,
							}),
							{}
						),
					},
					menteeEngagement: (questionnaireAnswers?.menteeEngagement as number) ?? 0,
					mentorEngagement: (questionnaireAnswers?.mentorEngagement as number) ?? 0,
					menteeSatisfaction: (questionnaireAnswers?.menteeSatisfaction as number) ?? 0,
					...lastSessionDefaultFieldsValues,
				});
			},
		}
	);

	const lastSessionDefaultFieldsValues =
		sessionType === 'last'
			? {
					standards: (questionnaireAnswers?.standards as boolean) ?? undefined,
					schedule: (questionnaireAnswers?.schedule as boolean) ?? undefined,
					contract: (questionnaireAnswers?.contract as boolean) ?? undefined,
					goalChanges: (questionnaireAnswers?.goalChanges as string) ?? '',
			  }
			: undefined;

	const { control, watch, handleSubmit, reset } = useForm<ProgressSurveyFormObject>();

	const { mutate: createQuestionnaire, isLoading: isCreateSubmitting } = useMutation(
		(data: CreateSessionQuestionnairePayload) =>
			userPanelApi.createQuestionnaire(+pairId, currentProgramMembership.id, data),
		{
			onSuccess: () => {
				refetchQuestionnaires();
				sessiosRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.successfullSessionEditTooltip'),
				});
			},
		}
	);

	const { mutate: updateQuestionnaire, isLoading: isUpdateSubmitting } = useMutation(
		(data: UpdateSessionQuestionnairePayload) =>
			userPanelApi.updateQuestionnaire(+pairId, currentProgramMembership.id, sessionType, data),
		{
			onSuccess: () => {
				refetchQuestionnaires();
				sessiosRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.successfullSessionEditTooltip'),
				});
			},
		}
	);

	const onSubmit = handleSubmit((data) => {
		if (!questionnairesData?.length || !currentQuestionnaire) {
			createQuestionnaire({
				sessionType,
				forMentorOnly: false,
				questionnaireAnswers: { ...data },
			});
		} else {
			updateQuestionnaire({
				id: currentQuestionnaire.id,
				questionnaireAnswers: { ...data },
			});
		}
	});

	const renderSliderLabel = (goal: Goal, index: number) => (
		<div className="flex flex-column row-gap-3">
			<div className="flex column-gap-3">
				<div className="w-2">{`${t('userPanel.goal.goal')} #${index + 1}`}</div>

				<div className="flex flex-column row-gap-2">
					<div>{`${t('userPanel.goal.goal')}: ${goal.goal}`}</div>
					<div>
						{`${t('userPanel.sessions.mentorEvaluation.criteria')}: `}
						{goal.change || (
							<Trans
								t={t}
								i18nKey="userPanel.sessions.mentorEvaluation.noCriteria"
								components={[
									<Link
										to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentee-plan`}
										className="text-purplish-blue underline"
									/>,
								]}
							/>
						)}
					</div>
					<div style={{ color: colors.gray }}>
						{`${t('userPanel.sessions.mentorEvaluation.goalRealization')}: ${
							((watch(`goals.goal-${goal.id}`) as number) ?? 0) * 10
						}%`}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<form className="flex flex-column row-gap-5 pt-3" onSubmit={onSubmit}>
			{goalsSuccess && goalsData && (
				<>
					{goalsData.goals.length === 0 && (
						<MessageBox
							variant="purple"
							title={t('userPanel.sessionsNew.progressSurvey.enterGoalsInMenteeDevelopmentPlan')}
							icon="info-circle"
							iconSet="pi"
						/>
					)}
					{goalsData.goals.length > 0 && (
						<Box variant="white-bordered">
							{goalsData.goals.map((goal, index) => (
								<Controller
									key={goal.id}
									name={`goals.goal-${goal.id}`}
									control={control}
									render={({ field: { ref, onChange, value } }) => (
										<BoxSection
											header={() => (
												<div className="flex flex-row gap-2 justify-content-start align-items-center">
													{renderSliderLabel(goal, index)}
													{!value && <Icon name="circle" className="text-xs text-rose" />}
												</div>
											)}
											headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
											contentClassName="pb-2"
										>
											<StepSlider
												ref={ref}
												min={0}
												max={10}
												onChange={onChange}
												defaultValue={Number(value || 0)}
												// disabled={panelType === 'mentor'}
												// title={
												// 	panelType === 'mentor'
												// 		? t('userPanel.editableOnlyForMentee')
												// 		: undefined
												// }
											/>
										</BoxSection>
									)}
								/>
							))}
						</Box>
					)}
				</>
			)}

			{['menteeEngagement', 'mentorEngagement', 'menteeSatisfaction'].map((name) => (
				<Box key={name} variant="white-bordered">
					<Controller
						name={name as keyof ProgressSurveyFormObject}
						control={control}
						render={({ field: { ref, onChange, value } }) => (
							<BoxSection
								header={() => (
									<div className="flex flex-row gap-2 justify-content-start align-items-center">
										{t(`userPanel.sessions.progressSurvey.questionnaire.${name}`)}
										{!value && <Icon name="circle" className="text-xs text-rose" />}
									</div>
								)}
								headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
								contentClassName="pb-2"
							>
								<StepSlider
									ref={ref}
									min={0}
									max={10}
									onChange={onChange}
									defaultValue={Number(value || 0)}
									// disabled={panelType === 'mentor'}
									// title={panelType === 'mentor' ? t('userPanel.editableOnlyForMentee') : undefined}
								/>
							</BoxSection>
						)}
					/>
				</Box>
			))}

			{sessionType === 'last' && (
				<>
					{['standards', 'schedule', 'contract'].map((name) => (
						<Controller
							name={name as keyof ProgressSurveyFormObject}
							control={control}
							render={({ field }) => {
								const { value, onChange } = field;
								return (
									<div key={name} className="flex flex-row gap-2 align-items-center">
										<div className="">
											<h3 className="font-semibold heading-16-24 text-on-blue-grey">
												{t(`userPanel.sessions.progressSurvey.questionnaire.${name}`)}
											</h3>
										</div>
										{value === undefined && <Icon name="circle" className="text-xs text-rose" />}
										<Line className="flex-1" minWidth={20} />
										<ButtonGroupYesNo
											value={
												typeof value !== 'undefined' && value !== null ? Boolean(value) : null
											}
											onChange={({ value }) => onChange(value)}
											// disabled={panelType === 'mentor'}
											// title={
											// 	panelType === 'mentor'
											// 		? t('userPanel.editableOnlyForMentee')
											// 		: undefined
											// }
										/>
									</div>
								);
							}}
						/>
					))}
					<Box variant="white-bordered">
						<Controller
							name="goalChanges"
							control={control}
							render={({ field: { value, ...restField } }) => (
								<BoxSection
									header={() => (
										<div className="flex flex-row gap-2 justify-content-start align-items-center">
											{t('userPanel.sessions.progressSurvey.questionnaire.goalChanges')}
											{!value && <Icon name="circle" className="text-xs text-rose" />}
										</div>
									)}
									headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
									contentClassName="pb-2"
								>
									<InputTextArea
										{...restField}
										value={value}
										defaultValue={value}
										// disabled={panelType === 'mentor'}
										// title={
										// 	panelType === 'mentor' ? t('userPanel.editableOnlyForMentee') : undefined
										// }
									/>
								</BoxSection>
							)}
						/>
					</Box>
				</>
			)}

			<div className="flex justify-content-end">
				<Button
					submit
					label={t('misc.forms.save')}
					loading={isCreateSubmitting || isUpdateSubmitting}
					// disabled={panelType === 'mentor'}
					// title={panelType === 'mentor' ? t('userPanel.editableOnlyForMentee') : undefined}
				/>
			</div>
		</form>
	);
};

export default ProgressSurveyModule;
