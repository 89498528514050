import React from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import CustomLabel from './Label';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	choiceList: string[];
	description: string;
	label?: string;
	underLabel?: string;
	required?: boolean;
	minAnswers: number;
	maxAnswers: number;
	links?: string[] | null;
	onChangeValue?: (values: any) => void;
}
const ValidateMultipleChoice = <T extends FieldValues>({
	name,
	description,
	label,
	underLabel,
	choiceList,
	control,
	required = false,
	rules,
	minAnswers,
	maxAnswers,
	links,
	onChangeValue,
}: Props<T>) => {
	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: {
			required: required ? `${label} ${t('misc.forms.isRequired')}` : false,
			...rules,
			validate: (answers: string[]) => {
				const numberOfAnswers = answers.length;
				return (
					(numberOfAnswers >= minAnswers && numberOfAnswers <= maxAnswers) ||
					t('misc.forms.atLeastTwoMaxFive', {
						min: minAnswers,
						max: maxAnswers,
					})
				);
			},
		},
	});

	// eslint-disable-next-line no-nested-ternary
	const values: string[] = field.value ? (Array.isArray(field.value) ? field.value : [field.value]) : [];
	const [firstWord, ...restWords] = description.split(' ');
	const restOfText = restWords.join(' ');
	return (
		<div>
			{label && (
				<CustomLabel name={name} required={required}>
					<Trans
						components={
							links
								? links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{label}
					</Trans>
				</CustomLabel>
			)}
			{underLabel && <p className="mt-0 mb-2 px-3 text-sm text-muted">{underLabel}</p>}
			<div className="px-3">
				<div className="mb-3">
					{firstWord} <span className="font-bold">{restOfText}</span>
				</div>

				{(choiceList || []).map((item, index) => (
					<div key={item} className="field-radiobutton">
						<Checkbox
							inputRef={index === 0 ? field.ref : undefined}
							inputId={field.name}
							name={field.name}
							onChange={({ checked, value }) => {
								const newValues = checked ? [...values, value] : values.filter((i) => i !== item);
								if (onChangeValue) {
									onChangeValue(newValues);
								}
								field.onChange(newValues);
							}}
							value={item}
							checked={values.includes(item)}
							className={classNames({ 'p-invalid': !!error })}
						/>
						<label
							onClick={() => {
								const checked = !values.includes(item);
								const newValues = checked ? [...values, item] : values.filter((i) => i !== item);
								if (onChangeValue) {
									onChangeValue(newValues);
								}
								field.onChange(newValues);
							}}
							className={classNames({ 'p-error': !!error }, 'cursor-pointer')}
						>
							{item}
						</label>
					</div>
				))}
			</div>

			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidateMultipleChoice;
