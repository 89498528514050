export const colors = {
	whitePurple: '#e9e9ff',
	blue: '#2589EF',
	purple: '#8E28F5',
	purplishBlue: '#664BF0',
	darkPurplishBlue: '#5c43d8',
	violet: '#4a41c6',
	pink: '#f2bfff',
	gray: '#9C9C9C',
	lightGray: '#DFDFDF',
	blackShadow: 'rgba(24, 24, 24, 0.07)',
	successGreen: '#5B9003',
	warningYellow: '#D4B200',
	warningPink: '#F674CB',
	white: '#fff',
	black: '#000',
	hotPink: '#F674CB',
};

export const border = (color: string, borderSize: string | number = 'thin') => `${borderSize} solid ${color}`;
