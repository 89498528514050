import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { landingPagesApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { usePanel } from 'contexts/panelContext';
import { Spinner } from 'components/_new/Spinner';
import { Page } from 'components/_new/Page';
import FormQuestions from './components/FormQuestions';

export const Application = () => {
	const { t } = useTranslation();
	const { currentApplicationData, currentProgramMembership } = usePanel();

	const {
		refetch: getQuestions,
		data: questionsData,
		isLoading: isQuestionsLoading,
		isRefetching: isQuestionsRefetching,
	} = useQuery(['questions'], () =>
		landingPagesApi.getFormQuestions(currentApplicationData.programId, {
			applicationType: currentApplicationData.applicationType,
			applicationRole: currentApplicationData.applicationRole,
		})
	);

	useEffect(() => {
		getQuestions();
	}, [getQuestions]);

	if (isQuestionsLoading || isQuestionsRefetching || !questionsData) {
		return <Spinner />;
	}

	return (
		<>
			<Helmet title={t('misc.pageTitles.editApplication')} />
			<SubPageTitle title={t('misc.pageTitles.editApplication')} />
			<Page>
				<FormQuestions
					key={currentApplicationData?.applicationType === 'entry' ? 'entry' : 'proper'}
					type={currentApplicationData?.applicationRole === 'mentee' ? 'mentee' : 'mentor'}
					programMembershipId={currentProgramMembership.id}
					questionsData={questionsData}
					membershipData={currentApplicationData}
					applicationType={currentApplicationData?.applicationType}
					editMode
					programId={currentApplicationData.programId}
				/>
			</Page>
		</>
	);
};

export default Application;
