import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import { useTranslation } from 'react-i18next';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { createTools } from './tools';
import './BlockEditor.scss';
import { MessageBox } from '../MessageBox';

type BlockEditorPresenterProps = {
	input?: string | OutputData | null;
};

const ReactEditorJS = createReactEditorJS();

export const BlockEditorPresenter = ({ input }: BlockEditorPresenterProps) => {
	const editorCore = useRef<any>(null);
	const { t } = useTranslation();

	const defaultValue: OutputData | undefined = useMemo(() => {
		if (typeof input === 'object') {
			return input as OutputData;
		}
		if (typeof input === 'string') {
			return JSON.parse(input) as OutputData;
		}
		return undefined;
	}, [input]);

	const handleInitialize = useCallback((instance: any) => {
		editorCore.current = instance;
	}, []);

	const tools = useMemo(
		() => createTools({}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const [ready, setReady] = useState(false);
	const handleReady = useCallback(() => {
		setReady(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [error, setError] = useState<any>(null);
	useEffect(() => {
		if (ready && editorCore.current && defaultValue) {
			// eslint-disable-next-line no-underscore-dangle
			const editor = editorCore.current._editorJS as EditorJS;
			if (editor && editor.blocks) {
				try {
					editor.blocks.insertMany(defaultValue.blocks);
				} catch (error) {
					setError(error);
					console.error('BlockEditror presenter error:', error);
				}
			}
		}
	}, [defaultValue, ready, editorCore]);

	return (
		<div className="block-editor">
			{error ? (
				<MessageBox message={t('misc.blockEditorPresenterValueError')} />
			) : (
				<ReactEditorJS onReady={handleReady} onInitialize={handleInitialize} tools={tools} readOnly />
			)}
		</div>
	);
};
