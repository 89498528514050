import { Application } from './Application';
import { Session } from './Session';

export enum UserFileType {
	PDF = 'pdf',
	VIDEO = 'video',
	LINK = 'link',
	HTML = 'html',
	FILE = 'file',
}

export enum MaterialCategory {
	TOOL = 'tool',
	KNOWLEDGE = 'knowledge',
	FORMS = 'forms',
	NOTE = 'note',
	OTHER = 'other',
}

export interface SessionFile {
	id: number;
	uuid: string;
	name: string;
	type: UserFileType;
	category: MaterialCategory;
	url: string | null;
	content: string | null;
	forAuthorOnly: boolean;
	storageUrl: string | null;
	originalFilename: string | null;
	pairId: number;
	session?: Session | null;
	sessionId: number | null;
	programId: number;
	authorApplication?: Application;
	authorApplicationId: number;
	updatedAt: Date | null;
}
