/* eslint-disable no-case-declarations */
import React, { FC, ClipboardEvent, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { programsApi } from 'api';
import { File } from 'types/File';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { useProgramPanel } from 'contexts/programPanelContext';
import { classNames } from 'primereact/utils';
import { Box, BoxSection } from 'components/_new/Box';
import PageMissing from 'components/PageMissing';
import { slugify } from 'utils/helpers';
import { BlockEditorPresenter } from 'components/_new/BlockEditor/BlockEditorPresenter';
import { Link } from 'components/_new/Link';
import { Loader } from 'components/_new/Loader';
import { Spinner } from 'components/_new/Spinner';

interface Props {
	selectedFile: File;
	className?: string;
}
const ProgramKnowledgeFileDisplayModule: FC<Props> = ({ selectedFile, className }) => {
	const { t } = useTranslation();
	const { organizationName, programName, data: program } = useProgramPanel();

	const {
		data: knowledgeFile,
		isFetching,
		isError,
		refetch,
	} = useQuery(
		['knowledge-file', { id: Number(selectedFile?.id) }],
		() => programsApi.getProgramKnowledgeFile(Number(program?.id), Number(selectedFile?.id)),
		{
			// enabled: Boolean(selectedFile),
			// refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		refetch();
	}, []);

	const handleHtmlCopy = (event: ClipboardEvent) => {
		const selection = document.getSelection() as Selection;
		event.preventDefault();
		event.clipboardData.setData('text', `${selection.toString()} - ${t('userPanel.files.contentCopyMessage')}`);
	};

	const getParsedContent = useCallback(
		(content?: string | null) => {
			// eslint-disable-next-line no-case-declarations
			const parseFileData = (subcontent: string) => {
				try {
					const preParser = (_data: string) => {
						let data = _data;
						if (organizationName) {
							data = data.replaceAll('{{organization}}', organizationName);
						}
						if (programName) {
							data = data.replaceAll('{{program}}', programName);
						}
						return data;
					};
					return parse(preParser(subcontent));
				} catch {
					return 'Wystąpił błąd podczas wyświetlania zawartości.';
				}
			};
			if (typeof content === 'string' && content.length > 0) {
				return <div onCopy={(event) => handleHtmlCopy(event as any)}>{parseFileData(String(content))}</div>;
			}
			return null;
		},
		[handleHtmlCopy, organizationName, programName]
	);

	const prepareFilename = (file: File) => {
		const slugName = slugify(file.name);
		const extension = ['file', 'pdf'].includes(file.type) ? `.pdf` : '';
		return file.originalFilename || `${slugName}${extension}`;
	};

	const handleDownload = useCallback(() => {
		if (knowledgeFile && typeof knowledgeFile === 'object') {
			const { signedUrl } = knowledgeFile;
			if (signedUrl) {
				const downloadLink = document.createElement('a');
				downloadLink.href = signedUrl;
				downloadLink.rel = 'noreferrer';
				downloadLink.target = '_blank';
				downloadLink.download = prepareFilename(knowledgeFile.file);
				document.body.append(downloadLink);
				downloadLink.click();
				setTimeout(() => {
					downloadLink.remove();
				}, 100);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderFileContent = () => {
		if (knowledgeFile) {
			const downloadFilename = prepareFilename(knowledgeFile.file);
			switch (knowledgeFile.file.type) {
				case 'html_blocks':
					return <BlockEditorPresenter input={knowledgeFile.file.descriptionBlocks} />;
				case 'html_wyswig':
					return getParsedContent(knowledgeFile.file.description);
				case 'html':
					return getParsedContent(knowledgeFile.content);
				case 'file':
					return (
						<div className="flex flex-column gap-2">
							{getParsedContent(knowledgeFile.file.description)}
							<div className="flex flex-row gap-2 justify-content-end">
								<Button
									label={t('misc.downloadFile')}
									onClick={handleDownload}
									disabled={!knowledgeFile.signedUrl}
								/>
							</div>
						</div>
					);

				case 'video':
					// eslint-disable-next-line no-case-declarations
					let src = null;
					try {
						src = `https://www.youtube.com/embed/${(knowledgeFile.file.url as string).split('=')[1]}`;
					} catch {
						return 'Wystąpił błąd podczas ładwania filmu';
					}
					return (
						<div className="flex flex-column gap-2">
							{getParsedContent(knowledgeFile.file.description)}
							<iframe
								className="iframe-video"
								src={src}
								frameBorder="0"
								title={knowledgeFile.file.name}
								allowFullScreen
							/>
						</div>
					);
				case 'pdf':
					if (knowledgeFile.signedUrl) {
						return (
							<div className="flex flex-column gap-2">
								{getParsedContent(knowledgeFile.file.description)}
								<iframe
									className="iframe-view iframe-pdf"
									src={knowledgeFile.signedUrl}
									title={downloadFilename}
									width="100%"
									height="500px"
									aria-label="pdf file preview"
									allowFullScreen
								/>
								<div className="text-center">
									<Trans
										t={t}
										i18nKey="userPanel.userFiles.openPdfAlternative"
										components={[
											<Link to={knowledgeFile.signedUrl} target="_blank" variant="standard" />,
										]}
									/>
								</div>
								<div className="flex flex-row gap-2 justify-content-end">
									<Button label={t('misc.downloadFile')} onClick={handleDownload} />
								</div>
							</div>
						);
					}
					return (
						<div className="flex flex-column gap-2">
							{getParsedContent(knowledgeFile.file.description)}
							<Spinner />
						</div>
					);
				case 'link':
					try {
						if (!knowledgeFile.file.url) {
							throw new Error('invalid url');
						} else {
							const url = new URL(knowledgeFile.file.url);
							// window.location.href = url.href;
							return (
								<a href={url.href} target="_blank" className="p-link" rel="noreferrer">
									{url.href}
								</a>
							);
						}
					} catch {
						return t('userPanel.userFiles.file.invalidUrl');
					}

				default:
					return (
						<Message
							severity="error"
							text={t('misc.genericError')}
							className="w-full justify-content-start pl-4"
						/>
					);
			}
		}
		return null;
	};

	if (isError) return <PageMissing />;

	if (!knowledgeFile || isFetching) return <Loader />;

	return (
		<Box variant="white-bordered" className={className}>
			<BoxSection header={knowledgeFile.file.name}>
				<div className={classNames('p-knowledge-base-article')}>{renderFileContent()}</div>
			</BoxSection>
		</Box>
	);
};

export default ProgramKnowledgeFileDisplayModule;
