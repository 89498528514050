import React from 'react';
import { Helmet } from 'react-helmet';
import BusinessCard from 'components/_common/panel/BusinessCard';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Application } from 'types/Application';
import { useTranslation } from 'react-i18next';
import { Page } from 'components/_new/Page';

type PersonalDetailsProps = { applicationData: Application; isYour?: boolean };

const PersonalDetails = (props: PersonalDetailsProps) => {
	const { applicationData, isYour = false } = props;

	const { t } = useTranslation();

	return (
		<>
			<Helmet title={t('misc.pageTitles.personalDetails')} />
			<SubPageTitle
				title={
					isYour
						? t('userPanel.personalDetails.header')
						: t('userPanel.pairing.personalDetailsPair', {
								type:
									applicationData.applicationRole === 'mentee'
										? t('misc.mentees')
										: t('misc.mentors'),
						  })
				}
			/>
			<Page>
				<BusinessCard data={applicationData} isYour={isYour} displayHidden={isYour} />
			</Page>
		</>
	);
};

export default PersonalDetails;
