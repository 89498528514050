import ImageTool from '@editorjs/image';
import Quote from '@editorjs/quote';
// @ts-ignore
import EditorjsColumns from '@calumk/editorjs-columns';
// @ts-ignore
import Delimiter from '@coolbytes/editorjs-delimiter';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import { postsApi } from 'api';
import { AxiosError } from 'axios';
// import { StyleInlineTool } from 'editorjs-style';
import EditorJS, { ToolConstructable, ToolSettings } from '@editorjs/editorjs';
// @ts-ignore
import Underline from '@editorjs/underline';
// @ts-ignore
import Header from 'editorjs-header-with-alignment';
// @ts-ignore
import Paragraph from 'editorjs-paragraph-with-alignment';
// @ts-ignore
// import TextColor from '@itech-indrustries/editor-js-text-color';
import { EditorJSLocale } from './locale';

type CreateToolsOptions = {
	i18n?: EditorJSLocale;
	uploadImage?: {
		onFileOversize: () => void;
		onInvalidImageFormat: () => void;
		onError: () => void;
	};
};

type Tools = {
	[toolName: string]: ToolConstructable | ToolSettings;
};

export const createTools = (options: CreateToolsOptions) => {
	const handleUploadImage = (file: File): Promise<{ success: boolean; file: { url: string } }> => {
		if (options.uploadImage) {
			const formData = new FormData();
			formData.append('file', file);

			const maxFileSize = 1024 * 1024 * 3; // 3 mb

			return new Promise((resolve, reject) => {
				if (file.size > maxFileSize) {
					options.uploadImage?.onFileOversize();

					reject(new Error('max file size'));
				}
				postsApi
					.uploadImage(formData)
					.then((link) => resolve({ success: true, file: { url: link } }))
					.catch((err) => {
						if (err instanceof AxiosError) {
							const message = err.response?.data.message || err.message;
							switch (message) {
								case 'File too large':
									options.uploadImage?.onFileOversize();

									break;
								case 'Invalid image format':
									options.uploadImage?.onInvalidImageFormat();

									break;
								default:
							}
						} else {
							options.uploadImage?.onError();
						}
						reject(new Error('unexpected upload error'));
					});
			});
		}
		return new Promise((resolve, reject) => {});
	};

	const i18nToolsMessages = (options?.i18n?.messages?.tools || {}) as EditorJSLocale['messages']['tools'];

	const standardTools = {
		header: {
			class: Header,
			config: {
				...(i18nToolsMessages?.header || {}),
				defaultLevel: 3,
				defaultAlignment: 'left',
			},
		},
		paragraph: {
			class: Paragraph,
			config: {
				...(i18nToolsMessages?.paragraph || {}),
				inlineToolbar: true,
			},
		},
		quote: {
			class: Quote,
			config: {
				...(i18nToolsMessages?.quote || {}),
			},
		},
		// linkTool: {
		// 	class: LinkTool,
		// 	config: {
		// 		endpoint: 'http://localhost:8008/fetchUrl', // Your backend endpoint for url data fetching,
		// 	},
		// },
		// embed: {
		// 	class: Embed,
		// 	inlineToolbar: true,
		// 	// config: {
		// 	// 	services: {
		// 	// 		youtube: true,
		// 	// 	},
		// 	// },
		// },
		image: {
			class: ImageTool,
			config: {
				...(i18nToolsMessages?.image || {}),
				uploader: {
					// uploadByUrl(url: string): Promise<{ success: boolean; file: { url: string } }> {
					// 	return hadnelUploadImageByUrl(url);
					// },
					uploadByFile(file: File): Promise<{ success: boolean; file: { url: string } }> {
						return handleUploadImage(file);
					},
				},
			},
		},
		list: {
			class: List,
			inlineToolbar: true,
		},
		table: {
			class: Table,
			inlineToolbar: true,
			config: {
				rows: 2,
				cols: 3,
				maxRows: 5,
				maxCols: 5,
				withHeadings: true,
				stretched: true,
			},
		},
		delimiter: Delimiter,
		underline: Underline,
		// style: StyleInlineTool,
	};
	const tools = {
		...standardTools,
		columns: {
			class: EditorjsColumns,
			config: {
				EditorJsLibrary: EditorJS,
				tools: standardTools,
			},
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any as Tools;

	return tools;
};
