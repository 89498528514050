import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { programsApi, organizationsApi } from 'api';
import { useProgramPanel } from 'contexts/programPanelContext';
import { ProgramLandingPageMode, UpdateProgramStatusParametersPayload } from 'types/Program';
import { Link } from 'components/_new/Link';
import { Icon } from 'components/_new/Icon';
import { Tooltip } from 'primereact/tooltip';
import { Panel, PanelItem } from '../../partials/panel';

type ProgramStatusForm = {
	displayName: string;
	organizationDisplayName: string;
	subtitle: string;
	logotype?: File;
	finished: boolean;
	endDate?: Date;
	landingPageMode?: string;
};

export const ProgramStatus = () => {
	const { t } = useTranslation();
	const { data: programData, refetch: programRefetch } = useProgramPanel();

	const editModeState = useState(false);
	const form = useForm<ProgramStatusForm>({
		defaultValues: {
			displayName: programData?.displayName,
			organizationDisplayName: programData?.organization?.displayName || '',
			subtitle: programData?.subtitle || '',
			logotype: undefined,
			finished: programData?.finished || false,
			endDate: programData?.endDate || undefined,
			landingPageMode: programData?.landingPageMode,
		},
	});

	const updateProgram = useMutation(
		(data: UpdateProgramStatusParametersPayload) =>
			programsApi.putProgramAdminStatusParameters(Number(programData?.id), data),
		{
			onSuccess: () => {
				programRefetch?.();
			},
		}
	);

	const updateOrganization = useMutation(
		({
			organizationName,
			displayName,
			language,
		}: {
			organizationName: string;
			displayName: string;
			language: string;
		}) => organizationsApi.editOrganization(organizationName, { displayName, language }),
		{
			onSuccess: () => {
				programRefetch?.();
			},
		}
	);

	const handleSave = async ({
		displayName,
		organizationDisplayName,
		subtitle,
		logotype,
		finished,
		endDate: inputEndDate,
		landingPageMode,
	}: ProgramStatusForm) => {
		const endDate = inputEndDate ? new Date(inputEndDate).toISOString().split('T')[0] : null;

		try {
			await updateProgram.mutateAsync({
				displayName,
				subtitle,
				logotype,
				finished,
				endDate,
				landingPageMode: String(landingPageMode),
			});

			if (
				programData?.isProgramOrganizationAdmin &&
				organizationDisplayName !== programData?.organization?.displayName &&
				programData?.organization?.name
			) {
				await updateOrganization.mutateAsync({
					organizationName: programData.organization.name,
					displayName: organizationDisplayName,
					language: programData.organization.language,
				});
			}

			editModeState[1](false);
		} catch (error) {
			console.error('Error updating program or organization:', error);
		}
	};

	const programUrl = `${process.env.REACT_APP_FRONT_URL}/${programData?.name}`;
	const isOrganizationAdmin = programData?.isProgramOrganizationAdmin;

	return (
		<Panel
			title={t('programPanel.dashboard.status.titleInConfig')}
			form={form}
			editModeState={editModeState}
			isLoading={updateProgram.isLoading || updateOrganization.isLoading}
			onSave={handleSave}
		>
			<>
				<PanelItem
					title={t('programPanel.dashboard.status.programName')}
					value={programData?.displayName}
					controller={{ control: form.control, name: 'displayName' }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programSubtitle')}
					value={programData?.subtitle}
					type="string"
					controller={{ control: form.control, name: 'subtitle' }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.organizationName')}
					value={programData?.organization?.displayName}
					type="string"
					controller={
						isOrganizationAdmin ? { control: form.control, name: 'organizationDisplayName' } : undefined
					}
					editable={isOrganizationAdmin}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programLogotype')}
					value={programData?.logotype}
					type="image"
					controller={{ control: form.control, name: 'logotype' }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programIsActive')}
					type="boolean"
					value={!programData?.finished}
					controller={{ control: form.control, name: 'finished' }}
					transformValue="active/unactive"
					inputCheckboxProps={{ invertValue: true }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programContentDisplayType')}
					value={programData?.landingPageMode}
					type="select"
					controller={{ control: form.control, name: 'landingPageMode' }}
					inputDropdownProps={{
						options: [
							{
								value: ProgramLandingPageMode.HTML,
								label: t('programPanel.dashboard.status.programContentDisplayAsHTML'),
								tooltip: t('programPanel.dashboard.status.programContentDisplayTooltip'),
							},
							{
								value: ProgramLandingPageMode.MODULE,
								label: t('programPanel.dashboard.status.programContentDisplayAsModules'),
							},
						],
					}}
					transformValue={({ value, label }) => (
						<div className="flex flex-row align-items-center gap-2">
							{value === ProgramLandingPageMode.HTML && (
								<Tooltip
									target="#lp-mode-tooltip"
									content={t('programPanel.dashboard.status.programContentDisplayTooltip')}
									position="bottom"
								/>
							)}
							<span id="lp-mode-tooltip">{label}</span>
							{value === ProgramLandingPageMode.MODULE && (
								<Link to={`/program/${programData?.name}/landing-page`}>
									<Icon name="pencil" iconSet="fa" iconStyle="solid" />
								</Link>
							)}
						</div>
					)}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programUrlAddress')}
					value={programUrl}
					transformValue={({ value }) => (
						<a href={value} target="_blank" rel="noreferrer" className="underline">
							{value}
						</a>
					)}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.endDate')}
					value={programData?.endDate}
					type="date"
					controller={{ control: form.control, name: 'endDate' }}
					clearable
					transformValue="date-only"
					withEmptyText
					bold
				/>
			</>
		</Panel>
	);
};
