import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Fuse from 'fuse.js';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { RouterUrlParams } from 'App';
import { Folder, File, FileType } from 'types/File';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FilterMatchMode } from 'primereact/api';
import FileDisplay from 'components/knowledgeDatabase/FileDisplay';
import FilesSearchbar from 'components/knowledgeDatabase/FilesSearchbar';
import { matchFiles } from 'components/knowledgeDatabase/helpers';
import { slugify, deslugify } from 'utils/helpers';
import { usePanel } from 'contexts/panelContext';
import toolsIcon from 'assets/tools.svg';
import knowledgePillsIcon from 'assets/knowledge-pills.svg';
import guideIcon from 'assets/guide.svg';
import formsIcon from 'assets/forms.svg';
import FileDisplayModal from 'components/knowledgeDatabase/FileDisplayModal';
import { scrollToElementById } from 'utils/scroll';
import { Button } from '../_new/Button';
import { Link } from '../_new/Link';
import { Divider } from '../_new/Divider';
import { Icon } from '../_new/Icon';
import { Box, BoxSection } from '../_new/Box';
import './files.scss';

interface Props {
	filesData: Folder[];
	enablePing?: boolean;
	breadcrumb: {
		home: string;
	};
}

const getFolderSVGData = (folderName: string) => {
	switch (folderName) {
		case 'Techniki i narzędzia':
		case 'Tools and techniques':
			return { svg: toolsIcon, hoverColor: '#f1c40f' };

		case 'Pigułki wiedzy':
		case 'Knowledge':
			return { svg: knowledgePillsIcon, hoverColor: '#2ecc71' };

		case 'Przewodnik po Mentiway':
		case 'Mentiway guides':
		case 'Workshops':
			return { svg: guideIcon, hoverColor: '#3498db' };

		case 'Formularze przygotowawcze':
		case 'Preparation forms':
			return { svg: formsIcon, hoverColor: '#e74c3c' };

		default:
			return { svg: guideIcon, hoverColor: '#3498db' }; // Default case
	}
};

const renderSVG = (folderName: string) => {
	const folderData = getFolderSVGData(folderName);
	if (folderData) {
		return (
			<div className="knowedge-base-box-icon">
				<div className="circle-inbg" />
				<img src={folderData.svg} alt={folderName} className="relative z-1 w-5rem h-5rem" />
			</div>
		);
	}
	return null;
};

const Files: FC<Props> = ({ filesData, enablePing = false, breadcrumb }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName, type, '*': filePath } = useParams() as RouterUrlParams;
	const { currentApplicationData, currentProgramMembership } = usePanel();
	const { mutate: pingFileMutate } = useMutation(['pingFile'], (filePath: string) =>
		userPanelApi.checkFile(currentApplicationData.id, currentProgramMembership.id, filePath)
	);

	const flattenedFiles = useMemo(() => {
		const uniqueFilesMap = new Map<number, File>();

		filesData.forEach((folder) => {
			folder.files.forEach((file) => {
				if (!uniqueFilesMap.has(file.id)) {
					uniqueFilesMap.set(file.id, file);
				}
			});
		});

		return Array.from(uniqueFilesMap.values());
	}, [filesData]);

	const [usageFilter, setUsageFilter] = useState<string | null>(null);
	const usageFilteredFiles: File[] = useMemo(() => {
		return usageFilter
			? flattenedFiles.filter((item) => {
					if (item.type !== 'directory') {
						return item.usages && item.usages.includes(usageFilter);
					}
					return false;
			  })
			: [];
	}, [flattenedFiles, usageFilter]);

	const isMainPage = !filePath || filePath.trim() === '';
	const handleOpenClick = (folderName: string) => {
		const slugifiedFolderName = slugify(folderName);
		navigate(`/panel/${organizationName}/${programName}/${type}/files/${slugifiedFolderName}`);
	};

	useEffect(() => {
		if (enablePing && filePath.length > 0) {
			pingFileMutate(filePath);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enablePing, filePath]);

	const homeBreadcrumb = {
		icon: 'pi pi-book',
		command: () => {
			setSearchValue(null);
			setUsageFilter(null);
			navigate(breadcrumb.home);
		},
	};

	const [breadcrumbs, setBreadcrumbs] = useState<{ label: string; command: () => void }[]>([]);
	const [viewableFiles, setViewableFiles] = useState<Folder[] | File[] | Array<File | Folder>>([]);
	const [selectedFile, setSelectedFile] = useState<File>();
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [selectedFileId, setSelectedFileId] = useState<number | null>(null);
	const [filters, setFilters] = useState({
		type: { value: '', matchMode: FilterMatchMode.EQUALS },
	});
	const filterMode = searchValue && searchValue.length > 0;
	// const isMentor = currentProgramMembership.role === 'mentor';

	const getFolderFiles = useCallback(
		(searchedName: string) => filesData.find((folder) => slugify(folder.name) === searchedName)?.files as File[],
		[filesData]
	);

	const usages = useMemo(
		() =>
			Array.from(
				new Set(
					filesData
						.map(({ files }) => files.map(({ usages }) => usages).filter(Boolean))
						.flat()
						.reduce((current, item) => [...(current || []), ...(item || [])], []) || []
				)
			),
		[filesData]
	);

	useEffect(() => {
		if (!filePath) {
			setViewableFiles(filesData);
			setSelectedFile(undefined);
		} else {
			const requestedData = matchFiles(filesData, filePath);

			if (requestedData && requestedData.type !== 'directory') {
				setSelectedFile(requestedData as File);
			} else {
				setSelectedFileId(null);
				setSelectedFile(undefined);
				setViewableFiles(((requestedData as Folder)?.files as File[]) ?? []);
			}
		}

		const breadcrumsData = filePath.split('/').reduce((current: any, currentItem: any, currentIndex: number) => {
			const label =
				flattenedFiles.find((file) => slugify(file.name) === currentItem)?.name ?? deslugify(currentItem);

			const prevSlug = current[currentIndex - 1]?.slug || null;
			const slug = slugify(currentItem);
			const finalSlug = [prevSlug, slug].filter(Boolean).join('/');
			const navigatePath = `/panel/${organizationName}/${programName}/${type}/files/${finalSlug}`;

			current.push({
				label,
				slug,
				navigatePath,
			});

			return current;
		}, []);

		setBreadcrumbs(
			breadcrumsData.map(({ label, navigatePath, slug }: any) => {
				return {
					label,
					command: () => {
						navigate(navigatePath);
						setSearchValue(null);
						if (navigatePath !== slug) {
							setViewableFiles(getFolderFiles(slug));
						}
					},
				};
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filePath, navigate, filesData, getFolderFiles, flattenedFiles]);

	const handleSearch = useCallback(
		(value: string) => {
			const finalSearchValue = value && value.length ? value : null;
			setSearchValue(finalSearchValue);

			switch (true) {
				// if filter by tag is active
				case Boolean(usageFilter):
					if (finalSearchValue) {
						const fuse = new Fuse(usageFilteredFiles, {
							threshold: 0.5,
							findAllMatches: true,
							ignoreLocation: true,
							keys: ['name'],
						});
						setViewableFiles(fuse.search(value).map((result) => result.item));
					} else {
						setViewableFiles(usageFilteredFiles);
					}
					break;
				// for active directory selected
				case Boolean(filePath):
					if (finalSearchValue) {
						const fuse = new Fuse(
							(viewableFiles as File[]).filter((item) => item.type !== 'directory'),
							{
								threshold: 0.5,
								findAllMatches: true,
								ignoreLocation: true,
								keys: ['name'],
							}
						);
						setViewableFiles(fuse.search(value).map((result) => result.item));
					} else {
						const requestedData = matchFiles(filesData, filePath);
						const folderFiles = ((requestedData as Folder)?.files as File[]) ?? [];
						setViewableFiles(folderFiles);
					}
					break;
				// standard search
				default:
					if (finalSearchValue) {
						const fuse = new Fuse(flattenedFiles, {
							threshold: 0.5,
							findAllMatches: true,
							ignoreLocation: true,
							keys: ['name'],
						});
						const filteredFiles = fuse.search(value).map((result) => result.item);
						setViewableFiles(filteredFiles);
					} else {
						setViewableFiles(filesData);
					}
					break;
			}
		},
		[usageFilter, usageFilteredFiles, flattenedFiles, viewableFiles, filesData, filePath]
	);

	const handleTagClick = (usage: string) => {
		setUsageFilter(usage);
		scrollToElementById('scrollWrapperRef', 0);
	};

	const handleFilter = (value: string) => {
		const newFilters = { ...filters };
		newFilters.type.value = value;
		setFilters(newFilters);
	};

	const renderIcon = (type: FileType | undefined): string => {
		switch (type) {
			case 'pdf':
				return 'file-pdf';
			case 'link':
				return 'file-code';
			case 'html_wyswig':
			case 'html':
				return 'file-lines';
			case 'file':
				return 'file';
			case 'video':
				return 'file-video';
			default:
				return 'folder';
		}
	};

	const currentItem = breadcrumbs[breadcrumbs.length - 1]?.label || null;

	const renderTopCards = () => {
		return (
			<div className="grid">
				{filesData.map((folder) => {
					const svgElement = renderSVG(folder.name);
					if (!svgElement) return null;

					return (
						<div className="flex flex-column col-12 sm:col-6 md:col-4 xl:col-3">
							<Box
								wrap
								hoverable
								variant="white-bordered"
								className="flex flex-column flex-1 knowedge-base-box"
								onClick={() => handleOpenClick(folder.name)}
							>
								<BoxSection contentClassName="h-full">
									<div className="flex justify-content-center">{svgElement}</div>
									<div className="flex-1 flex flex-column justify-content-between gap-2">
										<span className="flex justify-content-center p-card-title font-bold text-l px-2 text-center">
											{folder.name}
										</span>
										<div className="flex justify-content-center flex-end">
											<Button
												className="px-6"
												variantSize="sm"
												label={t('actions.open')}
												onClick={() => handleOpenClick(folder.name)}
											/>
										</div>
									</div>
								</BoxSection>
							</Box>
						</div>
					);
				})}
			</div>
		);
	};

	const renderFileItem = (item: File) => {
		const icon = renderIcon(item.type);
		const uniqueKey = `${item.id}-${item.name}-${item.type}`;
		const to =
			item.type === 'directory'
				? `/panel/${organizationName}/${programName}/${type}/files/${slugify(item.name)}`
				: `/panel/${organizationName}/${programName}/${type}/files/${slugify(item.directory)}/${slugify(
						item.name
				  )}`;

		return (
			<Box vertical key={uniqueKey} className="box-file-container md:py-1" wrap variant="white-bordered">
				<BoxSection
					className="justify-content-center align-content-center left-col"
					contentClassName="min300px"
				>
					<Link
						to={to}
						icon={icon}
						iconSet="fa"
						iconStyle="regular"
						iconClassName="text-xl text-purple"
						label={item.name}
						variant="grey"
						noUnderline
						className="block text-l font-bold flex-grow flex align-items-center mt-2"
					>
						{item.name}
					</Link>
				</BoxSection>
				<BoxSection
					horizontal
					className="flex-0 right-col"
					contentClassName="flex flex-nowrap gap-1 pr-2 align-items-center min100px"
				>
					<Button
						label="View"
						icon="eye"
						iconOnly
						variant="primary-text"
						variantSize="lg"
						onClick={(event) => {
							event.preventDefault();
							setSelectedFileId(item.id);
						}}
					/>
					<Button
						label="Open in new tab"
						icon="arrow-up-right-from-square"
						iconOnly
						variant="primary-text"
						variantSize="lg"
						onClick={() => {
							// navigate(to, {});
							window.open(to, '_bland');
						}}
					/>
				</BoxSection>
			</Box>
		);
	};

	function findFeaturedItems(data: any[]) {
		const featuredItems: any[] = [];

		data.forEach((item) => {
			if (item.type === 'directory' && item.files) {
				item.files.forEach((file: { featured: any }) => {
					if (file.featured) {
						featuredItems.push(file);
					}
				});
			} else if (item.featured) {
				featuredItems.push(item);
			}
		});

		return featuredItems;
	}

	// Adjusted renderFilesList function
	const renderFilesList = () => {
		let filesToRender: File[] = [];

		if (filterMode) {
			// If in search mode, use viewableFiles directly
			filesToRender = viewableFiles as File[];
		} else if (usageFilter) {
			filesToRender = usageFilteredFiles;
		} else if (isMainPage) {
			// On the main page without search, show featured items
			filesToRender = findFeaturedItems(viewableFiles);
		} else {
			// When inside a folder without search, show viewableFiles
			filesToRender = viewableFiles as File[];
		}

		// Remove duplicates from filesToRender
		const uniqueFilesMap = new Map<number, File>();
		filesToRender.forEach((file) => {
			if (!uniqueFilesMap.has(file.id)) {
				uniqueFilesMap.set(file.id, file);
			}
		});
		filesToRender = Array.from(uniqueFilesMap.values());

		return (
			<div className="flex flex-column gap-2">
				<div>
					{usageFilter && (
						<div className="text-black font-bold text-base">
							{t('userPanel.files.filteredByUsage')} &quot;{usageFilter}&quot;
						</div>
					)}
					{!usageFilter && isMainPage && !filterMode && (
						<div className="text-black font-bold text-base">{t('userPanel.files.mostPopular')}</div>
					)}
					{!usageFilter && isMainPage && filterMode && (
						<div className="text-black font-bold text-base">{t('userPanel.files.searchResults')}</div>
					)}
				</div>
				<div className="flex flex-column gap-6">
					{filesToRender.length === 0 ? (
						<span className="text-muted">{t('userPanel.files.fileNotFound')}</span>
					) : (
						<div className="md:flex-1">
							<Box variant="clean" vertical className="box-file-container">
								<BoxSection
									horizontal
									className="flex-3 left-col"
									contentClassName="min200px gap-1 align-items-center nowrap"
								>
									{/* <Icon name="angle-down" iconSet="fa" className="mr-2" /> */}
									{t('userPanel.files.name')}
								</BoxSection>
								<BoxSection horizontal className="flex-0 right-col" contentClassName="min100px" />
							</Box>
							<div className="flex flex-column gap-2">{filesToRender.map(renderFileItem)}</div>
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			{currentItem && <Helmet title={currentItem} />}
			<FileDisplayModal
				selectedFileId={selectedFileId}
				visible={Boolean(selectedFileId)}
				onHide={() => {
					setSelectedFileId(null);
				}}
			/>

			{(!isMainPage || usageFilter) && (
				<BreadCrumb
					model={usageFilter ? [{ label: usageFilter }] : breadcrumbs}
					home={homeBreadcrumb}
					className="mb-3"
				/>
			)}
			{!selectedFile && (
				<FilesSearchbar
					searchValue={searchValue}
					onSearch={handleSearch}
					selectedFilterOption={filters.type.value}
					onFilter={handleFilter}
				/>
			)}
			<Divider className="my-4" />
			<div className="flex flex-column gap-6">
				{isMainPage && !filterMode && !usageFilter && renderTopCards()}

				{selectedFile ? <FileDisplay selectedFile={selectedFile} /> : renderFilesList()}

				{isMainPage && usages && type === 'mentor' && (
					<div className="flex flex-column gap-2 word-cloud-container">
						<div className="text-black font-bold text-base mb-2">{t('userPanel.files.wordCloudTitle')}</div>
						<div className="tag-cloud">
							{usages.map((usage) => (
								<Button
									key={usage}
									label={usage}
									onClick={() => handleTagClick(usage)}
									variant={usageFilter === usage ? 'primary' : 'primary-outlined'}
								/>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Files;
