import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { parseHtml } from 'utils/htmlParser';
import { programsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { usePanel } from 'contexts/panelContext';
import { Page } from 'components/_new/Page';

export const Contact = () => {
	const { t } = useTranslation();
	const { currentProgram } = usePanel();

	const { data, isSuccess } = useQuery(
		['GET_PROGARM_CONTACT', currentProgram.id],
		() => programsApi.getProgramContact(currentProgram.id),
		{
			enabled: Boolean(currentProgram),
		}
	);

	const hasContent = data && data.length > 0;

	return (
		<>
			<Helmet title={t('misc.pageTitles.pairing')} />
			<SubPageTitle title={t('userPanel.navigation.contact')} />
			<Page>
				{isSuccess && hasContent ? parseHtml(data, { nl2br: false }) : <p>Brak informacji kontaktowych</p>}
			</Page>
		</>
	);
};
