import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import PairRejected from 'assets/icons/pair-cancel.svg';
import PairStatus from 'assets/icons/pair-status.svg';
import PairAccept from 'assets/icons/pair-accept.svg';

import { Pair } from 'types/Pair';

import { usePanel } from 'contexts/panelContext';
import { colors } from 'theme';
import { ConfirmPairItem } from './ConfirmPairItem';
import { IconWrapper } from './IconWrapper';

interface MenteeActionsProps {
	data: Pair;
	setInitialMeetingModal: React.Dispatch<React.SetStateAction<boolean>>;
	setInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
	onAction?: () => void;
}

const MenteeActions = ({ data, setInitialMeetingModal, setInfoDialogOpen, disabled, onAction }: MenteeActionsProps) => {
	const { t } = useTranslation();
	const {
		currentApplicationData: { limitStats },
	} = usePanel();
	const youPairLimitReached = Boolean(limitStats?.pairLimitReached);

	const {
		mentorBusyness,
		rejectedByMentee,
		rejectedByMentor,
		acceptedByMentee,
		entryMeeting,
		acceptedByMentor,
		menteeMembershipId,
		id,
		status,
		application,
	} = data;
	const pairLimitReached = Boolean(application.limitStats?.pairLimitReached);
	const noRelationship =
		rejectedByMentor === null &&
		acceptedByMentor === null &&
		acceptedByMentee === null &&
		rejectedByMentee === null;

	const renderInfo = () => {
		if (acceptedByMentee && acceptedByMentor) {
			return (
				<IconWrapper
					imgSrc={PairAccept}
					alt={t('userPanel.pairing.altPairAccepted')}
					text={t('userPanel.pairing.pairAccepted')}
				/>
			);
		}

		if (rejectedByMentor) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.altPairRejected')}
					text={t('userPanel.pairing.pairRejected', { type: 'Mentor' })}
				/>
			);
		}

		if (rejectedByMentee) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.altPairRejected')}
					text={t('userPanel.pairing.youRejected')}
				/>
			);
		}

		if (youPairLimitReached) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.youPairLimitReached')}
					text={t('userPanel.pairing.youPairLimitReached')}
				/>
			);
		}

		if (pairLimitReached) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.pairLimitReached')}
					text={t('userPanel.pairing.pairLimitReached')}
				/>
			);
		}

		if (status === 'open' && acceptedByMentee && !acceptedByMentor) {
			return (
				<IconWrapper
					imgSrc={PairStatus}
					alt={t('userPanel.pairing.altPairStatus')}
					text={t('userPanel.pairing.waitForAnswer', { type: t('misc.mentors') })}
				/>
			);
		}

		if (status === 'open' && acceptedByMentor && !acceptedByMentee) {
			return (
				<IconWrapper
					imgSrc={PairStatus}
					text={t('userPanel.pairing.oppositeSideAccepted', { type: 'Mentor' })}
					alt={t('userPanel.pairing.altPairRejected')}
				/>
			);
		}

		if (noRelationship && entryMeeting) {
			return (
				<IconWrapper
					imgSrc={PairStatus}
					text={t('userPanel.pairing.meetingInvitationSent')}
					alt={t('userPanel.pairing.meetingInvitationSent')}
				/>
			);
		}

		return null;
	};

	return (
		<div className="flex gap-2 flex-wrap justify-content-between w-full align-content-center">
			<div className="flex-1 flex flex-column justify-content-center min200px">
				{renderInfo()}
				{mentorBusyness > 0 && status === 'open' && ((noRelationship && entryMeeting) || !noRelationship) && (
					<div className="flex align-items-center text-xs mt-3">
						<i
							className=" fa-solid fa-2x fa-triangle-exclamation mr-3"
							style={{ color: colors.warningPink, width: 22, height: 22 }}
						/>
						{t('userPanel.pairing.mentorBusyness', { mentorBusyness })}
					</div>
				)}
			</div>

			{!youPairLimitReached && !pairLimitReached && (
				<div className="flex-1 flex justify-content-end align-content-center">
					{noRelationship && (
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>
							{!entryMeeting && status === 'open' && (
								<div className="flex w-full justify-content-end align-items-center">
									{!disabled && !youPairLimitReached ? (
										<div className="flex-column ">
											<Button
												className="py-1 px-4 text-sm align-self-end"
												onClick={() => setInitialMeetingModal(true)}
												color="white"
											>
												{t('userPanel.pairing.proposeInitialMeeting')}
											</Button>
										</div>
									) : (
										<div className="py-1 px-4" />
									)}
								</div>
							)}
							{entryMeeting && !youPairLimitReached && (
								<ConfirmPairItem
									id={id}
									membershipId={menteeMembershipId}
									setInfoDialogOpen={setInfoDialogOpen}
									hideButton={disabled}
									onAction={onAction}
								/>
							)}
						</>
					)}
					{!noRelationship && acceptedByMentor && status === 'open' && (
						<ConfirmPairItem
							id={id}
							membershipId={menteeMembershipId}
							setInfoDialogOpen={setInfoDialogOpen}
							hideButton={disabled}
							onAction={onAction}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default MenteeActions;
