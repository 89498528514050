import React, { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { dot } from 'dot-object';
import { finalReportApi, userPanelApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Box, BoxSection } from 'components/_new/Box';
import { useGlobal } from 'contexts/globalContext';
import { usePanel } from 'contexts/panelContext';
import { InputTextArea } from 'components/_new/InputTextArea';
import { Field } from 'components/_new/Field';
import { RouterUrlParams } from 'App';
import { usePair } from 'contexts/pairContext';
import { useAuth } from 'contexts/authContext';
import { userDisplayName } from 'utils/userDisplayName';
import { User } from 'types/User';
import { MessageBox } from 'components/_new/MessageBox';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'components/_new/Button';
import { Page } from 'components/_new/Page';

type FinalReportForm = {
	goal: Array<any>;
	specific_skills_developed: string;
	mentoring_process_supportive: string;
	general_recomendations_for_development: string;
	mentor_thanks: string;
	additional_notes: string;
	visibility: number;
};

export const FinalRaport = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams() as RouterUrlParams;

	const { currentUser } = useAuth();
	const { toastRef } = useGlobal();
	const { currentProgramMembership, currentApplicationData, panelType } = usePanel();
	const {
		pair: { id: pairId, application },
	} = usePair();

	const { control, handleSubmit, setValue, watch } = useForm<FinalReportForm>({
		defaultValues: {
			goal: [],
			specific_skills_developed: '',
			mentoring_process_supportive: '',
			general_recomendations_for_development: '',
			mentor_thanks: '',
			additional_notes: '',
			visibility: 0,
		},
	});
	useFieldArray({ name: 'goal', control });

	const { data: goalsData, isFetched: goalsFetched } = useQuery(
		['goals', pairId],
		() => userPanelApi.getGoals(pairId, currentProgramMembership.id),
		{
			enabled: Boolean(pairId && currentProgramMembership?.id),
		}
	);

	const [defaultValues, setDefaultValues] = useState<Record<string, string | number>>({});
	const {
		data: finalReportData,
		refetch: finalReportRefetch,
		isFetched,
	} = useQuery(
		['final-report', { membershipId: currentProgramMembership?.id, pairId }],
		() => finalReportApi.getFinalReport(pairId, currentApplicationData?.id),
		{
			enabled: Boolean(goalsFetched),
			refetchOnWindowFocus: false,
			onSuccess: ({ answers, visibleForMentee }: any) => {
				const rawDefaultValues: Record<string, string | number> = {};
				(answers || []).forEach((answer: any) => {
					const value = answer.value ? String(answer.value) : '';
					if (answer?.goalId !== null) {
						setValue(`goal.${answer.goalId}.${answer.type}`, value);
						rawDefaultValues[`goal[${answer.goalId}].${answer.type}`] = value;
					} else {
						setValue(answer.type, value);
						rawDefaultValues[`${answer.type}`] = value;
					}
				});
				setValue('visibility', Number(visibleForMentee));

				rawDefaultValues.visibility = Number(visibleForMentee);
				setDefaultValues(rawDefaultValues);
			},
		}
	);

	const formValues = watch();
	const stringlifyDefaultValues = JSON.stringify(defaultValues);
	const hasChanges = useMemo(() => {
		const changesEntries = Object.fromEntries(
			Object.entries(dot(formValues)).map(([k, v]) => {
				if (typeof defaultValues[k] === 'undefined') {
					return [k, false];
				}
				if (k === 'visibility') {
					return [k, v !== Number(defaultValues[k])];
				}
				return [k, v !== defaultValues[k]];
			})
		);
		return Object.values(changesEntries).some((v) => v === true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched, formValues, defaultValues, stringlifyDefaultValues]); // last el for deps comprasion

	const queryClient = useQueryClient();
	const { mutate: updateFinalReportMutate, isLoading: updateFinalReportLoading } = useMutation(
		(data: any) => finalReportApi.updateFinalReportInfo(pairId, data),
		{
			onSuccess: () => {
				queryClient.refetchQueries({ queryKey: ['sessions', { pairId }] });
				finalReportRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.finalReport.dataUpdatedSuccessfully'),
				});
			},
		}
	);

	const handleSubmitForm = handleSubmit((values) => {
		const preparedValues = Object.fromEntries(
			Object.entries(values).map(([k, v]) => {
				if (k === 'goal') {
					const goalAnswers = (v as Array<any>)
						.map((subanswers: Record<string, string>, index: number) =>
							Object.entries(subanswers).map(([k, v]) => ({ goalId: index, type: k, value: v }))
						)
						.filter(Boolean)
						.flat();
					return [k, goalAnswers];
				}
				return [k, v === undefined ? null : v];
			})
		);
		updateFinalReportMutate(preparedValues);
	});

	const pairFullName = userDisplayName(
		(currentApplicationData.applicationRole === 'mentee' ? currentUser : application.programMembership.user) as User
	);

	return (
		<>
			<SubPageTitle title={`${t('userPanel.finalReport.finalReportForMentee')}: ${pairFullName}`} />
			<Page>
				<MessageBox
					icon="info-circle"
					iconSet="pi"
					message={t('userPanel.finalReport.message')}
					variant="purple"
				/>
				<ol className="flex flex-column gap-2">
					<li>{t('userPanel.finalReport.instruction.first')}</li>
					<li>{t('userPanel.finalReport.instruction.second')}</li>
					<li>
						<Trans
							t={t}
							i18nKey="userPanel.finalReport.instruction.third"
							components={[
								<Link
									to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/process-summary`}
									className="p-link"
								/>,
							]}
						/>
					</li>
				</ol>
				{panelType === 'mentee' && !finalReportData?.visibleForMentee && (
					<MessageBox message={t('userPanel.finalReport.notVisible')} variant="rose" highlight />
				)}
			</Page>

			{(panelType === 'mentor' || (panelType === 'mentee' && finalReportData?.visibleForMentee)) && (
				<Page>
					<form onSubmit={handleSubmitForm} className="flex flex-column gap-4">
						{panelType === 'mentor' && (
							<p className="my-2">
								{t('userPanel.finalReport.entryMessageForMentor1')}
								<br />
								<br />
								{t('userPanel.finalReport.entryMessageForMentor2')}
								<br />
								{t('userPanel.finalReport.entryMessageForMentor3')}
							</p>
						)}
						{panelType === 'mentee' && (
							<p className="my-2">
								{t('userPanel.finalReport.entryMessageForMentee1')}
								<br />
								<br />
								{t('userPanel.finalReport.entryMessageForMentee2')}
								<br />
								{t('userPanel.finalReport.entryMessageForMentee3')}
							</p>
						)}

						<div className="flex flex-column gap-4">
							{(goalsData?.goals || [])?.map(({ id, goal }: any, index: number) => {
								const goalNum = index + 1;
								return (
									<Box variant="white-bordered" full wrap>
										<BoxSection
											header={t('userPanel.finalReport.goalNo', { index: goalNum, goal })}
											contentClassName="flex flex-column gap-2"
											headerClassName="text-primary text-lg ml-2 mt-2"
										>
											{[
												{
													name: 'achievements',
													label: t('userPanel.finalReport.menteeAchievments'),
												},
												{
													name: 'working',
													label: t('userPanel.finalReport.menteeWorking'),
												},
												{
													name: 'recommendation',
													label: t('userPanel.finalReport.mentorRecomendation'),
												},
											].map(({ name, label }) => (
												<Field key={name} label={label} htmlFor={`goal.${id}.${name}`}>
													<Controller
														control={control}
														name={`goal.${id}.${name}`}
														render={({ field }) =>
															panelType === 'mentee' ? (
																<div
																	id={`goal.${id}.${name}`}
																	className="input-base disabled"
																	{...field}
																>
																	{field.value || '-'}
																</div>
															) : (
																<InputTextArea id={`goal.${id}.${name}`} {...field} />
															)
														}
													/>
												</Field>
											))}
										</BoxSection>
									</Box>
								);
							})}

							<Box variant="white-bordered" full wrap>
								<BoxSection
									header={t('userPanel.finalReport.generalProcessSummary')}
									contentClassName="flex flex-column gap-2"
									headerClassName="text-primary text-lg ml-2 mt-2"
								>
									{[
										{
											name: 'specific_skills_developed',
											label: t('userPanel.finalReport.specificSkillsDevelopment'),
										},
										{
											name: 'mentoring_process_supportive',
											label: t('userPanel.finalReport.mentoringProcessSupportive'),
										},
										{
											name: 'general_recomendations_for_development',
											label: t('userPanel.finalReport.generalRecomendationForMenteeDevelopment'),
										},
										{
											name: 'mentor_thanks',
											label: t('userPanel.finalReport.mentorThanks'),
										},
										{
											name: 'additional_notes',
											label: t('userPanel.finalReport.additionalNotes'),
										},
									].map(({ name, label }) => (
										<Field key={name} label={label} htmlFor={name}>
											<Controller
												control={control}
												name={name as keyof FinalReportForm}
												render={({ field }) =>
													panelType === 'mentee' ? (
														<div id={name} className="input-base disabled" {...field}>
															{field.value || '-'}
														</div>
													) : (
														<InputTextArea id={name} {...field} />
													)
												}
											/>
										</Field>
									))}
								</BoxSection>
							</Box>
						</div>

						{panelType === 'mentor' && (
							<div className="flex flex-row justify-content-between align-items-center">
								<div className="flex flex-row align-items-center">
									<span className="bold mr-2 ml-2">
										{t('userPanel.finalReport.visibility.visible')}
									</span>
									<Controller
										control={control}
										name="visibility"
										render={({ field }) => (
											<InputSwitch
												checked={field.value === 1}
												onChange={(event) => {
													const newValue = event.value ? 1 : 0;
													field.onChange(newValue);
												}}
											/>
										)}
									/>
								</div>

								<div className="flex justify-content-end">
									<Button submit label={t('actions.save')} loading={updateFinalReportLoading} />
								</div>
							</div>
						)}
					</form>
				</Page>
			)}
		</>
	);
};
