import React, { useMemo } from 'react';
import { Outlet, Navigate, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useAuth } from 'contexts/authContext';
import { useTranslation } from 'react-i18next';
import { PageLoading } from './PageLoading';

interface RequireAuthProps {
	strict: boolean;
}
const RequireAuth = ({ strict }: RequireAuthProps) => {
	const { isLogged, ready, currentUser } = useAuth();
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();
	const scopePath = useMemo(() => {
		if (organizationName && String(organizationName).length > 0 && programName && String(programName).length > 0) {
			return `${organizationName}/${programName}`;
		}
		return undefined;
	}, [organizationName, programName]);
	const location = useLocation();
	const redirectUrl =
		String(location.pathname).length > 0 && String(location.pathname) !== '/' ? location.pathname : scopePath;

	if (!ready) {
		return <PageLoading text={t('auth.authorizationMessage')} />;
	}

	if (!isLogged) {
		return (
			<Navigate
				to={{
					pathname: '/login',
					search: redirectUrl ? `?redirectUrl=${encodeURI(redirectUrl)}` : undefined,
				}}
				state={location}
			/>
		);
	}

	// force require terms page
	if (
		!['/require-terms', '/login'].includes(location.pathname) &&
		ready &&
		isLogged &&
		currentUser &&
		!currentUser.terms
	) {
		return <Navigate to="/require-terms" />;
	}

	return <Outlet />;
};

export default RequireAuth;

interface OutletContext {
	setIsAuthRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useIsAuthRequired() {
	return useOutletContext<OutletContext>();
}
