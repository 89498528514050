import { httpClient } from 'api';
import { AdminNote } from 'types/Chat';

export const adminNotesApi = {
	getAdminNotes: (applicationId: number, programId: number) =>
		httpClient.get<AdminNote[]>(`/admin-notes/${programId}/${applicationId}`),

	addAdminNote: (applicationId: number, content: string, programId: number) =>
		httpClient.post<AdminNote>(`/admin-notes/${programId}/${applicationId}`, { content }),

	deleteAdminNote: (noteId: number, programId: number) =>
		httpClient.delete<void>(`/admin-notes/${programId}/${noteId}`),
};
