import React, { PropsWithChildren, ReactNode, Ref, forwardRef } from 'react';
import { universalRenderer } from 'utils/universalRenderer';
import { Box } from '../Box';
import './Page.scss';

export type PageProps = PropsWithChildren<{
	className?: string;
	beforeLeft?: ReactNode | (() => ReactNode);
	beforeRight?: ReactNode | (() => ReactNode);
	afterLeft?: ReactNode | (() => ReactNode);
	afterRight?: ReactNode | (() => ReactNode);
	header?: ReactNode | (() => ReactNode);
}>;

export const Page = forwardRef(
	(
		{ beforeLeft, beforeRight, afterLeft, afterRight, header, children, className, ...restProps }: PageProps,
		ref: Ref<HTMLDivElement>
	) => {
		return (
			<div className="page-container">
				{(beforeLeft || beforeRight) && (
					<div className="flex flex-row justify-between mb-2 py-3">
						{beforeLeft && (
							<div className="flex-1 flex justify-content-start">{universalRenderer(beforeLeft)}</div>
						)}
						{beforeRight && (
							<div className="flex-1 flex justify-content-end">{universalRenderer(beforeRight)}</div>
						)}
					</div>
				)}
				<Box ref={ref} className="page" {...restProps}>
					{header && <div>{universalRenderer(header)}</div>}
					<div className={className}>{children}</div>
				</Box>
				{(afterLeft || afterRight) && (
					<div className="flex flex-row justify-between mt-2">
						{afterLeft && (
							<div className="flex-1 flex justify-content-start">{universalRenderer(afterLeft)}</div>
						)}
						{afterRight && (
							<div className="flex-1 flex justify-content-end">{universalRenderer(afterRight)}</div>
						)}
					</div>
				)}
			</div>
		);
	}
);
