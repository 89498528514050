import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from 'assets/mentiway-banner.svg';
import { useAuth } from '../contexts/authContext';
import { Button } from './_common/Button';
import { Box, BoxSection } from './_new/Box';

type ProgramFinishedPageProps = {
	nextProgramName?: string | null;
	currentProgramFinished?: boolean;
};

export const ProgramFinishedPage = (props: ProgramFinishedPageProps) => {
	const { nextProgramName, currentProgramFinished } = props;
	const { t } = useTranslation();
	const { currentUser, currentMemberships, logout } = useAuth();

	const showNoAccessMessage = currentUser && !currentMemberships?.length && !currentProgramFinished;

	return (
		<div className="flex flex-column align-items-center justify-content-center row-gap-6 pt-8 pb-7">
			<Box variant="white" dropShadow>
				<BoxSection className="px-6 py-4 text-center" contentClassName="w-360-full">
					<img src={banner} alt="banner" className="mt-2 mb-6 align-self-center" />
					{currentProgramFinished && <p>{t('misc.programFinished')}</p>}
					{showNoAccessMessage && <p>{t('misc.noAccessToProgram')}</p>}

					{nextProgramName && (
						<Link
							to={{ pathname: `/${nextProgramName}` }}
							className="p-button flex-column text-center mt-3 mb-3"
						>
							{t('misc.goToNextProgram')}
						</Link>
					)}

					{currentUser && currentMemberships && currentMemberships?.length > 0 && (
						<Link to="/change-organization" className="p-button flex-column text-center mt-3 mb-3">
							{t('misc.chooseAnotherProgram')}
						</Link>
					)}
					{!currentUser && (
						<Link to="/login" className="p-button flex-column text-center mt-3 mb-3">
							{t('misc.backToLogin')}
						</Link>
					)}
					{!currentMemberships?.length && (
						<Button className="p-button flex-column text-center mt-3 mb-3" onClick={() => logout()}>
							{t('landingPages.buttons.logout')}
						</Button>
					)}

					<p>
						<Trans
							t={t}
							i18nKey="misc.checkSocials"
							components={[
								<a className="p-link" href="https://mentiway.com/">
									Mentiway
								</a>,
								<a className="p-link" href="https://www.linkedin.com/company/mentiway/">
									LinkedIn
								</a>,
							]}
						/>
					</p>
				</BoxSection>
			</Box>
		</div>
	);
};
