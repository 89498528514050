import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { DialogAction, DialogBaseProps } from '../../_new/Dialog';
import { useGlobal } from '../../../contexts/globalContext';
import { organizationsApi, usersApi } from '../../../api';
import CustomLabel from '../forms/Label';
import TextField from '../forms/TextField';
import { Dropdown } from '../forms/Dropdown';
import Checkbox from '../forms/Checkbox';
import { Program } from '../../../types/Program';
import { User } from '../../../types/User';
import { useOrganizationPanel } from '../../../contexts/organizationPanelContext';
import { CreateUserAsAdminPayload } from '../../../types/payloads/CreateUserAsAdminPayload';

interface UpdateUserAsAdminPayload {
	firstName: string;
	lastName: string;
	email: string;
	language: string;
}

type UserFormData = {
	firstName: string;
	lastName: string;
	email: string;
	language: string;
	notifyCreatedUser: boolean;
	organisationProgramId?: number;
};

interface AddUserDialogProps extends DialogBaseProps {
	isOrganisationPanel: boolean;
	program?: Program | undefined;
	onUserAddSuccess: () => void;
	editMode?: boolean;
	userToEdit?: User | null;
}

export const AddUserDialog = ({
	onHide,
	isOrganisationPanel,
	program,
	onUserAddSuccess,
	editMode = false,
	userToEdit = null,
	...props
}: AddUserDialogProps) => {
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();

	const { control, handleSubmit, watch, reset } = useForm<UserFormData>({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			language: program?.language || '',
			notifyCreatedUser: false,
		},
	});

	useEffect(() => {
		if (editMode && userToEdit) {
			reset({
				firstName: userToEdit.firstName || '',
				lastName: userToEdit.lastName || '',
				email: userToEdit.email || '',
				language: userToEdit.language || program?.language || '',
				notifyCreatedUser: false,
			});
		} else if (!editMode) {
			reset({
				firstName: '',
				lastName: '',
				email: '',
				language: program?.language || '',
				notifyCreatedUser: false,
			});
		}
	}, [editMode, userToEdit, reset, program?.language]);

	const handleSubmitForm = handleSubmit((values) => {
		if (editMode && userToEdit) {
			updateUser({ userId: userToEdit.id, data: values });
		} else {
			createUser({ programId: values.organisationProgramId || Number(program?.id), data: values });
		}
	});

	const { mutate: createUser, isLoading: isCreating } = useMutation(
		(input: { programId: number; data: UserFormData }) => {
			const payload: CreateUserAsAdminPayload = {
				firstName: input.data.firstName,
				lastName: input.data.lastName,
				email: input.data.email,
				language: input.data.language,
				notifyCreatedUser: input.data.notifyCreatedUser,
			};
			return usersApi.createUser(input.programId, payload);
		},
		{
			onError: ({ response }) => {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: t('misc.error'),
					detail: t(`userPanel.myAccount.${response.data.message}`),
				});
			},
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
				});
				reset({
					firstName: '',
					lastName: '',
					email: '',
					language: program?.language || '',
					notifyCreatedUser: false,
				});
				onHide?.();
				onUserAddSuccess();
			},
		}
	);

	const { mutate: updateUser, isLoading: isUpdating } = useMutation(
		(input: { userId: number; data: UserFormData }) => {
			const payload: UpdateUserAsAdminPayload = {
				firstName: input.data.firstName,
				lastName: input.data.lastName,
				email: input.data.email,
				language: input.data.language,
			};
			return usersApi.updateUser(Number(program?.id), input.userId, payload);
		},
		{
			onError: ({ response }) => {
				if (response.data.message === 'email_already_exists') {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.error'),
						detail: t('organizationPanel.memberships.emailAlreadyExists'),
					});
				} else {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.error'),
						detail: t(`userPanel.myAccount.${response.data.message}`),
					});
				}
			},
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
				});
				onHide?.();
				onUserAddSuccess();
			},
		}
	);

	const actions = [
		{
			key: 'save',
			submit: true,
			form: 'userForm',
			label: t('actions.save'),
			loading: isCreating || isUpdating,
		},
	];

	const {
		currentOrganization: { organizationName },
	} = useOrganizationPanel();

	const { data: organizationPrograms } = useQuery(
		['getOrganizationPrograms', organizationName],
		() => organizationsApi.getOrganizationPrograms({ organizationName: String(organizationName) }),
		{
			enabled: Boolean(organizationName && !editMode),
		}
	);

	return (
		<DialogAction
			title={editMode ? t('userPanel.myAccount.editUser') : t('userPanel.myAccount.addNewUser')}
			size="md"
			actions={actions}
			onHide={onHide}
			{...props}
		>
			<form id="userForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
				{!editMode && (
					<div className="flex flex-column mb-2">
						<p>{t('userPanel.myAccount.addNewUserSubtitle')}</p>
					</div>
				)}
				<div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="firstname" label={t('userPanel.myAccount.firstName')} required />
						<TextField
							name="firstName"
							control={control}
							placeholder={t('userPanel.myAccount.firstName')}
							required
						/>
					</div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="lastname" label={t('userPanel.myAccount.lastName')} required />
						<TextField
							name="lastName"
							control={control}
							placeholder={t('userPanel.myAccount.lastName')}
							required
						/>
					</div>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="email" label="Email" required />
					<TextField
						name="email"
						control={control}
						placeholder={t('userPanel.myAccount.email')}
						rules={{
							validate: {
								customRequired: () => {
									const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
									if (!regex.test(watch('email'))) {
										return t('userPanel.myAccount.invalidEmail');
									}
									return true;
								},
							},
						}}
						required
					/>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="selectLanugage" label={t('userPanel.myAccount.selectLanguage')} required />
					<Controller
						name="language"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={[
									{ value: 'pl', label: 'PL' },
									{ value: 'en', label: 'EN' },
								]}
								rounded
								required
								placeholder={t('userPanel.myAccount.selectLanguage')}
							/>
						)}
					/>
				</div>
				{!editMode && (
					<>
						<div>
							<Checkbox
								text={t('userPanel.myAccount.notifyCreatedUser')}
								control={control}
								name="notifyCreatedUser"
							/>
						</div>
						{isOrganisationPanel && (
							<div className="flex flex-column gap-1">
								<CustomLabel
									name="organisationProgramId"
									label={t('userPanel.myAccount.chooseProgram')}
									required
								/>
								<Controller
									name="organisationProgramId"
									control={control}
									render={({ field }) => (
										<Dropdown
											rounded
											{...field}
											options={organizationPrograms?.map((program) => ({
												value: program.id,
												label: program.displayName || '',
											}))}
											required
											placeholder={t('userPanel.myAccount.chooseProgram')}
										/>
									)}
								/>
							</div>
						)}
					</>
				)}
			</form>
		</DialogAction>
	);
};
