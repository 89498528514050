import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Dialog } from 'components/_new/Dialog';
import { Button } from 'components/_new/Button';
import { Spinner } from 'components/_new/Spinner';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { classNames } from 'primereact/utils';
import ConfirmDialog from 'components/_common/ConfirmDialog';
import { Tooltip } from 'primereact/tooltip';
import { AdminNote } from '../../types/Chat';
import { User } from '../../types/User';
import { InputText } from '../_new/InputText';
import { Avatar } from '../_new/Avatar';
import { userDisplayName } from '../../utils/userDisplayName';
import { Divider } from '../_new/Divider';
import { dateFormat } from '../../utils/dateFormat';
import { useAuth } from '../../contexts/authContext';
import { adminNotesApi } from '../../api';

interface AdminNotesModalProps {
	applicationId: number;
	programId: number;
	onHide: () => void;
	visible: boolean;
	interlocutor: User;
	onNotesCountChange?: (count: number) => void;
}

interface FormData {
	message: string;
}

const AdminNotesModal: React.FC<AdminNotesModalProps> = ({
	applicationId,
	programId,
	onHide,
	visible,
	interlocutor,
	onNotesCountChange,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid },
	} = useForm<FormData>();

	const {
		data: notes,
		isLoading,
		isError,
		refetch: refetchNotes,
	} = useQuery<AdminNote[]>(
		['admin-notes', applicationId],
		() => adminNotesApi.getAdminNotes(applicationId, programId),
		{
			enabled: !!applicationId,
			refetchOnWindowFocus: false,
			suspense: false,
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	);

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [noteToDelete, setNoteToDelete] = useState<number | null>(null);

	const { currentUser } = useAuth();
	useEffect(() => {
		if (visible && applicationId) {
			refetchNotes();
		}
	}, [visible, refetchNotes]);

	useEffect(() => {
		if (notes && onNotesCountChange) {
			onNotesCountChange(notes.length);
		}
	}, [notes, onNotesCountChange]);

	const prepareDate = useCallback((date: Date) => {
		const messageDate = dayjs(date);
		const today = dayjs().startOf('day');
		const isToday = messageDate.isSame(today, 'day');

		if (isToday) {
			return messageDate.format('HH:mm');
		}
		return dateFormat(date, 'full');
	}, []);

	const addNoteMutation = useMutation(
		(content: string) => {
			return adminNotesApi.addAdminNote(applicationId, content, programId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['admin-notes', applicationId]);
				reset();
			},
			onError: (error) => {
				console.error('Error adding note:', error);
			},
		}
	);

	const deleteNoteMutation = useMutation((noteId: number) => adminNotesApi.deleteAdminNote(noteId, programId), {
		onSuccess: () => {
			queryClient.invalidateQueries(['admin-notes', applicationId]);
			setConfirmationOpen(false);
			setNoteToDelete(null);
		},
	});

	const handleSubmitForm = handleSubmit((data: FormData) => {
		if (data.message.trim()) {
			addNoteMutation.mutate(data.message);
		}
	});

	const handleDeleteClick = (noteId: number) => {
		setNoteToDelete(noteId);
		setConfirmationOpen(true);
	};

	const handleConfirmDelete = () => {
		if (noteToDelete) {
			deleteNoteMutation.mutate(noteToDelete);
		}
	};
	const renderNotes = useCallback(() => {
		if (isLoading) {
			return <Spinner />;
		}

		if (isError) {
			return <div className="text-error">{t('chat.errorLoadingNotes')}</div>;
		}

		if (notes && notes.length > 0) {
			return (
				<>
					<Tooltip target=".avatar-tooltip" />
					<ul className="list-none p-0">
						{notes.map((note) => (
							<li key={note.id} className="mb-2 p-2">
								<div className="chat-message gap-2">
									<div
										className="py-1 avatar-tooltip"
										data-pr-tooltip={`${note.author.firstName} ${note.author.lastName}`}
										data-pr-position="top"
									>
										<Avatar
											src={note.author.avatar}
											name={`${note.author.firstName} ${note.author.lastName}`}
										/>
									</div>
									<div
										className={classNames(
											'chat-message-cloud',
											'chat-message-your',
											'flex flex-row justify-content-between'
										)}
									>
										<div>
											{note.content}
											<div
												className="flex flex-row gap-1 chat-message-date"
												title={dateFormat(note.createdAt, 'full')}
											>
												<span>{prepareDate(note.createdAt)}</span>
											</div>
										</div>
										{currentUser?.id === note.author.id && (
											<Button
												label="Remove"
												icon="trash"
												iconOnly
												variant="rose-text"
												variantSize="sm"
												onClick={() => handleDeleteClick(note.id)}
												className="p-button-text p-button-danger"
											/>
										)}
									</div>
								</div>
							</li>
						))}
					</ul>
				</>
			);
		}

		return <span className="text-center text-gray-600">{t('chat.noNotesYet')}</span>;
	}, [isLoading, isError, notes, t, prepareDate, deleteNoteMutation]);

	return (
		<>
			<Dialog
				title={t('chat.adminNotes')}
				size="lg"
				maxHeight
				bodyClassName="overflow-y-auto p-0 z-99999"
				onHide={onHide}
				visible={visible}
			>
				<div className="m-4">
					<div className="flex-1 flex flex-column gap-2 h-full">
						<div className="flex-0 flex flex-row gap-3 w-full">
							<Avatar name={userDisplayName(interlocutor, null)} src={interlocutor.avatar} size="lg" />
							<div className="flex flex-column gap-1">
								<span className="text-lg text-primary font-bold">{userDisplayName(interlocutor)}</span>
								<span>{interlocutor.email}</span>
							</div>
						</div>
						<Divider />
					</div>
					<form
						onSubmit={handleSubmitForm}
						className="flex-0 flex flex-row gap-2 mt-2 mb-2"
						style={{ minHeight: 50 }}
					>
						<InputText
							{...register('message', { required: true, maxLength: 255 })}
							autoComplete="off"
							maxLength={255}
						/>
						<Button submit label={t('actions.add')} loading={addNoteMutation.isLoading} />
					</form>

					{renderNotes()}
				</div>
			</Dialog>
			<ConfirmDialog
				confirmationOpen={confirmationOpen}
				setConfirmationOpen={setConfirmationOpen}
				handleAccept={handleConfirmDelete}
				header={t('chat.deleteNoteConfirmTitle')}
				message={t('chat.deleteNoteConfirmMessage')}
			/>
		</>
	);
};

export default AdminNotesModal;
