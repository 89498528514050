import React, { useRef, forwardRef, useImperativeHandle, useCallback, useMemo, useState, useEffect } from 'react';
import { createReactEditorJS } from 'react-editor-js';

import { useGlobal } from 'contexts/globalContext';
import { useTranslation } from 'react-i18next';
import EditorJS, { API, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import { useAuth } from 'contexts/authContext';
// @ts-ignore
import DragDrop from 'editorjs-drag-drop';
import { classNames } from 'primereact/utils';
import { locales } from './locales';
import { createTools } from './tools';
import './BlockEditor.scss';

const ReactEditorJS = createReactEditorJS();

interface BlockEditorRef {
	getContent: () => Promise<string | null>;
}

type BlockEditorProps = {
	hasError?: boolean;
	onChange?: (blocks: OutputData) => void;
	defaultValue?: OutputData;
};

export const BlockEditor = forwardRef<BlockEditorRef, BlockEditorProps>((props, ref) => {
	const { hasError, onChange, defaultValue } = props;

	const { t } = useTranslation();
	const { currentUser } = useAuth();
	const language = currentUser?.language || 'en';
	const i18n = locales[language] || locales.en;

	const { toastRef } = useGlobal();
	const editorCore = useRef<any>(null);

	useImperativeHandle(ref, () => ({
		getContent: async () => {
			if (editorCore.current) {
				try {
					const outputData: string = await editorCore.current.save();
					return outputData || '';
				} catch (error) {
					console.error('BlockEditror prepare content error:', error);
					return null;
				}
			}
			return null;
		},
	}));

	const handleInitialize = useCallback((instance: any) => {
		editorCore.current = instance;
	}, []);

	const [ready, setReady] = useState(false);
	const handleReady = useCallback(() => {
		setReady(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (ready && editorCore.current && defaultValue) {
			// eslint-disable-next-line no-underscore-dangle
			const editor = editorCore.current._editorJS as EditorJS;
			if (editor && defaultValue.blocks) {
				try {
					editor.blocks.insertMany(defaultValue.blocks || []);
				} catch (error) {
					console.error('BlockEditror defaultValue error:', error);
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t(`misc.error`),
						detail: t('misc.blockEditorDefaultValueError'),
					});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue, ready, editorCore]);

	const handleChange = useCallback(
		async (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => {
			const blocks = await api.saver.save();
			onChange?.(blocks);
		},
		[onChange]
	);

	const tools = useMemo(
		() =>
			createTools({
				i18n,
				uploadImage: {
					onFileOversize: () => {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t(`misc.error`),
							detail: t('misc.fileSizeExceeds', { count: 3 }),
						});
					},
					onInvalidImageFormat: () => {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t(`misc.error`),
							detail: t('misc.fileInvalidFileType'),
						});
					},
					onError: () => {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t(`misc.error`),
							detail: t('misc.fileUnexpectedError'),
						});
					},
				},
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[i18n]
	);

	return (
		<div
			className={classNames('p-inputtext p-component border-round-3xl block-editor block-editor-tool', {
				'p-invalid': hasError,
			})}
		>
			<div className="block-editor-page-container">
				<div className="block-editor-page" />
			</div>
			<ReactEditorJS
				onInitialize={handleInitialize}
				onReady={handleReady}
				onChange={handleChange}
				tools={tools}
				i18n={i18n}
				inlineToolbar
			/>
		</div>
	);
});
