/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ClipboardEvent, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { File, FileType } from 'types/File';
import { Message } from 'primereact/message';
import ValidateTextArea from 'components/_common/forms/TextArea';
import { Button } from 'components/_new/Button';
import PageMissing from 'components/PageMissing';
import { PageLoading } from 'components/PageLoading';
import { slugify } from 'utils/helpers';
import { Spinner } from 'components/_new/Spinner';
import { Dialog } from 'components/_new/Dialog';
import { BlockEditorPresenter } from 'components/_new/BlockEditor/BlockEditorPresenter';
import { Link } from 'components/_new/Link';
import { Loader } from 'components/_new/Loader';

interface FileDisplayModuleProps {
	selectedFile: File;
	disableRating?: boolean;
}

const FileDisplayModule: FC<FileDisplayModuleProps> = ({ selectedFile, disableRating = false }) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();

	const { currentApplicationData, currentProgramMembership } = usePanel();

	const {
		data: fileData,
		isLoading,
		isError,
		isFetched,
	} = useQuery(
		['knowledge-file-data', { id: Number(selectedFile?.id) }],
		() =>
			userPanelApi.getFileDataByApplicationAndMembership({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: selectedFile.id,
			}),
		{
			enabled: Boolean(selectedFile),
			refetchOnWindowFocus: false,
		}
	);

	const handleHtmlCopy = useCallback((event: ClipboardEvent) => {
		const selection = document.getSelection() as Selection;
		if (event) {
			event.preventDefault();
			(event as any).clipboardData.setData(
				'text',
				`${selection.toString()} - ${t('userPanel.files.contentCopyMessage')}`
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ratings
	const { control, handleSubmit, reset } = useForm<{ comment: string }>({
		defaultValues: {
			comment: '',
		},
	});
	const { mutateAsync: addFileRatingAsync, isLoading: addFileRatingLoading } = useMutation(
		({ rating, comment }: { rating?: number; comment?: string }) =>
			userPanelApi.addFileRating({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: selectedFile.id,
				rating,
				comment,
			}),
		{
			onSuccess: () => {
				fileRatingRefetch();
			},
		}
	);
	const {
		data: fileRatingData,
		refetch: fileRatingRefetch,
		isLoading: fileRatingLoading,
	} = useQuery(
		['knowledge-rating', { id: selectedFile.id }],
		() =>
			userPanelApi.getFileRating({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: selectedFile.id,
			}),
		{
			enabled: Boolean(selectedFile),
			onSuccess: (data) => {
				if (data && String(data?.comment).length > 0) {
					reset({ comment: data.comment });
				}
			},
		}
	);

	const handleRateUpClick = useCallback(() => {
		const isRatingUp = fileRatingData && fileRatingData.rating === 1;
		addFileRatingAsync({ rating: isRatingUp ? 0 : 1 }).then(({ rating }) => {
			if (rating !== 0) {
				setAfterFeadbackModalOpen(true);
			}
		});
	}, [addFileRatingAsync, fileRatingData]);

	const handleRateDownClick = useCallback(() => {
		const isRatingUp = fileRatingData && fileRatingData.rating === -1;
		addFileRatingAsync({ rating: isRatingUp ? 0 : -1 }).then(({ rating }) => {
			if (rating !== 0) {
				setAfterFeadbackModalOpen(true);
			}
		});
	}, [addFileRatingAsync, fileRatingData]);

	const handleSubmitForm = handleSubmit(({ comment }) => {
		if (fileRatingData && fileRatingData.rating !== 0) {
			addFileRatingAsync({ comment }).then(() => {
				setAfterFeadbackModalOpen(false);
			});
		}
	});

	const prepareFilename = (file: File) => {
		const slugName = slugify(file.name);
		const extension = ['file', 'pdf'].includes(file.type) ? `.pdf` : '';
		return file.originalFilename || `${slugName}${extension}`;
	};

	const handleDownload = useCallback(() => {
		if (fileData && typeof fileData === 'object') {
			const { signedUrl } = fileData;
			if (signedUrl) {
				const downloadLink = document.createElement('a');
				downloadLink.href = signedUrl;
				downloadLink.rel = 'noreferrer';
				downloadLink.target = '_blank';
				downloadLink.download = prepareFilename(fileData.file);
				document.body.append(downloadLink);
				downloadLink.click();
				setTimeout(() => {
					downloadLink.remove();
				}, 100);
			}
		}
	}, [fileData]);

	const getParsedContent = useCallback(
		(content?: string | null) => {
			// eslint-disable-next-line no-case-declarations
			const parseFileData = (subcontent: string) => {
				try {
					const preParser = (_data: string) => {
						let data = _data;
						if (organizationName) {
							data = data.replaceAll('{{organization}}', organizationName);
						}
						if (programName) {
							data = data.replaceAll('{{program}}', programName);
						}
						if (type) {
							data = data.replaceAll('{{usertype}}', type);
						}
						return data;
					};
					return parse(preParser(subcontent));
				} catch {
					return 'Wystąpił błąd podczas wyświetlania zawartości.';
				}
			};
			if (typeof content === 'string' && content.length > 0) {
				return <div onCopy={(event) => handleHtmlCopy(event as any)}>{parseFileData(String(content))}</div>;
			}
			return null;
		},
		[handleHtmlCopy, organizationName, programName, type]
	);

	const [afterFeadbackModalOpen, setAfterFeadbackModalOpen] = useState(false);

	const renderFileContent = () => {
		if (fileData) {
			const downloadFilename = prepareFilename(fileData.file);
			switch (fileData.file.type) {
				case 'html_blocks':
					return <BlockEditorPresenter input={fileData.file.descriptionBlocks} />;
				case 'html_wyswig':
					return getParsedContent(fileData.file.description);
				case 'html':
					return getParsedContent(fileData.content);
				case 'file':
					return (
						<div className="flex flex-column gap-2">
							{getParsedContent(fileData.file.description)}
							<div className="flex flex-row gap-2 justify-content-end">
								<Button
									label={t('misc.downloadFile')}
									onClick={handleDownload}
									disabled={!fileData.signedUrl}
								/>
							</div>
						</div>
					);
				case 'video':
					// eslint-disable-next-line no-case-declarations
					let src = null;
					try {
						src = `https://www.youtube.com/embed/${(fileData.file.url as string).split('=')[1]}`;
					} catch {
						return 'Wystąpił błąd podczas ładwania filmu';
					}
					return (
						<div className="flex flex-column gap-2">
							{getParsedContent(fileData.file.description)}
							<iframe width="100%" height="500px" src={src} title={fileData.file.name} allowFullScreen />
						</div>
					);
				case 'pdf':
					if (fileData.file.originalFilename && fileData.signedUrl) {
						return (
							<div className="flex flex-column gap-2">
								{getParsedContent(fileData.file.description)}
								<iframe
									className="iframe-view iframe-pdf"
									src={fileData.signedUrl}
									title={downloadFilename}
									width="100%"
									height="500px"
									aria-label="pdf file preview"
									allowFullScreen
								/>
								<div className="text-center">
									<Trans
										t={t}
										i18nKey="userPanel.userFiles.openPdfAlternative"
										components={[
											<Link to={fileData.signedUrl} target="_blank" variant="standard" />,
										]}
									/>
								</div>
								<div className="flex flex-row gap-2 justify-content-end">
									<Button label={t('misc.downloadFile')} onClick={handleDownload} />
								</div>
							</div>
						);
					}
					return (
						<div className="flex flex-column gap-2">
							{getParsedContent(fileData.file.description)}
							<Spinner />
						</div>
					);
				case 'link':
					try {
						if (selectedFile.url === null) {
							throw new Error('invalid url');
						} else {
							const url = new URL(selectedFile.url);
							window.location.href = url.href;
							return <Spinner center />;
						}
					} catch {
						return t('userPanel.userFiles.file.invalidUrl');
					}
				default:
					return (
						<Message
							severity="error"
							text={t('misc.genericError')}
							className="w-full justify-content-start pl-4"
						/>
					);
			}
		}
		return null;
	};

	if (isError) return <PageMissing />;

	if (!fileData || isLoading) return <Loader />;

	return (
		<>
			<div className="p-knowledge-base-article">{renderFileContent()}</div>
			{!disableRating && (
				<>
					<Dialog
						title={t('userPanel.files.rating.thanks1')}
						visible={afterFeadbackModalOpen}
						onHide={() => {
							setAfterFeadbackModalOpen(false);
						}}
					>
						<p>{t('userPanel.files.rating.thanks2')}</p>
						<form onSubmit={handleSubmitForm} className="w-full flex flex-column gap-1 mt-2">
							<ValidateTextArea
								control={control}
								name="comment"
								containerClassName="w-full"
								placeholder={t('userPanel.files.rating.commentPlaceholder')}
							/>
							<div className="flex flex-row justify-content-end">
								<Button submit label={t('actions.save')} loading={addFileRatingLoading} />
							</div>
						</form>
					</Dialog>
					<div className="flex flex-column gap-1 align-items-center">
						<span>{t('userPanel.files.rating.question')}</span>
						<span className="inline-flex gap-2">
							<Button
								label={t('misc.yes')}
								onClick={handleRateUpClick}
								title="Rate up"
								disabled={addFileRatingLoading || fileRatingLoading}
								variant={!(fileRatingData?.rating === 1) ? 'grey-text' : 'primary-text'}
								variantSize="lg"
								icon="thumbs-up"
								iconSet="fa"
								iconStyle="regular"
								iconClassName="text-3xl"
								iconPosition="right"
								className="p-1"
							/>
							<Button
								label={t('misc.no')}
								onClick={handleRateDownClick}
								title="Rate down"
								disabled={addFileRatingLoading || fileRatingLoading}
								variant={!(fileRatingData?.rating === -1) ? 'grey-text' : 'primary-text'}
								variantSize="lg"
								icon="thumbs-down"
								iconSet="fa"
								iconStyle="regular"
								iconClassName="text-3xl"
								className="p-1"
							/>
						</span>
					</div>
				</>
			)}
		</>
	);
};

export default FileDisplayModule;
