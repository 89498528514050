import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Session } from 'types/Session';
import { InputTextArea } from 'components/_new/InputTextArea';
import { Button } from 'components/_new/Button';
import { useGlobal } from 'contexts/globalContext';
import { useScope } from 'contexts/scopeContext';
import { Box, BoxSection } from 'components/_new/Box';
import { Link } from '../../../_new/Link';
import { usePair } from '../../../../contexts/pairContext';

type HomeworkSubpageProps = {
	session: Session;
	saveHandler: (data: { mostImportant: string; practice: string; homework: string }) => Promise<any>;
};

type HomeworkForm = {
	mostImportant: string;
	practice: string;
	homework: string;
};

export const HomeworkSubpage = ({ session, saveHandler }: HomeworkSubpageProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();
	const { currentProgram } = useScope();

	const { control, register, handleSubmit } = useForm<HomeworkForm>({
		defaultValues: { mostImportant: session.mostImportant, practice: session.practice, homework: session.homework },
	});
	const { organizationName, programName, type } = useParams();
	const {
		pair: { id: pairId },
	} = usePair();
	const [loading, setLoading] = useState<boolean>(false);
	const handleSubmitForm = handleSubmit(({ mostImportant, practice, homework }) => {
		setLoading(true);
		saveHandler({ mostImportant, practice, homework })
			.then(() => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessionsNew.homework.saveMessage'),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	});
	return (
		<form className="flex flex-column gap-2" onSubmit={handleSubmitForm}>
			<h2 className="my-5">{t('userPanel.sessionsNew.homework.heading')}</h2>
			<p>
				<Trans
					i18nKey="userPanel.sessionsNew.homework.desc"
					components={[
						<Link
							to={
								currentProgram?.language === 'pl'
									? `/panel/${organizationName}/${programName}/${type}/files/pigulki-wiedzy/praca-wlasna-mentee`
									: `/panel/${organizationName}/${programName}/${type}/files/knowledge/mentee's-homeworks`
							}
							target="_blank"
							rel="noopener noreferrer"
							className="inline"
						/>,
					]}
				/>
			</p>

			<Box variant="white-bordered">
				<Controller
					name="mostImportant"
					control={control}
					render={({ field: { value, ...restField } }) => (
						<BoxSection
							header={t('userPanel.sessionsNew.homework.mostImprtantField')}
							headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
							contentClassName="pb-2"
						>
							<InputTextArea rows={3} {...restField} value={value} defaultValue={value} />
						</BoxSection>
					)}
				/>
			</Box>

			<Box variant="white-bordered">
				<Controller
					name="practice"
					control={control}
					render={({ field: { value, ...restField } }) => (
						<BoxSection
							header={t('userPanel.sessionsNew.homework.practiceField')}
							headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
							contentClassName="pb-2"
						>
							<InputTextArea rows={3} {...restField} value={value} defaultValue={value} />
						</BoxSection>
					)}
				/>
			</Box>

			<Box variant="white-bordered">
				<Controller
					name="homework"
					control={control}
					render={({ field: { value, ...restField } }) => (
						<BoxSection
							header={t('userPanel.sessionsNew.homework.homeworkField')}
							headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
							contentClassName="pb-2"
						>
							<InputTextArea rows={3} {...restField} value={value} defaultValue={value} />
						</BoxSection>
					)}
				/>
			</Box>

			<div className="flex flex-row justify-content-end">
				<Button submit label={t('actions.save')} loading={loading} />
			</div>
		</form>
	);
};
