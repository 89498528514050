import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { userFilesApi } from 'api';
import { SessionFile } from 'types/SessionFile';
import { Button } from 'components/_common/Button';
import { Message } from 'primereact/message';
import { Spinner } from 'components/_new/Spinner';
import { slugify } from 'utils/helpers';
import { BlockEditorPresenter } from 'components/_new/BlockEditor/BlockEditorPresenter';
import { Link } from 'components/_new/Link';
import { useParams } from 'react-router-dom';

type DisplayModuleProps = {
	userFileId: number;
};

export const DispalyModule = ({ userFileId }: DisplayModuleProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();

	const {
		data: getUserfileData,
		isLoading: getUserfileLoading,
		isSuccess: getUserfileSuccess,
		isFetched: getUserfileFetched,
	} = useQuery(['userfile', { userFileId }], () => userFilesApi.getUserfile(Number(userFileId)), {
		enabled: Boolean(userFileId),
	});

	const handleHtmlCopy = useCallback((event: ClipboardEvent) => {
		const selection = document.getSelection() as Selection;
		if (event) {
			event.preventDefault();
			(event as any).clipboardData.setData(
				'text',
				`${selection.toString()} - ${t('userPanel.files.contentCopyMessage')}`
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getParsedContent = useCallback(
		(content?: string | null) => {
			// eslint-disable-next-line no-case-declarations
			const parseFileData = (subcontent: string) => {
				try {
					const preParser = (_data: string) => {
						let data = _data;
						if (organizationName) {
							data = data.replaceAll('{{organization}}', organizationName);
						}
						if (programName) {
							data = data.replaceAll('{{program}}', programName);
						}
						if (type) {
							data = data.replaceAll('{{usertype}}', type);
						}
						return data;
					};
					return parse(preParser(subcontent));
				} catch {
					return 'Wystąpił błąd podczas wyświetlania zawartości.';
				}
			};
			if (typeof content === 'string' && content.length > 0) {
				return <div onCopy={(event) => handleHtmlCopy(event as any)}>{parseFileData(String(content))}</div>;
			}
			return null;
		},
		[handleHtmlCopy, organizationName, programName, type]
	);

	const prepareFilename = (file: SessionFile) => {
		const slugName = slugify(file.name);
		const extension = ['file', 'pdf'].includes(file.type) ? `.pdf` : '';
		return file.originalFilename || `${slugName}${extension}`;
	};

	const handleDownload = useCallback(() => {
		if (getUserfileData && typeof getUserfileData === 'object') {
			const { signedUrl } = getUserfileData;
			if (signedUrl) {
				const downloadLink = document.createElement('a');
				downloadLink.href = signedUrl;
				downloadLink.rel = 'noreferrer';
				downloadLink.target = '_blank';
				downloadLink.download = prepareFilename(getUserfileData.userFile);
				document.body.append(downloadLink);
				downloadLink.click();
				setTimeout(() => {
					downloadLink.remove();
				}, 100);
			}
		}
	}, [getUserfileData]);

	const renderFileContent = () => {
		if (getUserfileData) {
			const downloadFilename = prepareFilename(getUserfileData.userFile);
			if (getUserfileSuccess && getUserfileData && getUserfileFetched) {
				switch (getUserfileData.userFile.type) {
					case 'html':
						return getParsedContent(getUserfileData.userFile.content);
					case 'file':
						return (
							<div className="flex flex-column gap-2">
								{getParsedContent(getUserfileData.userFile.content)}
								<div className="flex flex-row gap-2 justify-content-end">
									<Button
										label={t('misc.downloadFile')}
										onClick={handleDownload}
										disabled={!getUserfileData.signedUrl}
									/>
								</div>
							</div>
						);
					case 'video':
						// eslint-disable-next-line no-case-declarations
						let src = null;
						try {
							src = `https://www.youtube.com/embed/${
								(getUserfileData.userFile.url as string).split('=')[1]
							}`;
						} catch {
							return 'Wystąpił błąd podczas ładwania filmu';
						}
						return (
							<div className="flex flex-column gap-2">
								{getParsedContent(getUserfileData.userFile.content)}
								<iframe
									width="100%"
									height="500px"
									src={src}
									title={getUserfileData.userFile.name}
									allowFullScreen
								/>
							</div>
						);
					case 'pdf':
						if (getUserfileData.userFile.originalFilename && getUserfileData.signedUrl) {
							return (
								<div className="flex flex-column gap-2">
									{getParsedContent(getUserfileData.userFile.content)}
									<iframe
										className="iframe-view iframe-pdf"
										src={getUserfileData.signedUrl}
										title={downloadFilename}
										width="100%"
										height="500px"
										aria-label="pdf file preview"
										allowFullScreen
									/>
									<div className="text-center">
										<Trans
											t={t}
											i18nKey="userPanel.userFiles.openPdfAlternative"
											components={[
												<Link
													to={getUserfileData.signedUrl}
													target="_blank"
													variant="standard"
												/>,
											]}
										/>
									</div>
									<div className="flex flex-row gap-2 justify-content-end">
										<Button label={t('misc.downloadFile')} onClick={handleDownload} />
									</div>
								</div>
							);
						}
						return (
							<div className="flex flex-column gap-2">
								{getParsedContent(getUserfileData.userFile.content)}
								<Spinner />
							</div>
						);
					case 'link':
						try {
							if (getUserfileData.userFile.url === null) {
								throw new Error('invalid url');
							} else {
								const url = new URL(getUserfileData.userFile.url);
								window.location.href = url.href;
								return <Spinner />;
							}
						} catch {
							return t('userPanel.userFiles.file.invalidUrl');
						}
					default:
						return (
							<Message
								severity="error"
								text={t('misc.genericError')}
								className="w-full justify-content-start pl-4"
							/>
						);
				}
			}
		}
		return null;
	};

	return (
		<>
			{getUserfileLoading && <Spinner center />}
			{getUserfileSuccess && renderFileContent()}
		</>
	);
};
