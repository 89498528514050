import React, { PropsWithChildren, createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Program } from 'types/Program';
import { programsApi } from 'api';

type ProgramPanelContextValue = {
	organizationName?: string;
	programName?: string;
	data?: Program;
	isLoading?: boolean;
	isSuccess?: boolean;
	isError?: boolean;
	refetch?: () => Promise<any>;
	panelPath?: string;
	isStale?: boolean;
	programPath?: string;
	fullProgramUrl?: string;
};

export const ProgramPanelContext = createContext<ProgramPanelContextValue>({});

// eslint-disable-next-line @typescript-eslint/ban-types
type ProgramPanelContextProviderProps = PropsWithChildren<{}>;

const ProgramPanelContextProvider = ({ children }: ProgramPanelContextProviderProps) => {
	const { organizationName, programName } = useParams();

	const { data, isSuccess, isLoading, isError, refetch, isStale } = useQuery(
		['currentProgramPanel', { organizationName, programName }],
		() =>
			programsApi.getProgramAdmin({
				organizationName: String(organizationName),
				programName: String(programName),
			}),
		{ enabled: Boolean(organizationName) && Boolean(programName), staleTime: 10000 }
	);

	const programPath = `${organizationName}/${programName}`;
	const panelPath = `/program/${programPath}`;
	const fullProgramUrl = `${[process.env.REACT_APP_FRONT_URL]}/${programPath}`;

	return (
		<ProgramPanelContext.Provider
			value={{
				organizationName,
				programName,
				data,
				isSuccess,
				isLoading,
				isError,
				refetch,
				panelPath,
				isStale,
				programPath,
				fullProgramUrl,
			}}
		>
			{children}
		</ProgramPanelContext.Provider>
	);
};

export const useProgramPanel = () => useContext(ProgramPanelContext);

export default ProgramPanelContextProvider;
