import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { userFilesApi } from 'api';
import { BreadCrumb } from 'primereact/breadcrumb';
import { RouterUrlParams } from 'App';
import { Box, BoxSection } from 'components/_new/Box';
import { DispalyModule } from './DisplayModule';

export const DispalyPage = () => {
	const { t } = useTranslation();
	const params = useParams();
	const userFileId = Number(params.userFileId);
	const { organizationName, programName, type, pairId } = useParams() as RouterUrlParams;

	const {
		data: getUserfileData,
		isSuccess: getUserfileSuccess,
		isFetched: getUserfileFetched,
	} = useQuery(['userfile', { userFileId }], () => userFilesApi.getUserfile(Number(userFileId)), {
		enabled: Boolean(userFileId),
	});

	const navigate = useNavigate();
	const homeBreadcrumb = {
		icon: 'pi pi-book',
		command: () => {
			navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/userfiles`);
		},
	};

	return (
		<>
			<Helmet title={t('userPanel.userFiles.title')} />
			<SubPageTitle title={t('userPanel.userFiles.title')} />
			{getUserfileSuccess && (
				<>
					<BreadCrumb
						model={[
							{
								label: t('userPanel.userFiles.title'),
								command: () => {
									navigate(
										`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/userfiles`
									);
								},
							},
							{
								label: `${t('userPanel.userFiles.file.session')} ${
									getUserfileData.userFile.session?.sessionNumber || '-'
								}`,
								command: () => {
									navigate(
										`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${getUserfileData.userFile.sessionId}`
									);
								},
							},
							{
								label: getUserfileData.userFile.name,
							},
						]}
						home={homeBreadcrumb}
						className="mb-3"
					/>
					<Box variant="white-bordered">
						<BoxSection>
							<DispalyModule userFileId={userFileId} />
						</BoxSection>
					</Box>
				</>
			)}
		</>
	);
};
