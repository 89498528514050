import React from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import CustomLabel from './Label';
import { Dropdown } from './Dropdown';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	choiceList: string[];
	description: string | null;
	label?: string;
	underLabel?: string;
	required?: boolean;
	links?: string[] | null;
	onChangeValue?: (value: any) => void;
}
const ValidateSingleChoice = <T extends FieldValues>({
	name,
	description,
	label,
	underLabel,
	choiceList,
	control,
	required = false,
	rules,
	links,
	onChangeValue,
}: Props<T>) => {
	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: required ? `${label} ${t('misc.forms.isRequired')}` : false, ...rules },
	});

	return (
		<div>
			{label && (
				<CustomLabel name={name} required={required}>
					<Trans
						components={
							links
								? links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{label}
					</Trans>
				</CustomLabel>
			)}
			{underLabel && <p className="mt-0 mb-2 px-3 text-sm text-muted">{underLabel}</p>}
			{(choiceList?.length || 0) > 5 ? (
				<Dropdown
					ref={field.ref}
					value={field.value}
					options={choiceList}
					scrollHeight="165px"
					onChange={(e) => {
						if (onChangeValue) {
							onChangeValue(e.value);
						}
						field.onChange(e.value);
					}}
					placeholder={description || t('misc.dropdownPleaseSelect')}
					hasError={!!error}
					rounded
				/>
			) : (
				<div className="px-3">
					<div className="mb-3">
						<Trans
							components={
								links
									? links.map((link) => (
											// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
											<a href={link} target="_blank" rel="noreferrer" className="p-link" />
									  ))
									: undefined
							}
						>
							{description}
						</Trans>
					</div>

					{(choiceList || []).map((item, index) => (
						<div key={item} className="field-radiobutton">
							<RadioButton
								inputRef={index === 0 ? field.ref : undefined}
								inputId={field.name}
								name={field.name}
								value={item}
								onChange={(e) => {
									if (onChangeValue) {
										onChangeValue(e.value);
									}
									field.onChange(e.value);
								}}
								checked={field.value === item}
								className={classNames({ 'p-invalid': !!error })}
							/>

							<label
								onClick={() => {
									if (onChangeValue) {
										onChangeValue(item);
									}
									field.onChange(item);
								}}
								className={classNames({ 'p-error': !!error }, 'cursor-pointer')}
							>
								{item}
							</label>
						</div>
					))}
				</div>
			)}

			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidateSingleChoice;
