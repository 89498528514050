import React, { createContext, useMemo, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { landingPagesApi } from 'api';
import { ProgramWithLanding } from 'types/Program';
import { ProgramMembership } from 'types/Membership';
import { Application } from 'types/Application';
import { StoredKeys } from 'types/Auth';
import { Loader } from 'components/_new/Loader';
import { PageLoading } from 'components/PageLoading';
import { useAuth } from './authContext';

type ScopeContextType = {
	currentProgram?: ProgramWithLanding;
	currentProgramRefetch: () => void;
	currentProgramLoading: boolean;
	currentProgramError?: any;
	hasPanelAccess: boolean;
	currentProgramMembership?: ProgramMembership | null;
	scopePath: string | undefined;
	applicationsData: Application[];
	applicationsRefetch: () => void;
	applicationsFetched: boolean;
	acceptedApplicationsData: Application[];
};

const ScopeContext = createContext<ScopeContextType>({
	currentProgram: undefined,
	currentProgramRefetch: () => {},
	currentProgramLoading: false,
	currentProgramError: undefined,
	hasPanelAccess: false,
	currentProgramMembership: undefined,
	scopePath: undefined,
	applicationsData: [],
	applicationsRefetch: () => {},
	applicationsFetched: false,
	acceptedApplicationsData: [],
});

export const ScopeContextProvider = () => {
	const { organizationName, programName } = useParams();
	const { ready, currentUser } = useAuth();

	const scopePath = useMemo(() => {
		if (organizationName && String(organizationName).length > 0 && programName && String(programName).length > 0) {
			window.localStorage.setItem(StoredKeys.ProgramName, `${organizationName}/${programName}`);
			return `${organizationName}/${programName}`;
		}
		return undefined;
	}, [organizationName, programName]);

	const {
		data: programData,
		refetch: programRefetch,
		isLoading: programLoading,
		isError: programError,
	} = useQuery(
		['GET_PROGRAM_LANDING', scopePath],
		() => landingPagesApi.getProgramWithLanding(String(organizationName), String(programName)),
		{
			enabled: ready && Boolean(scopePath),
			onError: (error) => {
				// prevent toas
			},
		}
	);

	const currentProgramMembership = useMemo(() => {
		if (ready && currentUser && programData) {
			return currentUser && programData
				? (currentUser.programMemberships || []).find(({ programId }) => programId === programData.id) || null
				: null;
		}
		return undefined;
	}, [currentUser, programData, ready]);

	const {
		data: applicationsData,
		refetch: applicationsRefetch,
		isFetched: applicationsFetched,
	} = useQuery(
		['applications', { programMembershipId: currentProgramMembership?.id }],
		() =>
			landingPagesApi.getProgramApplications(Number(currentProgramMembership?.id), {
				applicationType: 'proper',
			}),
		{
			initialData: [],
			enabled: Boolean(currentProgramMembership),
		}
	);

	const acceptedApplicationsData = applicationsData.filter(({ approved }) => approved === 'accepted');
	const hasPanelAccess = acceptedApplicationsData.length > 0;

	if (Boolean(programName && String(programName).length > 0) && programLoading) {
		return <PageLoading />;
	}

	return (
		<ScopeContext.Provider
			value={{
				currentProgram: programData,
				currentProgramRefetch: programRefetch,
				currentProgramLoading: programLoading,
				currentProgramError: programError,
				hasPanelAccess,
				currentProgramMembership,
				scopePath,
				applicationsData,
				applicationsRefetch,
				applicationsFetched,
				acceptedApplicationsData,
			}}
		>
			<Outlet />
		</ScopeContext.Provider>
	);
};

export const useScope = () => useContext(ScopeContext);
