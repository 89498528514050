import { httpClient } from 'api';
import { ContractType } from 'types/Contract';
import { File, FileRating, Folder } from 'types/File';
import { Goals } from 'types/Goal';
import { Pair } from 'types/Pair';
import { Session, SessionType } from 'types/Session';
import { SessionQuestionnaire } from 'types/SessionQuestionnaire';
import { ContractPayload, EditContractPayload } from 'types/payloads/ContractPayload';
import { CreateGoalPayload, UpdateGoalPayload } from 'types/payloads/GoalPayload';
import { InitialMeetingPayload } from 'types/payloads/PairPayload';
import { SessionPayload } from 'types/payloads/SessionPayload';
import { SendIssueReportPayload } from 'types/payloads/SendIssueReportPayload';
import {
	CreateSessionQuestionnairePayload,
	UpdateSessionQuestionnairePayload,
} from 'types/payloads/SessionQuestionnairePayload';
import { Application } from 'types/Application';
import { CalendarMeetingPayload } from '../types/payloads/CalendarMeetingPayload';
import { ContractSettingType } from '../types/ContractSetting';
import { ContractSettingPayload } from '../types/payloads/ContractSettingPayload';
import { AdminQuestion } from '../types/Question';

export const userPanelApi = {
	// Pairing
	postEntryMeeting: (pairId: number, membershipId: number, payload: InitialMeetingPayload) =>
		httpClient.post(`pairs/entryMeeting/${pairId}/${membershipId}`, payload),

	getPairs: (applicationRole: string, membershipId: number) =>
		httpClient.get<Pair[]>(`pairs/${applicationRole}/${membershipId}/all`),
	getCurrentPairs: (applicationRole: string, membershipId: number) =>
		httpClient.get<Pair[]>(`pairs/${applicationRole}/${membershipId}/current`),
	getPairApplicationDetails: (applicationRole: string, membershipId: number, pairId: number) =>
		httpClient.get<Application>(`pairs/${applicationRole}/${membershipId}/pair/${pairId}`),

	rejectPair: (pairId: number, membershipId: number, rejectReason: string) =>
		httpClient.post(`pairs/reject/${pairId}/${membershipId}`, { rejectMessage: rejectReason }),
	acceptPair: (pairId: number, membershipId: number) => httpClient.post(`pairs/accept/${pairId}/${membershipId}`),

	sendCalendarEmail: (pairId: number, membershipId: number, sessionId: number, payload: CalendarMeetingPayload) =>
		httpClient.post(`sessions/send-calendar-email/${pairId}/${membershipId}/${sessionId}`, payload),
	finishProcessForPair: (pairId: number, membershipId: number) =>
		httpClient.post(`pairs/finish/${pairId}/${membershipId}`),
	// Pair Sessions
	getSessions: (pairId: number, membershipId: number) =>
		httpClient.get<Session[]>(`sessions/${pairId}/${membershipId}`),
	createSession: (pairId: number, membershipId: number) =>
		httpClient.post(`sessions/create/${pairId}/${membershipId}`),
	updateSession: (pairId: number, membershipId: number, id: number, payload: SessionPayload) =>
		httpClient.post<Session>(`sessions/update/${pairId}/${membershipId}/${id}`, payload),
	deleteSession: (pairId: number, membershipId: number, sessionId: number) =>
		httpClient.delete(`sessions/${pairId}/${membershipId}/${sessionId}`),

	// Session Questionnaires
	getQuestionnaires: (pairId: number, membershipId: number, sessionType: SessionType) =>
		httpClient.get<SessionQuestionnaire[]>(`questionnaires/get/${pairId}/${membershipId}/${sessionType}`),
	createQuestionnaire: (pairId: number, membershipId: number, payload: CreateSessionQuestionnairePayload) =>
		httpClient.post(`questionnaires/create/${pairId}/${membershipId}`, payload),
	updateQuestionnaire: (
		pairId: number,
		membershipId: number,
		sessionType: SessionType,
		payload: UpdateSessionQuestionnairePayload
	) => httpClient.patch(`questionnaires/update/${pairId}/${membershipId}/${sessionType}`, payload),

	// Knowledge database
	getFiles: (applicationId: number, membershipId: number) =>
		httpClient.get<Folder[]>(`files/${membershipId}/${applicationId}`),
	checkFile: (applicationId: number, membershipId: number, filePath: string) =>
		httpClient.put<Folder[]>(`files/${membershipId}/${applicationId}`, { filePath }),
	getFile: ({
		applicationId,
		membershipId,
		fileId,
	}: {
		applicationId: number;
		membershipId: number;
		fileId: number;
	}) =>
		httpClient.get<{ file: File; signedUrl: string | null; content: string | null }>(
			`files/${membershipId}/${applicationId}/${fileId}`
		),

	getFileDataByApplicationAndMembership: ({
		applicationId,
		membershipId,
		fileId,
	}: {
		applicationId: number;
		membershipId: number;
		fileId: number;
	}) =>
		httpClient.get<{ file: File; signedUrl: string | null; content: string | null }>(
			`files/${membershipId}/${applicationId}/${fileId}`,
			undefined
		),

	// getFileData: ({
	// 	fileId,
	// }: {
	// 	applicationId: number;
	// 	membershipId: number;
	// 	fileId: number;
	// 	responseAsBlob?: boolean;
	// }) =>
	// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- files come from backend as octet-stream data
	// 	httpClient.get<any>(`files/${fileId}`, undefined),

	addFileRating: ({
		applicationId,
		membershipId,
		fileId,
		rating,
		comment,
	}: {
		applicationId: number;
		membershipId: number;
		fileId: number;
		rating?: number;
		comment?: string;
	}) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- files come from backend as octet-stream data
		httpClient.put<FileRating>(`files/${membershipId}/${applicationId}/${fileId}/rating`, { rating, comment }),

	getFileRating: ({
		applicationId,
		membershipId,
		fileId,
	}: {
		applicationId: number;
		membershipId: number;
		fileId: number;
	}) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- files come from backend as octet-stream data
		httpClient.get<FileRating>(`files/${membershipId}/${applicationId}/${fileId}/rating`, undefined),
	// Contract
	getContract: (pairId: number, membershipId: number) =>
		httpClient.get<ContractType>(`contracts/${pairId}/${membershipId}`),
	postContract: (pairId: number, membershipId: number, payload: ContractPayload) =>
		httpClient.post(`contracts/${pairId}/${membershipId}`, [payload]),
	putContract: (pairId: number, membershipId: number, payload: EditContractPayload) =>
		httpClient.put(`contracts/${pairId}/${membershipId}`, payload),
	signContract: (pairId: number, membershipId: number) => httpClient.post(`contracts/sign/${pairId}/${membershipId}`),

	getContractSettings: (programId: number) =>
		httpClient.get<Array<ContractSettingType>>(`contracts/admin/settings/${programId}`),
	getExistingContractSettings: (programId: number) =>
		httpClient.get<Array<ContractSettingType>>(`contracts/admin/settings/existing/${programId}`),
	updateContractSettings: (programId: number, settings: Array<ContractSettingPayload>) =>
		httpClient.put(`contracts/admin/settings/${programId}`, settings),
	checkIfContractGenerated: (programId: number) =>
		httpClient.get<Array<number>>(`contracts/admin/settings/stale-check/${programId}`),
	// Goals
	getGoals: (pairId: number, membershipId: number) => httpClient.get<Goals>(`goals/${pairId}/${membershipId}`),
	postGoal: (pairId: number, membershipId: number, payload: CreateGoalPayload) =>
		httpClient.post(`goals/create/${pairId}/${membershipId}`, payload),
	updateGoal: (pairId: number, membershipId: number, payload: UpdateGoalPayload) =>
		httpClient.post(`goals/update/${pairId}/${membershipId}`, payload),
	deleteGoal: (pairId: number, membershipId: number, id: number) =>
		httpClient.delete(`goals/${pairId}/${membershipId}/${id}`),
	signGoal: (pairId: number, membershipId: number) => httpClient.post(`goals/sign/${pairId}/${membershipId}`),

	// Issue reporting
	postIssueReport: (payload: SendIssueReportPayload) => httpClient.post(`reports`, payload),
	uploadReportImage: (data: FormData) =>
		httpClient.post<string>('reports/upload/screenshot', data, {
			headers: { 'Content-Type': 'multipart/form-data; boundary' },
		}),

	// getPosts: (membershipId: number, applicationId: number, page: number, pageSize: number) =>
	// 	axiosInstance.get<Post[]>(`posts/${membershipId}/${applicationId}?page=${page}&pageSize=${pageSize}`),
	// getPostComments: (postId: number, params?: any) =>
	// 	axiosInstance.get<{ data: PostComment[]; meta: { page: number; nextPage: number | null; left: number } }>(
	// 		`posts/comment/${postId}`,
	// 		params
	// 	),
	// addPostComment: (postId: number, payload: any) =>
	// 	axiosInstance.post<PostComment>(`posts/comment/${postId}`, payload),
	updatePostComment: (postId: number, commentId: number, payload: any) =>
		httpClient.put(`posts/comment/${postId}/${commentId}`, payload),
	removePostComment: (postId: number, commentId: number) => httpClient.delete(`posts/comment/${postId}/${commentId}`),

	saveFormQuestionsAsAdmin: (programId: number, payload: any) =>
		httpClient.put<AdminQuestion[]>(`questions/admin/question/${programId}`, payload),
	getQuestionsAsAdmin: (programId: number) =>
		httpClient.get<AdminQuestion[]>(`questions/admin/question/${programId}`),
	checkIfQuestionsAnswered: (programId: number) =>
		httpClient.get<number[]>(`questions/admin/question/stale-check/${programId}`),
};
