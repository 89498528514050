/* eslint-disable no-nested-ternary */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { chatApi } from 'api/chat';
import { Button } from 'components/_new/Button';
import { ChatMessage, ChatThreadUnknown, ChatThreadWithMessage } from 'types/Chat';
import { Spinner } from 'components/_new/Spinner';
import { classNames } from 'primereact/utils';
import { dateFormat } from 'utils/dateFormat';
import { Avatar } from 'components/_new/Avatar';
import { userDisplayName } from 'utils/userDisplayName';
import { User } from 'types/User';
import { useAuth } from 'contexts/authContext';
import { Icon } from 'components/_new/Icon';
import { useIsWindowFocused } from 'hooks/useWindowFocus';
import { useScope } from 'contexts/scopeContext';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useChat } from './useChat';
import { useChatConversationSubscriber } from './useChatConversationSubscriber';
import { useChatThreadSubscriber } from './useChatSubscrber';
import { InputTextArea } from '../_new/InputTextArea';

type ChatMessageLocal = ChatMessage & {
	sending?: boolean;
	localId?: string;
	error?: boolean;
};

type ChatMessageCloudProps = {
	your: boolean;
	author: User;
	message: string;
	createdAt: Date;
	readedAt: Date | null;
	sending?: boolean;
	onRetry?: () => void;
};

const ChatMessageCloud = ({
	your,
	author,
	message,
	createdAt,
	readedAt,
	sending = false,
	onRetry = undefined,
}: ChatMessageCloudProps) => {
	const { t } = useTranslation();

	const prepareDate = (date: Date) => {
		const dayDiff = dayjs().diff(date, 'day');
		if (dayDiff === 0) {
			return dayjs(createdAt).format('HH:mm');
		}
		return dateFormat(date, 'full');
	};
	const renderState = () => {
		if (your) {
			if (sending) {
				return (
					<span title={t('chat.sending')} className="w-1rem h-1rem">
						<Spinner size="xs" />
					</span>
				);
			}
			if (onRetry) {
				return (
					<>
						<span className="w-1rem h-1rem text-error" title={t('chat.sendError')}>
							<Icon name="times-circle" />
						</span>
						<Button
							label={t('chat.retry')}
							variant="danger-text"
							variantSize="xs"
							noPad
							onClick={onRetry}
						/>
					</>
				);
			}
			return (
				<span
					title={readedAt ? `${t('chat.readedAt')} ${readedAt.toLocaleString()}` : t('chat.notReaded')}
					className="w-1rem h-1rem"
				>
					<Icon name="circle-check" iconStyle={readedAt ? 'solid' : 'regular'} />
				</span>
			);
		}
		// if (readedAt) {
		// 	return (
		// 		<span
		// 			title={readedAt ? `${t('chat.readedAt')} ${readedAt.toLocaleString()}` : t('chat.notReaded')}
		// 			className="w-1rem h-1rem text-purple"
		// 		>
		// 			<Icon name="check" iconStyle="solid" />
		// 		</span>
		// 	);
		// }
		return null;
	};
	return (
		<div className="chat-message gap-2">
			<div className="py-1">
				<Avatar src={author.avatar} name={userDisplayName(author, null)} />
			</div>
			<div
				className={classNames('chat-message-cloud', {
					'chat-message-interlocuter': !your,
					'chat-message-your': your,
					'chat-message-new': !your && !readedAt,
				})}
			>
				<div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{message}</div>
				<div className="flex flex-row gap-1 chat-message-date" title={dateFormat(createdAt, 'full')}>
					<span>{prepareDate(createdAt)}</span>
					{renderState()}
				</div>
			</div>
		</div>
	);
};

type ChatConversationWindowProps = {
	thread: ChatThreadUnknown;
};

export const ChatConversationWindow = ({ thread }: ChatConversationWindowProps) => {
	const { t } = useTranslation();

	const [chatDrafts, setChatDrafts] = useLocalStorage<Record<string, string>>('chat-drafts', {});

	const { currentUser } = useAuth();
	const { currentProgram, currentProgramMembership } = useScope();
	const { queryUniqueParam, readThread } = useChat();

	const threadExists = Boolean(thread.id);
	const [localMessages, setLocalMessages] = useState<ChatMessageLocal[]>([]);

	// window
	const messageWindowRef = useRef<HTMLDivElement>(null);
	const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

	const scrollToBottom = useCallback(() => {
		if (messageWindowRef.current && autoScrollEnabled) {
			messageWindowRef.current.scrollTo(0, messageWindowRef.current.scrollHeight);
		}
	}, [autoScrollEnabled]);

	useEffect(() => {
		const handleScroll = (event: any) => {
			const { scrollHeight, scrollTop, clientHeight } = event.target;
			const positionFromBottom = scrollHeight - scrollTop - clientHeight;
			setAutoScrollEnabled(positionFromBottom < 50);
			if (positionFromBottom < 50) {
				setShowNewMessageButton(false);
			}
		};
		messageWindowRef.current?.addEventListener('scroll', handleScroll);
		return () => {
			messageWindowRef.current?.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const {
		// data: serverMessageStorage,
		isFetching: messagesFetching,
		fetchNextPage: messagesFetch,
		hasNextPage: messagesHasNext,
		isLoading: messagesLoading,
		isFetched: messagesFetched,
	} = useInfiniteQuery<ChatMessage[]>(
		[
			'chat-messages',
			{ interlocutorProgramMembershipId: thread.interlocutorProgramMembershipId, ...queryUniqueParam },
		],
		({ pageParam }) => {
			// const lastMessageId = messages[0].id || undefined;
			return chatApi.getThreadMessages(Number(currentProgramMembership?.id), Number(thread.id), pageParam);
		},
		{
			enabled: localMessages.length === 0 && Boolean(currentProgramMembership?.id) && threadExists,
			getNextPageParam: (results) => (results.length < 10 ? undefined : results[results.length - 1].id || 0),
			keepPreviousData: true,
			onSuccess: (data) => {
				const serverMessages = (data?.pages || [])
					.flat()
					.sort(({ id: idA }, { id: idB }) => (idA > idB ? 1 : -1));
				setLocalMessages(serverMessages);
				setTimeout(() => {
					scrollToBottom();
				}, 250);
			},
		}
	);

	// const serverMessages = (serverMessageStorage?.pages || [])
	// 	.flat()
	// 	.sort(({ id: idA }, { id: idB }) => (idA > idB ? 1 : -1));
	// const messages: ChatMessageLocal[] = [...serverMessages, ...localMessages];

	// scroll to down when new messages incoming
	useEffect(() => {
		scrollToBottom();
	}, [scrollToBottom, localMessages]);

	const localReadThread = useCallback(() => {
		readThread(thread.interlocutorProgramMembershipId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thread.interlocutorProgramMembershipId, messagesFetched]);

	const [messageLocalID, setMessageLocalID] = useState(0);
	const { mutate, isLoading } = useMutation(
		(data: { message: string; localId: string }) =>
			chatApi.sendChatMessage(Number(currentProgramMembership?.id), thread.interlocutorProgramMembershipId, data),
		{
			onMutate: ({ message, localId }) => {
				setMessageLocalID((prev) => prev + 1);
				const sendingMessage: ChatMessageLocal = {
					id: 0,
					authorProgramMembershipId: Number(currentProgramMembership?.id),
					createdAt: new Date(),
					message,
					readedAt: null,
					sending: true,
					error: false,
					localId,
				} as ChatMessageLocal;
				setLocalMessages((prev) => [...prev.filter((msg) => msg.localId !== localId), sendingMessage]);
				reset({ message: '' });
				scrollToBottom();
				setChatDrafts((prev) =>
					Object.fromEntries(Object.entries(prev).filter(([k]) => k !== String(thread.id)))
				);
			},
			onSuccess: (data: ChatMessageLocal) => {
				setLocalMessages((prev) =>
					prev.map((message) =>
						message.localId === data.localId ? { ...message, ...data, sending: false } : message
					)
				);
			},
			onError: (response, { localId }) => {
				setLocalMessages((prev) =>
					prev.map((message) =>
						message.localId === localId ? { ...message, sending: false, error: true } : message
					)
				);
			},
		}
	);
	const { register, handleSubmit, reset, watch, setValue } = useForm({ defaultValues: { message: '' } });
	const prepareAndMutate = (message: string, localId?: string) => {
		if (message && message.trim().length > 0) {
			mutate({
				message,
				localId: localId || `${thread.id}-${messageLocalID}`,
			});
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.ctrlKey) {
			e.preventDefault();
			const message = watch('message');
			if (message && message.trim().length > 0) {
				prepareAndMutate(message);
			}
		}
	};
	const handleSubmitForm = handleSubmit(({ message }) => {
		if (message && message.trim().length > 0) {
			prepareAndMutate(message);
		}
	});

	useEffect(() => {
		if (threadExists) {
			localReadThread();
		}
		// setLocalMessages([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thread, threadExists, messagesFetched]);

	const windowFocus = useIsWindowFocused();
	const [readAfterWindowFocus, setReadAfterWindowFocus] = useState(false);
	useEffect(() => {
		if (readAfterWindowFocus && autoScrollEnabled && windowFocus) {
			localReadThread();
			setReadAfterWindowFocus(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readAfterWindowFocus, thread, windowFocus, autoScrollEnabled]);

	const handleNewChatMessage = useCallback(
		(data: ChatMessageLocal) => {
			// only for current thread
			if (thread.id === data.threadId) {
				// message from interlocutor
				if (data.authorProgramMembershipId !== Number(currentProgramMembership?.id)) {
					if (!autoScrollEnabled) {
						setShowNewMessageButton(true);
					}
					if (windowFocus && autoScrollEnabled) {
						localReadThread();
					} else {
						setReadAfterWindowFocus(true);
					}
					setLocalMessages((prev) => [...prev, data]);
				}

				// my own message
				// update sended state
				else {
					setLocalMessages((prev) =>
						prev.map((msg) => {
							if (msg.localId === data?.localId) {
								return { ...data, sending: false };
							}
							return msg;
						})
					);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			thread.id,
			thread.interlocutorProgramMembershipId,
			currentProgramMembership?.id,
			autoScrollEnabled,
			windowFocus,
		]
	);

	const handeChatReadedInternal = useCallback(
		(data: ChatThreadWithMessage, toSelf = false) => {
			// only for current thread
			if (thread.id === data.id) {
				setLocalMessages((prev) =>
					prev.map((message) => {
						if (
							((toSelf && message.authorProgramMembershipId !== Number(currentProgramMembership?.id)) ||
								(!toSelf &&
									message.authorProgramMembershipId === Number(currentProgramMembership?.id))) &&
							message.readedAt === null
						) {
							return {
								...message,
								readedAt: data.interlocutorReadedAt ? new Date(data.interlocutorReadedAt) : new Date(),
							};
						}
						return message;
					})
				);
			}
		},
		[currentProgramMembership?.id, thread.id]
	);
	const handeChatReadedInterlocutorEvent = useCallback(
		({ detail }: CustomEvent<ChatThreadWithMessage>) => handeChatReadedInternal(detail, false),
		[handeChatReadedInternal]
	);
	const handeChatReadedSelfEvent = useCallback(
		({ detail }: CustomEvent<ChatThreadWithMessage>) => handeChatReadedInternal(detail, true),
		[handeChatReadedInternal]
	);

	useChatConversationSubscriber({
		interlocutorMembershipId: Number(thread.interlocutorProgramMembershipId),
		onMessage: handleNewChatMessage,
	});

	useChatThreadSubscriber({
		onChatThread: messagesFetched ? handeChatReadedInterlocutorEvent : undefined,
		onChatThreadSelf: messagesFetched ? handeChatReadedSelfEvent : undefined,
	});

	// useEffect(() => {
	// 	const handleMessage = (rawEvent: MessageEvent<any>) => {
	// 		const { event, data } = parseMessage(rawEvent);
	// 		if (event === 'chat-message') {
	// 			handleNewChatMessage(data);
	// 		}
	// 		if (event === 'chat-thread') {
	// 			handeChatReaded(data);
	// 		}
	// 		if (event === 'chat-thread-self') {
	// 			handeChatReaded(data, true);
	// 		}
	// 	};
	// 	connection?.socket.addEventListener('message', handleMessage);
	// 	return () => {
	// 		connection?.socket.removeEventListener('message', handleMessage);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [handleNewChatMessage, handeChatReaded, thread, connection?.socket]);

	const showNoChats = !threadExists && !localMessages.length;
	const showLoadingMessages = threadExists && messagesLoading;

	const [showNewMessageButton, setShowNewMessageButton] = useState(false);
	const handleGoToNewMessageClick = useCallback(() => {
		scrollToBottom();
		setAutoScrollEnabled(true);
		setShowNewMessageButton(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLocalMessages([]);
		if (thread.id && chatDrafts[thread.id] && chatDrafts[thread.id].length > 0) {
			setValue('message', chatDrafts[thread.id]);
		} else {
			setValue('message', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thread.id]);

	return (
		<div className="flex-1 h-full flex flex-column gap-2 overflow-y-hidden p-1">
			<div className="h-full  relative overflow-hidden">
				<div
					ref={messageWindowRef}
					className="flex-1 flex flex-column gap-1 h-full overflow-y-scroll p-styled-scrollbar-purplishblue bg-new-gray rounded-md p-2"
				>
					{(showNoChats || showLoadingMessages) && (
						<div className="h-full flex flex-column align-items-center justify-content-center">
							{showNoChats && (
								<>
									<p className="text-gray">{t('chat.noConversations')}</p>
									<p className="text-gray">{t('chat.writeNewChat')}</p>
								</>
							)}
							{showLoadingMessages && (
								<>
									<Spinner />
									<p className="text-gray">{t('chat.loadingChats')}</p>
								</>
							)}
						</div>
					)}

					{!messagesLoading && messagesHasNext && (
						<Button
							label={t('chat.loadMoreChats')}
							onClick={() => messagesFetch()}
							loading={messagesFetching}
						/>
					)}

					{(localMessages || []).map((msg) => {
						const {
							id,
							authorProgramMembershipId,
							message,
							createdAt,
							readedAt,
							localId,
							sending = false,
							error = false,
						} = msg;
						const your = authorProgramMembershipId === Number(currentProgramMembership?.id);
						const handleRetry = error ? () => prepareAndMutate(message, localId) : undefined;
						return (
							<Fragment key={localId || id}>
								<ChatMessageCloud
									your={your}
									author={
										your
											? (currentUser as unknown as User)
											: thread.interlocutorProgramMembership.user
									}
									message={message}
									createdAt={createdAt}
									readedAt={readedAt}
									sending={sending}
									onRetry={handleRetry}
								/>
							</Fragment>
						);
					})}
				</div>
				{showNewMessageButton && (
					<div className="absolute bottom-0 w-full p-4 flex flex-row justify-content-center">
						<Button label={t('chat.goToNewMessage')} onClick={handleGoToNewMessageClick} />
					</div>
				)}
			</div>
			<form onSubmit={handleSubmitForm} className="flex-0 flex flex-column gap-2 pt-1">
				<InputTextArea
					{...register('message', {
						required: true,
						onBlur: ({ currentTarget: { value } }) => {
							if (value && value.length > 0) {
								setChatDrafts((prev) => ({ ...prev, [String(thread.id)]: value }));
							} else {
								setChatDrafts((prev) =>
									Object.fromEntries(Object.entries(prev).filter(([k]) => k !== String(thread.id)))
								);
							}
						},
					})}
					autoComplete="off"
					className="w-full resize-none"
					rows={2}
					maxLength={255}
					placeholder={t('chat.writeMessage')}
					onKeyDown={handleKeyPress}
				/>
				<div className="flex flex-col justify-content-end pt-1">
					<Button submit label={t('actions.send')} iconStyle="solid" loading={isLoading} variant="primary" />
				</div>
			</form>
		</div>
	);
};
