import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { usePanel } from 'contexts/panelContext';
import { userFilesApi } from 'api';
import { MaterialCategory, SessionFile } from 'types/SessionFile';
import { dateFormat } from 'utils/dateFormat';
import { RouterUrlParams } from 'App';
import { BreadCrumb } from 'primereact/breadcrumb';
import { usePair } from 'contexts/pairContext';
import { getMaterialIconPropsForLink } from 'utils/materialIconProps';
import { Link } from 'components/_new/Link';
import { DownloadMaterialsButton } from 'components/userPanel/processSummary/DownloadMaterialsButton';

export const Browse = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams() as RouterUrlParams;

	const { currentProgramMembership, currentApplicationData } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const { data: getUserfilesData } = useQuery(
		[
			'userfiles-list',
			{ programMembership: currentProgramMembership?.id, application: currentApplicationData?.id, pairId },
		],
		() =>
			userFilesApi.getUserfiles(
				Number(currentProgramMembership?.id),
				Number(currentApplicationData?.id),
				pairId,
				{}
			),
		{
			enabled: Boolean(currentProgramMembership?.id) && Boolean(currentApplicationData?.id),
		}
	);
	const files: SessionFile[] = getUserfilesData || [];

	const getIcon = (fileType: MaterialCategory) => {
		switch (fileType) {
			case 'tool':
				return <i className="fa-solid fa-screwdriver-wrench" />;
			case 'forms':
				return <i className="fa-solid fa-file-pen" />;
			case 'knowledge':
				return <i className="fa-solid fa-book" />;
			case 'note':
				return <i className="fa-solid fa-clipboard" />;
			case 'other':
			default:
				return <i className="fa-solid fa-file" />;
		}
	};

	const navigate = useNavigate();
	const homeBreadcrumb = {
		icon: 'pi pi-book',
		command: () => {
			navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/userfiles`);
		},
	};

	return (
		<>
			<Helmet title={t('userPanel.userFiles.title')} />
			<SubPageTitle title={t('userPanel.userFiles.title')} right={<DownloadMaterialsButton />} />
			<BreadCrumb model={[{ label: t('userPanel.userFiles.title') }]} home={homeBreadcrumb} className="mb-3" />

			<p>{t('userPanel.userFiles.desc')}</p>

			{files.length === 0 && <p>{t('userPanel.files.fileNotFound')}</p>}
			{files.length > 0 && (
				<div className="p-datatable">
					<table className="p-datatable-table w-full">
						<thead className="p-datatable-thead">
							<tr>
								<th>{t('userPanel.userFiles.file.name')}</th>
								<th className="hidden md:table-cell">{t('userPanel.userFiles.file.type')}</th>
								<th className="hidden md:table-cell">{t('userPanel.userFiles.file.author')}</th>
								<th>{t('userPanel.userFiles.file.session')}</th>
								<th className="hidden md:table-cell">{t('userPanel.userFiles.file.updatedAt')}</th>
							</tr>
						</thead>
						<tbody className="p-datatable-tbody">
							{files.map((file) => (
								<tr key={file.id}>
									<td className="w-full">
										<Link
											to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/userfiles/display/${file.id}`}
											className="p-link underline"
											{...getMaterialIconPropsForLink(file.category)}
											// iconClassName="text-lg "
											target={file.type === 'link' ? '_target' : undefined}
										>
											{file.name}
										</Link>
									</td>
									<td className="hidden md:table-cell">{file.type}</td>
									<td className="hidden md:table-cell">
										{((file) => {
											const user = file?.authorApplication?.programMembership?.user || null;
											const author = `${user?.firstName} ${user?.lastName}`;
											return author;
										})(file)}
									</td>
									<td className="nowrap">
										{((file) => {
											if (file?.sessionId) {
												return (
													<Link
														to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${file.sessionId}`}
														className="p-link underline"
													>
														{`${t('userPanel.userFiles.file.session')} ${
															file.session?.sessionNumber || '-'
														}`}
													</Link>
												);
											}
											return <span>-</span>;
										})(file)}
									</td>
									<td className="hidden md:table-cell">
										{file.updatedAt ? dateFormat(file.updatedAt, 'full') : '-'}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};
