import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { programsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import BusinessCard from 'components/_common/panel/BusinessCard';
// import PairItem from 'components/pairing/Pair';
import ValidateTextArea from 'components/_common/forms/TextArea';
import { Pair } from 'types/Pair';
import { Dropdown } from 'primereact/dropdown';
import { DialogAction, DialogConfirm } from 'components/_new/Dialog';
import { Spinner } from 'components/_new/Spinner';
import { Application } from 'types/Application';
import { dateFormat } from 'utils/dateFormat';
import { dayjs } from 'utils/dayjs';
import { useGlobal } from 'contexts/globalContext';
import { Button } from 'components/_new/Button';
import { Tooltip } from 'primereact/tooltip';
import PageMissing from 'components/PageMissing';
import { classNames } from 'primereact/utils';
import { prepareUserAreas } from 'utils/prepareUserAreas';
import { Message } from 'primereact/message';
import { scrollToElementById } from 'utils/scroll';
import { Box, BoxSection } from 'components/_new/Box';
import PairItem from 'components/userPanel/pairing/Pair';
import { getPairStatusInfo } from './utils';
import { EditApplicationDialog } from './EditApplicationDialog';
import { DeleteApplicationConfirmDialog } from './DeleteApplicationConfirmDialog';
import PairActivityTimestamps from '../_common/PairActivityTimestamps';

const ProgramPairingDetails = () => {
	const { t } = useTranslation();

	const { toastRef } = useGlobal();
	const { data: program, isSuccess } = useProgramPanel();
	const params = useParams<{ applicationId: string }>();
	const applicationId = Number(params.applicationId);
	const {
		data: application,
		isLoading: applicationLoading,
		isError: applicationError,
		refetch: refetchApplication,
	} = useQuery(
		['getProgramApplication', applicationId],
		() => programsApi.getProgramAdminApplication({ programId: Number(program?.id), applicationId }),
		{
			enabled: Boolean(isSuccess && Number(program?.id) && applicationId),
		}
	);
	const systemAnswers = application?.systemAnswers;

	const [manualModel, setManualMode] = useState(false);
	const [pairs, setPairs] = useState<Pair[]>([]);
	const currentPairsApplicationIdentifiers = (pairs || []).reduce<number[]>(
		(curr, pair) => [...curr, pair.menteeApplicationId, pair.mentorApplicationId],
		[]
	);
	const {
		isLoading: pairsLoading,
		refetch: refetchPairs,
		isRefetching,
	} = useQuery(
		['getProgramApplicationPairs', applicationId],
		() => programsApi.getProgramAdminApplicationPairing({ programId: Number(program?.id), applicationId }),
		{
			enabled: Boolean(isSuccess && Number(program?.id) && applicationId) && !manualModel,
			onSuccess: (data) => {
				if (data && data.length > 0) {
					setPairs(data);
				}
			},
		}
	);

	const {
		mutateAsync,
		isLoading: sending,
		isSuccess: sendEditSuccess,
	} = useMutation(programsApi.editProgramApplication, {
		onSuccess: () => {
			refetchApplication();
			setRejectConfirmDialogOpen(false);
		},
	});

	const userAreas = prepareUserAreas(systemAnswers?.areas || []);

	const getApprovedText = (status: string) => {
		switch (status) {
			case 'accepted':
				return t('programPanel.application.statusOptions.accepted');
			case 'rejected':
				return t('programPanel.application.statusOptions.rejected');
			case 'not accepted':
			default:
				return t('programPanel.application.statusOptions.notAccepted');
		}
	};

	const handleAccept = useCallback(() => {
		if (program && application) {
			mutateAsync({
				programId: program?.id,
				applicationId: application?.id,
				status: 'accepted',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [program, application, mutateAsync]);

	const handleReject = useCallback(
		// eslint-disable-next-line consistent-return
		(reason: string) => {
			if (program && application) {
				return mutateAsync({
					programId: program?.id,
					applicationId: application?.id,
					status: 'rejected',
					reason,
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[program, application, mutateAsync]
	);

	const { control, trigger, getValues } = useForm<{ reason: string }>();

	const [rejectConfirmDialogOpen, setRejectConfirmDialogOpen] = useState(false);
	const [editApplicationDialogOpen, setEditApplicationDialogOpen] = useState(false);
	const [currentPair, setCurrentPair] = useState<null | number>(null);

	// handle accept pair
	const [acceptPairDialogOpen, setAcceptPairDialogOpen] = useState(false);
	const { mutateAsync: acceptPair, isLoading: acceptPairLoading } = useMutation(programsApi.acceptProgramPair, {
		onSuccess: () => {
			setManualMode(true);
			setAcceptPairDialogOpen(false);
			setPairs((prev) =>
				prev.map((item) => {
					if (item.id === currentPair) {
						return {
							...item,
							acceptedByMentee: new Date().toISOString(),
							acceptedByMentor: new Date().toISOString(),
							entryMeeting: new Date().toISOString(),
							status: 'accepted',
						};
					}
					return item;
				})
			);
			setCurrentPair(null);
			refetchApplication();
			toastRef.current?.show({
				severity: 'success',
				life: 5000,
				summary: t('misc.success'),
				detail: t('programPanel.application.pairPropositions.acceptedSuccessInfo'),
			});
		},
	});
	// eslint-disable-next-line consistent-return
	const handleAcceptPair = useCallback(() => {
		if (currentPair && program) {
			return acceptPair({ programId: program.id, pairId: currentPair });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPair, program]);

	// handle remove pairs
	const [removePairDialogOpen, setRemovePairDialogOpen] = useState(false);
	const { mutateAsync: deletePair, isLoading: deletePairLoading } = useMutation(programsApi.deleteProgramPair, {
		onSuccess: () => {
			setManualMode(true);
			setRejectConfirmDialogOpen(false);
			setPairs((prev) => prev.filter((item) => item.id !== currentPair));
			setCurrentPair(null);
			refetchApplication();
			toastRef.current?.show({
				severity: 'success',
				life: 5000,
				summary: t('misc.success'),
				detail: t('programPanel.application.statusInfo.removed'),
			});
		},
	});
	// eslint-disable-next-line consistent-return
	const handleRemovePair = useCallback(() => {
		if (currentPair && program) {
			return deletePair({ programId: program.id, pairId: currentPair });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPair, program, deletePair]);

	const { mutate: addPair, isLoading: isAddingPair } = useMutation(programsApi.addProgramPair, {
		onSuccess: (newPair) => {
			refetchPropositions();
			if (newPair) {
				const preparedPair = {
					...newPair,
					programMembership:
						application?.applicationRole === 'mentor' ? newPair.menteeMembership : newPair.mentorMembership,
					application:
						application?.applicationRole === 'mentor'
							? newPair.menteeApplication
							: newPair.mentorApplication,
				};

				setPairs((prev) =>
					[...prev, preparedPair as unknown as Pair].sort((a, b) => {
						if (a.status === 'accepted' && b.status !== 'accepted') {
							return -1;
						}
						if (a.status === 'open' && b.status === 'accepted') {
							return 1;
						}

						if (a.createdAt > b.createdAt) {
							return -1;
						}
						if (a.createdAt < b.createdAt) {
							return 1;
						}

						return 0;
					})
				);

				setTimeout(() => {
					const newPairElementId = `pair-${newPair.id}`;
					scrollToElementById(newPairElementId);
				}, 100);
			}
			setApplicationToAdd(null);
		},
	});

	const [applicationToAdd, setApplicationToAdd] = useState<null | number>(null);
	const handleAddPair = useCallback(() => {
		if (program && application && applicationToAdd) {
			addPair({ programId: program?.id, applicationId: application?.id, pairApplicationId: applicationToAdd });
		}
	}, [program, application, applicationToAdd, addPair]);

	// remove application
	const { mutate: deleteApplication, isLoading: deleteApplicationLoading } = useMutation(
		programsApi.deleteProgramApplications,
		{
			onSuccess: (data, variables) => {
				refetchApplication();
				toastRef.current?.show({
					severity: 'info',
					life: 5000,
					summary: t('misc.info'),
					detail: variables.quickDelete
						? t('programPanel.pairing.removeApplication.infoQuick')
						: t('programPanel.pairing.removeApplication.info'),
				});
			},
		}
	);

	// cancel remove application
	const { mutate: cancelDeleteApplication, isLoading: cancelDeleteApplicationLoading } = useMutation(
		programsApi.cancelDeleteProgramApplication,
		{
			onSuccess: () => {
				refetchApplication();
			},
		}
	);
	const handleCancelDeleting = useCallback(() => {
		cancelDeleteApplication({ programId: Number(program?.id), applicationId: Number(application?.id) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [program, application]);

	const finalDeleteApplicationLoading = cancelDeleteApplicationLoading || deleteApplicationLoading;

	const { data: propositionsData, refetch: refetchPropositions } = useQuery(
		['getAvaiablePairsForApplication', program?.id],
		() =>
			programsApi.getAvailablePairsForApplication({
				programId: Number(program?.id),
				applicationId: Number(application?.id),
			}),
		{
			enabled: Boolean(isSuccess && Number(program?.id) && application?.id),
		}
	);
	const rejectApplicationDialogActions = [
		{
			key: 'reject',
			submit: true,
			label: t('actions.reject'),
			onClick: () => {
				trigger('reason').then((valid) => {
					if (valid) {
						handleReject(getValues('reason'));
					}
				});
			},
			loading: sending,
		},
	];

	const [removeApplicationDialogOpen, setRemoveApplicationDialogOpen] = useState(false);
	const { control: deleteApplicationControl, handleSubmit: deleteApplicationHandleSubmit } = useForm({
		defaultValues: { withUser: false, sendMail: true, quickDelete: false },
	});
	const handleFormSubmit = deleteApplicationHandleSubmit(({ withUser, sendMail, quickDelete }) => {
		return deleteApplication({
			programId: Number(program?.id),
			applicationIds: [Number(application?.id)],
			withUser,
			sendMail,
			quickDelete,
		});
	});

	const propositions = propositionsData || [];

	const currentApplicationPairLimitReached = Boolean(application?.limitStats?.pairLimitReached);

	const sortedPairs = React.useMemo(() => {
		return [...(pairs || [])].sort((a, b) => {
			const aIsAccepted = a.acceptedByMentee !== null || a.acceptedByMentor !== null;
			const bIsAccepted = b.acceptedByMentee !== null || b.acceptedByMentor !== null;

			if (aIsAccepted && !bIsAccepted) return -1;
			if (!aIsAccepted && bIsAccepted) return 1;

			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		});
	}, [pairs]);

	return (
		<>
			<Helmet title={t('organizationPanel.programPairingTests')} />

			{application && (
				<SubPageTitle
					title={
						application?.applicationRole === 'mentor'
							? t('programPanel.application.titleForMentor')
							: t('programPanel.application.titleForMentee')
					}
					additional={program?.displayName}
				/>
			)}

			<DialogConfirm
				message={t('programPanel.application.confirmRemovePairProposition')}
				visible={removePairDialogOpen}
				onHide={() => {
					setRemovePairDialogOpen(false);
				}}
				onConfirm={() => handleRemovePair()}
				confirmAction={{ loading: deletePairLoading }}
			/>

			<DialogConfirm
				message={t('programPanel.application.confirmCreatePair')}
				visible={acceptPairDialogOpen}
				onHide={() => {
					setAcceptPairDialogOpen(false);
				}}
				onConfirm={() => handleAcceptPair()}
				confirmAction={{ loading: acceptPairLoading }}
			/>

			<DeleteApplicationConfirmDialog
				control={deleteApplicationControl}
				onConfirm={handleFormSubmit}
				visible={removeApplicationDialogOpen}
				onHide={() => {
					setRemoveApplicationDialogOpen(false);
				}}
				confirmAction={{ loading: deleteApplicationLoading }}
			/>

			{application && (
				<EditApplicationDialog
					visible={editApplicationDialogOpen}
					onHide={() => {
						setEditApplicationDialogOpen(false);
					}}
					applicationData={application}
					programMembershipId={application?.programMembership.id}
					applicationRole={application.applicationRole}
					applicationType={application.applicationType}
					editMode
					programId={application.programId}
					onApplicationAdded={refetchApplication}
					onCancel={() => {
						// TODO
					}}
				/>
			)}

			<DialogAction
				title={t('programPanel.application.enterRejectReason')}
				visible={rejectConfirmDialogOpen}
				onHide={() => setRejectConfirmDialogOpen(false)}
				actions={rejectApplicationDialogActions}
			>
				<ValidateTextArea name="reason" control={control} required />
			</DialogAction>
			{applicationLoading && (
				<div className="flex justify-content-center">
					<Spinner />
				</div>
			)}

			{applicationError && <PageMissing />}

			{!applicationLoading && application && (
				<>
					<div className="flex flex-row justify-content-between align-items-center">
						<h2>{t('programPanel.application.pairTest')}</h2>
						{application?.limitStats && (
							<div>
								{`${t('programPanel.application.pairPropositions.acceptedPairs')}: `}
								<span
									className={classNames({
										'text-success font-bold': application.limitStats.pairLimitReached,
									})}
								>
									{application.limitStats.currentPairs}/{application.pairLimit}
								</span>
							</div>
						)}
					</div>

					<Box variant="white-bordered">
						<BoxSection>
							<BusinessCard
								configPanel
								data={application}
								highlightDeleted
								displayHidden
								renderBottom={() => (
									<div className="flex gap-2 justify-content-between">
										<p>
											{t('programPanel.application.applicationHasStatus', {
												status: application.finished
													? t('programPanel.application.statusOptions.finished')
													: getApprovedText(application.approved),
											})}
											{application.rejectReason ? (
												<>
													<br />
													{t('programPanel.application.rejectReason', {
														reason: application.rejectReason,
													})}
												</>
											) : null}
										</p>
										<div className="flex flex-row flex-wrap justify-content-end gap-2">
											{application.approved === 'not accepted' && (
												<>
													{!sendEditSuccess && (
														<Button
															onClick={handleAccept}
															label={t('actions.accept')}
															loading={sending}
														/>
													)}
													{!sendEditSuccess && (
														<Button
															onClick={() => setRejectConfirmDialogOpen(true)}
															label={t('actions.reject')}
															variant="primary-text"
															disabled={sending}
														/>
													)}
												</>
											)}
											{application.approved === 'accepted' && (
												<>
													<Button
														onClick={() => setEditApplicationDialogOpen(true)}
														label={t('programPanel.application.editApplicationData')}
														variant="primary-text"
														loading={sending}
														disabled={!application}
													/>
													<Button
														onClick={() => setRejectConfirmDialogOpen(true)}
														label={t('programPanel.application.withdrawApplication')}
														variant="primary-text"
														loading={sending}
													/>
												</>
											)}
											{application.deletedAt && (
												<Button
													onClick={handleCancelDeleting}
													label={t('programPanel.pairing.removeApplication.cancelAction', {
														datetime: dateFormat(
															dayjs(application.deletedAt).toDate(),
															'date'
														),
													})}
													variant="primary-text"
													loading={sending}
												/>
											)}
											{!application.deletedAt && (
												<Button
													onClick={() => setRemoveApplicationDialogOpen(true)}
													label={t('programPanel.pairing.removeApplication.removeAction')}
													variant="primary-text"
													loading={finalDeleteApplicationLoading}
												/>
											)}
										</div>
									</div>
								)}
							/>
						</BoxSection>
					</Box>

					<div>
						<div className="flex justify-content-between align-items-center flex-wrap gap-2 mt-2">
							<div className="flex-1">
								<h2>{t('programPanel.application.pairPropositions.title')}</h2>
								{application?.applicationRole === 'mentor' && (
									<p>{t('programPanel.application.pairPropositions.descMentor')}</p>
								)}
								{application?.applicationRole === 'mentee' && (
									<p>{t('programPanel.application.pairPropositions.descMentee')}</p>
								)}
								{!program?.pairingStatus && (
									<Message
										severity="info"
										text={t('programPanel.application.pairPropositions.pairingDisabledInfo')}
										className="w-full justify-content-start pl-4 mb-4"
									/>
								)}
							</div>
							<Button
								onClick={() => {
									setManualMode(false);
									refetchPairs();
								}}
								label={t('actions.refresh')}
								loading={pairsLoading || isRefetching}
							/>
						</div>
						{pairs?.length === 0 && (
							<p className="text-gray">
								{application?.approved === 'not accepted'
									? t(
											'programPanel.application.pairPropositions.noPropositionsApplicationNotAccepted'
									  )
									: t('programPanel.application.pairPropositions.noPropositionsApplicationAccepted')}
							</p>
						)}
						{(pairs || []).length > 0 && (
							<ul className="p-list-unstylled">
								{sortedPairs.map((pair) => {
									const isAccepted = pair.acceptedByMentee !== null && pair.acceptedByMentor !== null;

									return (
										<li key={pair.id} className="mb-4">
											<div className="rounded-lg overflow-hidden">
												<PairItem
													id={`pair-${pair.id}`}
													withGreenBorder={isAccepted}
													data={pair}
													userAreas={userAreas}
													rednerBottom={() => {
														const targetHasLimit =
															application?.applicationRole === 'mentee'
																? pair.mentorApplication?.limitStats?.pairLimitReached
																: pair.menteeApplication?.limitStats?.pairLimitReached;
														const isRejected = pair.status === 'rejected';
														const hasContract = Boolean(pair.contractId);

														return (
															<>
																<hr className="my-3" />
																<div className="flex justify-content-between align-items-center flex-wrap gap-2">
																	<div>
																		<div>
																			{getPairStatusInfo(pair)},{' '}
																			<Link
																				to={`/program/${program?.name}/pair/${pair.id}`}
																				className="text-purplish-blue"
																			>
																				{t(
																					'programPanel.application.pairDetails'
																				)}
																			</Link>
																			.
																		</div>
																		<PairActivityTimestamps
																			pair={{
																				createdAt: pair.createdAt,
																				entryMeeting: pair.entryMeeting,
																				acceptedByMentee: pair.acceptedByMentee,
																				acceptedByMentor: pair.acceptedByMentor,
																				rejectedByMentee: pair.rejectedByMentee,
																				rejectedByMentor: pair.rejectedByMentor,
																			}}
																		/>
																	</div>
																	<div className="flex gap-2">
																		<Tooltip
																			target={`#remove-button-${pair.id}`}
																			position="top"
																		/>
																		<span
																			id={`remove-button-${pair.id}`}
																			data-pr-tooltip={
																				hasContract
																					? t(
																							'programPanel.application.pairPropositions.cantRemovePairProposition'
																					  )
																					: undefined
																			}
																		>
																			<Button
																				onClick={() => {
																					setCurrentPair(pair.id);
																					setRemovePairDialogOpen(true);
																				}}
																				label={t(
																					'programPanel.application.pairPropositions.removePairProposition'
																				)}
																				variant="danger"
																				disabled={hasContract}
																				loading={deletePairLoading}
																			/>
																		</span>

																		<Tooltip
																			target={`#accept-button-${pair.id}`}
																			position="top"
																			disabled={false}
																		/>
																		<span
																			id={`accept-button-${pair.id}`}
																			data-pr-tooltip={
																				// eslint-disable-next-line no-nested-ternary
																				currentApplicationPairLimitReached
																					? t(
																							'programPanel.application.pairPropositions.currentApplicationPairLimitReached'
																					  )
																					: // eslint-disable-next-line no-nested-ternary
																					targetHasLimit
																					? t(
																							'programPanel.application.pairPropositions.limitReached'
																					  )
																					: // eslint-disable-next-line no-nested-ternary
																					isAccepted
																					? t(
																							'programPanel.application.pairPropositions.accepted'
																					  )
																					: pair.entryMeeting
																					? t(
																							'programPanel.application.pairPropositions.entryMeeting'
																					  )
																					: undefined
																			}
																		>
																			<Button
																				onClick={() => {
																					setCurrentPair(pair.id);
																					setAcceptPairDialogOpen(true);
																				}}
																				label={t(
																					'programPanel.application.pairPropositions.acceptPair'
																				)}
																				disabled={
																					currentApplicationPairLimitReached ||
																					isRejected ||
																					targetHasLimit
																				}
																				loading={acceptPairLoading}
																			/>
																		</span>
																	</div>
																</div>
															</>
														);
													}}
												/>
											</div>
										</li>
									);
								})}
							</ul>
						)}

						<div className="mt-6">
							<div className="flex-1">
								<h2>{t('programPanel.application.pairPropositions.addNewPropositionTitle')}</h2>
								<p>{t('programPanel.application.pairPropositions.addNewPropositionDescription')}</p>
							</div>
							<div className="flex gap-2">
								<Dropdown
									placeholder={t(
										'programPanel.application.pairPropositions.addPairPropositionPlaceholder'
									)}
									className="flex-1"
									value={applicationToAdd}
									onChange={({ value }) => {
										setApplicationToAdd(value);
									}}
									dataKey="id"
									optionValue="id"
									options={propositions
										.sort((a, b) =>
											String(a.programMembership.user.lastName || '').localeCompare(
												String(b.programMembership.user.lastName || ''),
												'en',
												{ sensitivity: 'base' }
											)
										)
										.map((proposition: Application) => {
											const displayName = `${proposition.programMembership.user.lastName} ${proposition.programMembership.user.firstName}`;
											const additionalLabel = proposition.limitStats
												? `(${t(
														'programPanel.application.pairPropositions.acceptedPairs'
												  )}: ${`${proposition.limitStats.currentPairs}/${proposition.pairLimit}`})`
												: null;
											return {
												id: proposition.id,
												label: displayName,
												additionalLabel,
												disabled: currentPairsApplicationIdentifiers.includes(proposition.id),
												className: proposition.deletedAt ? 'text-error' : undefined,
											};
										})}
									filter
									itemTemplate={(item) => (
										<div>
											{item.label}{' '}
											{item.additionalLabel && (
												<span className="text-gray">{item.additionalLabel}</span>
											)}
										</div>
									)}
								/>
								<Button
									onClick={() => handleAddPair()}
									label={t('actions.add')}
									disabled={!applicationToAdd}
									loading={isAddingPair}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default ProgramPairingDetails;
