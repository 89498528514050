import React, { useMemo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { finalReportApi, userPanelApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/_new/Button';
import { useScope } from 'contexts/scopeContext';
import { usePanel } from 'contexts/panelContext';
import { classNames } from 'primereact/utils';
import { Page } from 'components/_new/Page';
import { Spinner } from 'components/_new/Spinner';
import { usePair } from 'contexts/pairContext';
import { MessageBox } from 'components/_new/MessageBox';
import { FinalSurveyPanel } from 'components/finalSurveyPage/FinalSurveyPanel';
import { surveyApi } from 'api/survey';
import { DownloadMaterialsButton } from './DownloadMaterialsButton';

export const ProcessSummary = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();

	const { currentProgram } = useScope();
	const { currentApplicationData, currentProgramMembership } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const membershipId = Number(currentProgramMembership.id);

	const {
		mutate: prepareDownloadLinkMutate,
		data: downloadLink,
		isLoading: prepareDownloadLinkLoading,
	} = useMutation(finalReportApi.prepareFinalReportDownloadLink);
	useEffect(() => {
		prepareDownloadLinkMutate({ pairId, membershipId, type: String(type) });
	}, [membershipId, pairId, prepareDownloadLinkMutate, type]);

	const navigate = useNavigate();

	const filesUrls = useMemo(() => {
		switch (currentProgram?.language) {
			case 'en':
				return {
					finalProcess: `/panel/${organizationName}/${programName}/${type}/files/knowledge/the-end-of-the-process-for-the-${type}-cheat-sheet`,
				};
			case 'pl':
			default:
				return {
					finalProcess: `/panel/${organizationName}/${programName}/${type}/files/pigulki-wiedzy/zakonczenie-procesu-dla-${
						type === 'mentor' ? 'mentora' : type
					}-o-czym-pamietac`,
				};
		}
	}, [currentProgram?.language, organizationName, programName, type]);

	const { refetch: finishProcess } = useQuery(
		['finishProcess'],
		() => userPanelApi.finishProcessForPair(pairId, currentProgramMembership.id),
		{
			onSuccess: () => {
				navigate(
					currentApplicationData.applicationRole === 'mentee'
						? `/${organizationName}/${programName}/apply-mentee`
						: `/${organizationName}/${programName}/`
				);
				setTimeout(() => {
					window.location.reload();
				}, 100);
			},
		}
	);
	const {
		data: finalReportData,
		isLoading: finalReportLoading,
		refetch: finalReportRefetch,
	} = useQuery(['final-report', pairId], () => finalReportApi.getFinalReport(pairId, currentApplicationData?.id), {
		enabled: Boolean(pairId),
		refetchOnWindowFocus: false,
		onSuccess: () => {},
	});

	const [finalSurveyDialogOpen, setFinalSurveyDialogOpen] = useState(false);
	const { data, isLoading: finalSurveyLoading } = useQuery(
		['FINAL_SURVEY', { programMembershipId: Number(currentProgramMembership?.id) }],
		() => surveyApi.getSurveyForProgramMembership(Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(currentProgramMembership),
		}
	);
	const hasAnswers = (data?.stats?.questionFilledCount || 0) > 0;

	return (
		<>
			<SubPageTitle title={t('userPanel.navigation.processSummary')} />
			<Page>
				<div>
					<h3 className="text-purplish-blue my-0">{t('userPanel.processSummary.thanks')}</h3>
					<p>{t('userPanel.processSummary.desc')}</p>
				</div>
				<div>
					<h3 className="text-purplish-blue my-0">{t('userPanel.processSummary.whatNext')}</h3>
					<div className="w-full">
						<ul className="flex flex-column gap-4 p-list-unstylled sm:pl-3">
							<li>
								<i
									className="pi pi-angle-right text-3xl text-purplish-blue"
									style={{ verticalAlign: 'middle' }}
								/>
								{type === 'mentor' && (
									<Trans
										t={t}
										i18nKey="userPanel.processSummary.readInKnowledgeForMentor"
										components={[<Link to={filesUrls.finalProcess} className="p-link" />]}
									/>
								)}
								{type === 'mentee' && (
									<Trans
										t={t}
										i18nKey="userPanel.processSummary.readInKnowledgeForMentee"
										components={[<Link to={filesUrls.finalProcess} className="p-link" />]}
									/>
								)}
							</li>
							<li>
								<i
									className="pi pi-angle-right text-3xl text-purplish-blue"
									style={{ verticalAlign: 'middle' }}
								/>
								<p className="inline-block">
									{type === 'mentor'
										? t('userPanel.processSummary.downloadReportForMentor')
										: t('userPanel.processSummary.downloadReportForMentee')}
								</p>
								{finalReportLoading ? <Spinner size="sm" /> : null}
								{type === 'mentor' && !finalReportData?.visibleForMentee && !finalReportLoading && (
									<MessageBox
										variant="rose"
										message={
											<Trans
												t={t}
												i18nKey="userPanel.processSummary.reportNotFilledForMentor"
												components={[
													<Link
														to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/final-report`}
														className="underline"
													/>,
												]}
											/>
										}
										highlight
									/>
								)}
								{type === 'mentee' && !finalReportData?.visibleForMentee && !finalReportLoading && (
									<MessageBox
										variant="rose"
										message={t('userPanel.processSummary.reportNotFilledForMentee')}
										highlight
									/>
								)}
								{finalReportData?.visibleForMentee && !finalReportLoading && (
									<div className="flex flex-col mt-4 justify-content-center align-items-center w-full">
										<Button
											label={t('userPanel.processSummary.downloadReport')}
											variant="primary"
											loading={prepareDownloadLinkLoading}
											onClick={() => {
												window.open(downloadLink, '_blank');
											}}
										/>
									</div>
								)}
							</li>
							<li>
								<div className={classNames({ 'mb-4': !hasAnswers })}>
									<i
										className="pi pi-angle-right text-3xl text-purplish-blue"
										style={{ verticalAlign: 'middle' }}
									/>
									{t('userPanel.processSummary.pleaseRateAndFillSurvey')}
								</div>

								{finalSurveyLoading ? (
									<Spinner center size="sm" />
								) : (
									<>
										{hasAnswers && (
											<>
												<MessageBox
													variant="success"
													message={t('userPanel.processSummary.survey.thanks')}
													highlight
												/>
												<Button
													label={t(
														'userPanel.processSummary.survey.openFinalSurveyAndCheckAnswers'
													)}
													onClick={() => {
														setFinalSurveyDialogOpen(true);
													}}
													className="px-auto py-2"
												/>
											</>
										)}

										{!hasAnswers && (
											<>
												<MessageBox
													variant="rose"
													message={t('userPanel.processSummary.survey.fillFinalSurvey')}
													highlight
												/>
												<Button
													label={t('userPanel.processSummary.survey.openFinalSurvey')}
													onClick={() => {
														setFinalSurveyDialogOpen(true);
													}}
													className="px-auto py-2"
												/>
											</>
										)}
									</>
								)}

								<FinalSurveyPanel
									visible={finalSurveyDialogOpen}
									onHide={() => setFinalSurveyDialogOpen(false)}
								/>
							</li>
							<li>
								<i
									className="pi pi-angle-right text-3xl text-purplish-blue"
									style={{ verticalAlign: 'middle' }}
								/>
								<Trans
									t={t}
									i18nKey="userPanel.processSummary.followUsOnLinkedIn"
									components={[
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a
											href="https://www.linkedin.com/company/mentiway/"
											rel="noreferrer"
											target="_blank"
											className="p-link"
										/>,
									]}
								/>
							</li>
							<li>
								<div className="mb-3">
									<i
										className="pi pi-angle-right text-3xl text-purplish-blue"
										style={{ verticalAlign: 'middle' }}
									/>
									{t('userPanel.processSummary.downloadAllSessionMaterials')}
								</div>
								<DownloadMaterialsButton withInfo />
							</li>
						</ul>
					</div>
				</div>
			</Page>
		</>
	);
};

export default ProcessSummary;
