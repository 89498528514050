import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { Message } from 'primereact/message';
import TextField from 'components/_common/forms/TextField';
import TextArea from 'components/_common/forms/TextArea';
import { SendIssueReportPayload } from 'types/payloads/SendIssueReportPayload';
import { useAuth } from 'contexts/authContext';
import { Button } from 'components/_new/Button';
import { InputText } from 'components/_new/InputText';
import { useGlobal } from 'contexts/globalContext';

interface ReportIssueFormObject {
	name: string;
	email: string;
	subject: string;
	content: string;
	url: string;
	screenshotUrl: string;
}

const ReportIssues = () => {
	const { t } = useTranslation();

	const { currentUser } = useAuth();
	const [screenshotUrl, setScreenshotUrl] = useState('');

	const { control, handleSubmit, setError, setValue } = useForm<ReportIssueFormObject>({
		defaultValues: {
			name: `${currentUser?.firstName} ${currentUser?.lastName}`,
			email: currentUser?.email,
			subject: '',
			content: '',
			url: window.location.href,
			screenshotUrl,
		},
	});
	const { currentProgramMembership } = usePanel();
	const { mutate: uploadImage } = useMutation((data: FormData) => userPanelApi.uploadReportImage(data), {
		onSuccess: (data) => {
			setScreenshotUrl(data);
			setValue('screenshotUrl', data);
		},
	});
	const { userAgent } = window.navigator || { navigation: { userAgent: null } };

	const { mutate, isSuccess } = useMutation(
		(data: SendIssueReportPayload) =>
			userPanelApi.postIssueReport({
				...data,
				membershipId: currentProgramMembership?.id ? Number(currentProgramMembership?.id) : undefined,
				userAgent: userAgent.length > 0 ? userAgent : null,
			}),
		{
			onError: ({ response }) => {
				setError('email', { type: 'custom', message: response.data.message });
			},
		}
	);
	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
		e.currentTarget.classList.add('border-primary', 'bg-primary-50');
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
		e.currentTarget.classList.remove('border-primary', 'bg-primary-50');
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();
		e.currentTarget.classList.remove('border-primary', 'bg-primary-50');

		const file = e.dataTransfer.files[0];
		if (file) {
			const maxFileSize = 1024 * 1024 * 3;
			if (file.size > maxFileSize) {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: t(`misc.error`),
					detail: t('misc.fileSizeExceeds', { count: 3 }),
				});
			} else {
				handleUpload(file);
			}
		}
	};
	const { toastRef } = useGlobal();
	const [previewSource, setPreviewSource] = useState<any | null>(null);
	const handleUpload = (file: File) => {
		try {
			// const img = e.files[0];
			const reader = new FileReader();
			reader.onload = () => {
				setPreviewSource(reader.result);
			};
			reader.readAsDataURL(file);
			const fd = new FormData();
			fd.append('file', file);
			uploadImage(fd);
		} catch (error) {
			// eslint-disable-next-line -- TODO: proper error handling
			console.error(error);
		}
	};
	if (isSuccess) {
		return (
			<div>
				<Message
					severity="success"
					className="flex w-full mt-3 p-3"
					text={t('userPanel.reportIssue.acknowledgement')}
				/>
			</div>
		);
	}

	const handleCancel = () => {
		setPreviewSource(null);
		setScreenshotUrl('');
		setValue('screenshotUrl', '');
	};

	return (
		<form onSubmit={handleSubmit((data) => mutate(data))} className="flex flex-column row-gap-4">
			<p className="mt-0">{t('userPanel.reportIssue.description')}</p>
			<TextField name="name" placeholder={t('userPanel.reportIssue.inputs.name')} control={control} required />
			<TextField name="email" placeholder={t('userPanel.reportIssue.inputs.email')} control={control} required />
			<TextField
				name="subject"
				placeholder={t('userPanel.reportIssue.inputs.title')}
				control={control}
				required
			/>
			<TextArea
				name="content"
				placeholder={t('userPanel.reportIssue.inputs.description')}
				control={control}
				required
			/>
			{!screenshotUrl ? (
				<div>
					<p>{t('userPanel.reportIssue.screenshot')}</p>
					<div className="flex flex-column align-items-center">
						<label
							htmlFor="file-upload"
							className="border-1 border-dashed border-round p-3 w-full text-center cursor-pointer hover:border-primary hover:bg-primary-50 flex flex-column align-items-center gap-2 transition-colors transition-duration-200"
							style={{ maxWidth: '400px' }}
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={handleDrop}
						>
							<i className="pi pi-upload text-color-secondary text-xl hover:text-primary" />
							<span className="text-color-secondary text-sm hover:text-primary">
								{t('userPanel.reportIssue.dropzone.text')}
							</span>
							<span className="text-color-secondary text-xs hover:text-primary">
								{t('userPanel.reportIssue.dropzone.maxSize')}
							</span>
						</label>
						<div className="hidden">
							<InputText
								id="file-upload"
								type="file"
								accept="image/*"
								onChange={(event) => {
									const file = event.currentTarget.files?.[0];
									if (file) {
										const maxFileSize = 1024 * 1024 * 3; // 3 mb

										if (file.size > maxFileSize) {
											toastRef?.current?.show({
												severity: 'error',
												life: 3000,
												summary: t(`misc.error`),
												detail: t('misc.fileSizeExceeds', { count: 3 }),
											});
										} else {
											handleUpload(file);
										}
									}
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-column align-items-center gap-2">
					<div className="flex flex-row gap-2  mt-3">
						<Button
							onClick={handleCancel}
							label={t('actions.remove')}
							variant="primary"
							icon="times"
							iconSet="pi"
						/>
					</div>
					<img
						src={previewSource}
						alt="upload preview"
						className="upload-preview-image border-round-md overflow-hidden"
					/>
				</div>
			)}
			<Button
				submit
				label={t('userPanel.reportIssue.buttons.send')}
				className="align-self-end justify-content-center w-5 mt-3"
			/>
		</form>
	);
};

export default ReportIssues;
