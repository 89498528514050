import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { authApi } from 'api';
import ValidatePassword from 'components/_common/forms/ValidatePassword';
import CustomLabel from 'components/_common/forms/Label';
import { useGlobal } from 'contexts/globalContext';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';

type ChangePasswordForm = {
	currentPassword: string;
	newPassword: string;
	repeatPassword: string;
};

export const ChangePasswordDialog = ({ onHide, visible, ...props }: DialogBaseExtendProps) => {
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();
	const { data: isExternalAuth } = useQuery(['isExternalAuth'], authApi.isExternalAuth, {
		enabled: visible,
	});
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setError,
	} = useForm<ChangePasswordForm>();

	const { mutate } = useMutation(authApi.changePassword, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
			});
			onHide?.();
		},
		onError: ({ response }) => {
			const message = response?.data?.message;
			if (Object.hasOwn(response.data, 'message') && typeof message === 'string') {
				switch (message) {
					case 'Invalid credentials':
						setError('currentPassword', {
							type: 'custom',
							message: refineMessage(response.data.message),
						});
						break;
					default:
						break;
				}
			} else {
				setError('currentPassword', {
					type: 'custom',
					message: refineMessage(response.data.message),
				});
			}
		},
	});

	const refineMessage = (message: string) => {
		return message === 'Internal server error' || message === 'Invalid credentials'
			? t('userPanel.myAccount.invalidPassword')
			: message;
	};

	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});

	const renderContent = () => {
		if (isExternalAuth) {
			return <p>{t('userPanel.myAccount.googleMicrosoftAuthPasswordMessage')}</p>;
		}

		return (
			<form id="changePasswordForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
				<div className="flex flex-column mb-2">
					<p>{t('auth.passwordComplexityRequirement')}</p>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="currentPassword" label={t('userPanel.myAccount.currentPassword')} required />
					<ValidatePassword
						name="currentPassword"
						control={control}
						placeholder={t('userPanel.myAccount.passwordRequired')}
						required
					/>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="newPassword" label={t('userPanel.myAccount.newPassword')} required />
					<ValidatePassword
						name="newPassword"
						control={control}
						placeholder={t('userPanel.myAccount.passwordRequired')}
						rules={{
							minLength: { value: 8, message: t('auth.passwordMinLength') },
							maxLength: { value: 30, message: t('auth.passwordMaxLength') },
							validate: {
								customRequired: () => {
									const regex =
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]{8,}$/;
									if (!regex.test(watch('newPassword'))) {
										if (!/(?=.*[a-z])/.test(watch('newPassword'))) {
											return t('auth.passwordRequiresLowercase');
										}
										if (!/(?=.*[A-Z])/.test(watch('newPassword'))) {
											return t('auth.passwordRequiresUppercase');
										}
										if (!/(?=.*\d)/.test(watch('newPassword'))) {
											return t('auth.passwordRequiresDigit');
										}
										if (!/(?=.*[@$#!%*?&])/.test(watch('newPassword'))) {
											return t('auth.passwordRequiresSpecialChar');
										}
									}
									return true;
								},
							},
						}}
						required
					/>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="repeatPassword" label={t('userPanel.myAccount.repeatPassword')} required />
					<ValidatePassword
						name="repeatPassword"
						control={control}
						rules={{
							validate: {
								matching: (v) => v === watch('newPassword') || t('misc.forms.notMatchingPassword'),
							},
						}}
						required
						placeholder={t('userPanel.myAccount.repeatPassword')}
					/>
				</div>
			</form>
		);
	};

	const dialogActions = useMemo(() => {
		if (isExternalAuth) {
			return [
				{
					key: 'close',
					label: t('actions.close'),
					onClick: onHide,
				},
			];
		}

		return [
			{
				key: 'save',
				submit: true,
				form: 'changePasswordForm',
				label: t('actions.save'),
			},
		];
	}, [isExternalAuth, onHide, t]);

	return (
		<DialogAction
			title={t('userPanel.myAccount.changePassword')}
			size={isExternalAuth ? 'sm' : 'md'}
			actions={dialogActions}
			visible={visible}
			onHide={onHide}
			withoutCancelAction={isExternalAuth}
			{...props}
		>
			{renderContent()}
		</DialogAction>
	);
};
