import { EditorJSLocale } from '../locale';

export const pl: EditorJSLocale = {
	messages: {
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Kliknij aby dostosować',
					// 'or drag to move': 'lub przeciągnij by przemieścić',
					// 'Click to close': 'Kliknij aby zamknąć',
				},
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'Конвертировать в',
				},
			},
			toolbar: {
				toolbox: {
					Add: 'Dodaj',
				},
			},
			popover: {
				Filter: 'Szukaj',
				'Nothing found': 'Nie znaleziono',
				'Convert to': 'Konwertuj na',
			},
		},
		toolNames: {
			Text: 'Tekst',
			Heading: 'Nagłówek',
			Image: 'Obraz',
			'Unordered List': 'Lista wypunktowana',
			'Ordered List': 'Lista uporządkowana',
			Checklist: 'Lista zadań',
			Quote: 'Cytat',
			Link: 'Link',
			Table: 'Tabela',
			Bold: 'Pogrubienie',
			Italic: 'Kursywa',
			Underline: 'Podkreślenie',
			Delimiter: 'Separator',
			Style: 'Style CSS',
			Columns: 'Kolumny',
		},
		tools: {
			header: {
				placeholder: 'Wpisz nagłówek',
			},
			paragraph: {
				placeholder: 'Wpisz treść',
			},
			quote: {
				quotePlaceholder: 'Wprowadź cytat',
				captionPlaceholder: 'Autor cytatu',
				'Align Left': 'Wyrównaj do lewej',
				'Align Center': 'Wyrównaj do środka',
			},
			list: {
				Unordered: 'Lista punktowana',
				Ordered: 'Lista uporządkowana',
				Checklist: 'Lista zadań',
			},
			link: {
				'Add a link': 'Dodaj link',
			},
			image: {
				captionPlaceholder: 'Wprowadź opis',
				buttonContent: 'Wybierz obraz z dysku',
				'With border': 'Z obramowaniem',
				'Stretch image': 'Pełna szerokość',
				'With background': 'Z tłem',
			},
			table: {
				'With headings': 'Z nagłówkami',
				'Without headings': 'Bez nagłówków',
				Stretch: 'Rozciągnij',
				Collapse: 'Nie rozciągaj',
				'Add row above': 'Dodaj wiersz powyżej',
				'Add row below': 'Dodaj wiersz poniżej',
				'Delete row': 'Usuń wiersz',
				'Add column to left': 'Dodaj kolumne po lewej',
				'Add column to right': 'Dodaj kolumne po prawej',
				'Delete column': 'Usuń kolumnę',
			},
			delimiter: {
				'Line 8%': 'Linia 8%',
				'Line 15%': 'Linia 15%',
				'Line 25%': 'Linia 25%',
				'Line 35%': 'Linia 35%',
				'Line 50%': 'Linia 50%',
				'Line 60%': 'Linia 60%',
				'Line 100%': 'Linia 100%',
			},
		},
		blockTunes: {
			delete: {
				Delete: 'Usuń',
				'Click to delete': 'Kliknij aby usunąć',
			},
			moveUp: {
				'Move up': 'Przesuń w górę',
			},
			moveDown: {
				'Move down': 'Przesuń w dół',
			},
		},
	},
	direction: 'ltr',
};
