import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { organizationsApi } from 'api';
import { useGlobal } from 'contexts/globalContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useOrganizationPanel } from 'contexts/organizationPanelContext';
import { userDisplayName } from 'utils/userDisplayName';
import { dateFormat } from 'utils/dateFormat';
import { Button } from 'components/_new/Button';
import { DialogConfirm } from 'components/_new/Dialog';
import { OrganizationMembership } from 'types/Membership';
import { classNames } from 'primereact/utils';
import { Box, BoxSection } from 'components/_new/Box';
import { AddUserDialog } from '../_common/panel/AddUserDialog';
import { sortMemberships } from '../programPanel/utils';

const OrganizationMemberships = () => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();

	const [addUserModal, setAddUserModal] = useState(false);
	const [selectedMembership, setSelectedMembership] = useState<OrganizationMembership | null>(null);
	const [makeAdminDialogOpen, setMakeAdminDialogOpen] = useState(false);
	const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	// const [deletingMembershipModalOpen, setDeletingMembershipModalOpen] = useState(false);
	// const [deletingMembership, setDeletingMembership] = useState<any>(null);

	const {
		currentOrganization: { organizationName },
	} = useOrganizationPanel();
	const { data: organizationMemberships, refetch: organizationMembershipRefetch } = useQuery(
		['getOrganizationMembership', organizationName],
		() => organizationsApi.getOrganizationMemberships({ organizationName: String(organizationName) }),
		{
			enabled: Boolean(organizationName),
		}
	);

	const {
		mutate: manageMembershipMutate,
		isLoading: manageMembershipLoading,
		variables: manageMembershipVariables,
	} = useMutation(['manageMyOrganizationMembership'], organizationsApi.manageMyOrganizationMembership, {
		onSuccess: () => {
			organizationMembershipRefetch();
			// queryClient.refetchQueries(['getOrganizationMembership']);
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('misc.updatedSuccessfully'),
			});
			setSelectedMembership(null);
			setMakeAdminDialogOpen(false);
		},
	});

	// const {
	// 	mutate: removeMembershipMutate,
	// 	isLoading: removeMembershipLoading,
	// 	variables: removeMembershipVariables,
	// } = useMutation(['removeMyOrganizationMembership'], organizationsApi.removeMyOrganizationMembership, {
	// 	onSuccess: () => {
	// 		queryClient.refetchQueries(['getOrganizationMembership']);
	// 		if (toastRef?.current) {
	// 			toastRef.current?.show({
	// 				severity: 'success',
	// 				life: 3000,
	// 				summary: t('misc.success'),
	// 				detail: 'Usunięto pomyślnie',
	// 			});
	// 		}
	// 	},
	// });

	// const handleDeleteMembership = () => {
	// 	removeMembershipMutate({ organizationName: organization.name, membershipId: deletingMembership.id });
	// };

	const handleMembershipRoleUpdate = (membershipId: number, role: string) => {
		manageMembershipMutate({ organizationName: String(organizationName), membershipId, role });
	};

	const sortedOrganizationMemberships = organizationMemberships
		? sortMemberships(organizationMemberships, 'organization admin', 'user.email')
		: [];

	return (
		<>
			<DialogConfirm
				title={t('organizationPanel.memberships.confirmMakeAnAdmin')}
				visible={makeAdminDialogOpen}
				onHide={() => setMakeAdminDialogOpen(false)}
				onConfirm={() => {
					if (selectedMembership) {
						handleMembershipRoleUpdate(selectedMembership.id, 'organization admin');
					}
				}}
			/>
			<AddUserDialog
				visible={editUserDialogOpen || addUserModal}
				onHide={() => {
					setEditUserDialogOpen(false);
					setAddUserModal(false);
					setSelectedUser(null);
				}}
				isOrganisationPanel
				editMode={editUserDialogOpen}
				userToEdit={selectedUser}
				onUserAddSuccess={() => {
					organizationMembershipRefetch();
				}}
			/>
			<Helmet title={t('organizationPanel.organizationMemberships')} />
			<SubPageTitle title={t('organizationPanel.organizationMemberships')} />
			<div className="ml-2 flex flex-row justify-content-between mr-2">
				<p>{t('organizationPanel.memberships.desc')}</p>
				<Button
					label={t('userPanel.myAccount.addNewUser')}
					className=" p-button p-button-sm min-w-max max-h-3rem"
					onClick={() => setAddUserModal(true)}
				/>
			</div>
			<div>
				<ul className="p-list-unstylled flex flex-column gap-2">
					{sortedOrganizationMemberships.map((membership: any) => {
						const displayName = userDisplayName(membership.user);
						return (
							<li key={membership.id}>
								<Box variant="white-bordered">
									<BoxSection>
										<h2>{displayName}</h2>
										<p>
											{t('organizationPanel.memberships.email')}: {membership.user.email}
										</p>
										<p className="inline-flex flex-row align-items-center">
											{t('organizationPanel.memberships.role')}:{' '}
											<span
												className={classNames('mx-2', {
													'text-rose bold': membership.role === 'organization admin',
													'text-purplish-blue': membership.role === 'user',
												})}
											>
												{membership.role}
											</span>{' '}
											| {t('organizationPanel.memberships.terms')}:{' '}
											<span className="mx-2">
												{membership.user.terms ? (
													<span>
														<i className="mx-1 pi pi-check-circle text-green-400" />
													</span>
												) : (
													<span>
														<i className="mx-1 pi pi-times-circle text-red-400" />
													</span>
												)}
											</span>
											| {t('organizationPanel.memberships.lastLogin')}:{' '}
											<span className="mx-2">
												{membership?.user?.lastLoginDate ? (
													<span className="text-purplish-blue">
														{dateFormat(membership.user.lastLoginDate, 'full')}
													</span>
												) : (
													'-'
												)}
											</span>
										</p>
										<div className="flex gap-2 justify-content-end">
											<Button
												label={t('organizationPanel.memberships.editDetails')}
												onClick={() => {
													setSelectedUser(membership.user);
													setEditUserDialogOpen(true);
												}}
												variant="priamry-light"
											/>
											{membership.role === 'organization admin' && (
												<Button
													label={t('organizationPanel.memberships.removeAdminPrivilages')}
													onClick={() => {
														handleMembershipRoleUpdate(membership.id, 'user');
													}}
													loading={
														manageMembershipLoading &&
														manageMembershipVariables?.membershipId === membership.id
													}
													variant="primary-outlined"
												/>
											)}
											{membership.role === 'user' && (
												<Button
													label={t('organizationPanel.memberships.makeAnAdmin')}
													onClick={() => {
														setSelectedMembership(membership);
														setMakeAdminDialogOpen(true);
													}}
													loading={
														manageMembershipLoading &&
														manageMembershipVariables?.membershipId === membership.id
													}
												/>
											)}
											{/* <Button
										label={t('actions.remove')}
										onClick={() => {
											setDeletingMembership(membership);
											setDeletingMembershipModalOpen(true);
										}}
										loading={
											removeMembershipLoading &&
											removeMembershipVariables?.membershipId === membership.id
										}
									/> */}
										</div>
									</BoxSection>
								</Box>
							</li>
						);
					})}
				</ul>
			</div>
			{/* <CustomConfirmDialog
				handleAccept={() => handleDeleteMembership()}
				confirmationOpen={deletingMembershipModalOpen}
				setConfirmationOpen={setDeletingMembershipModalOpen}
				message={`Czy na pewno chces usunąć użytkownika ${deletingMembership?.user?.firstName} ${deletingMembership?.user?.lastName} z organizacji? Sprowoduje to również usunięcie go z programów
					organizacji.`}
			/> */}
		</>
	);
};

export default OrganizationMemberships;
