import React, { Ref, forwardRef, CSSProperties, useMemo, ReactNode, useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { universalRenderer } from 'utils/universalRenderer';
import { nameInitialsSplitter } from 'utils/nameInitialsSplitter';
import './Avatar.scss';
import { Icon } from '../Icon';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type AvatarProps = {
	src?: string | null;
	size?: AvatarSize;
	name: string | null;
	render?: ReactNode | (() => ReactNode);
	containerClassName?: string;
	className?: string;
	style?: CSSProperties;
};

export const Avatar = forwardRef(
	(
		{ src, size = 'md', name, render, className, containerClassName, ...restProps }: AvatarProps,
		ref: Ref<HTMLDivElement>
	) => {
		const finalContainerClassName = useMemo(
			() => classNames('avatar-container', containerClassName),
			[containerClassName]
		);
		const initials = name ? nameInitialsSplitter(name) : null;

		// progressive image loading
		const [imgSrc, setImgSrc] = useState<string | null>(null);
		const [loading, setLoading] = useState(false);
		useEffect(() => {
			if (src) {
				setLoading(true);
				setTimeout(() => {
					const img = new Image();
					img.src = src;
					img.onload = () => {
						setImgSrc(src);
						setLoading(false);
					};
					img.onabort = () => {
						setLoading(false);
					};
					img.onerror = () => {
						setLoading(false);
					};
				}, 100);
			} else {
				setImgSrc(null);
				setLoading(false);
			}
		}, [src]);

		const finalAvatarClassName = useMemo(
			() => classNames('avatar', `avatar-size-${size}`, className),
			[size, className]
		);

		return (
			<div ref={ref} className={finalContainerClassName} {...restProps}>
				<div className={finalAvatarClassName}>
					{!loading && imgSrc === null && (
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>
							{initials === null ? (
								<div className="avatar-initials">
									<Icon name="user" iconStyle="regular" />
								</div>
							) : (
								<div
									className="avatar-initials"
									aria-label={name || ''}
									role="img"
									title={initials || ''}
								>
									{initials}
								</div>
							)}
						</>
					)}
					{loading && (
						<div className="avatar-loader">
							<Icon name="circle-notch" iconStyle="solid" className="fa-spin" />
						</div>
					)}
					{imgSrc !== null && (
						<div className="avatar-image" style={{ backgroundImage: `url(${imgSrc})` }} role="img" />
					)}
				</div>
				{render && universalRenderer(render)}
			</div>
		);
	}
);
