import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import SessionBox from 'components/userPanel/sessions/partials/SessionBox';
import { useSessionsData } from 'components/userPanel/sessions/Sessions';
import { usePair } from 'contexts/pairContext';
import { fullName } from 'utils/fullName';
import { usePanel } from 'contexts/panelContext';
import { useGlobal } from 'contexts/globalContext';
import { Button } from 'components/_new/Button';
import { RouterUrlParams } from '../../../App';
import SubPageTitle from '../../_common/panel/SubPageTitle';

const SessionsList = () => {
	const { t } = useTranslation();
	const params = useParams() as RouterUrlParams;
	const { sessionId } = params;
	const { panelType, currentProgramMembership } = usePanel();
	const { toastRef } = useGlobal();
	const {
		pair: { id: pairId, application: pairApplication },
	} = usePair();

	const { sessionsData, sessiosRefetch } = useSessionsData();
	const currentDate = new Date();
	const firstUnallocated = sessionsData.find((session) => session.date === null);
	let currentSession =
		sessionsData.find((element) => element.date && new Date(element.date).getTime() > currentDate.getTime()) ||
		firstUnallocated ||
		sessionsData[sessionsData.length - 1];
	if (firstUnallocated) {
		const oneBefore = sessionsData.find((session) => session.sessionNumber === firstUnallocated.sessionNumber - 1);
		if (oneBefore && oneBefore.date < currentSession.date) {
			currentSession = oneBefore;
		}
	}
	const nextSessionDate =
		currentSession && sessionsData.find((session) => session.sessionNumber === currentSession.sessionNumber + 1);
	const { mutate: createSession } = useMutation(
		() => userPanelApi.createSession(pairId, currentProgramMembership.id),
		{
			onSuccess: () => {
				sessiosRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.createdSuccess'),
				});
				const scrollWrapperRef = document.getElementById('scrollWrapperRef');
				if (scrollWrapperRef) {
					scrollWrapperRef.scroll({ top: sessionsData.length * 50, behavior: 'smooth' });
				}
			},
		}
	);

	const pairFullName = fullName(pairApplication!);
	const computedTitle = `${t('userPanel.sessions.header', {
		type: panelType === 'mentor' ? 'Mentee' : t('misc.withMentor'),
	})}: ${pairFullName}`;

	const lastSessionWithDate = sessionsData.filter(({ date }) => Boolean(date)).pop()?.id;

	return (
		<>
			<SubPageTitle
				title={computedTitle}
				additionalStyles={{
					padding: '0rem !important',
				}}
				right={
					<Button
						label={t('userPanel.sessions.addSession')}
						variant="primary-text"
						icon="plus"
						iconStyle="solid"
						onClick={() => createSession()}
						className="p-button-link cursor-pointer"
						disabled={!!sessionId}
					/>
				}
			/>

			<div className="flex flex-column gap-2">
				{sessionsData.map((session, index) => {
					const previousSession = index > 0 ? sessionsData[index - 1] : undefined;
					const nextSession = index < sessionsData.length - 1 ? sessionsData[index + 1] : undefined;
					const canDisplayTasks = nextSessionDate
						? session.sessionNumber < nextSessionDate.sessionNumber
						: true;

					return (
						<SessionBox
							key={session.id}
							previousSession={previousSession}
							nextSession={nextSession}
							session={session}
							canDisplayTasks={canDisplayTasks}
							showSetDateButton={session.id === firstUnallocated?.id}
						/>
					);
				})}
			</div>
		</>
	);
};

export default SessionsList;
