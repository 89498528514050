import { EditorJSLocale } from '../locale';

export const en: EditorJSLocale = {
	messages: {
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Click to tune',
					// 'or drag to move': 'or drag to move';
					// 'Click to close': 'Click to close';
				},
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'xxxx',
				},
				// 'Convert to': 'xxxx',
			},
			toolbar: {
				toolbox: {
					Add: 'Addx',
				},
			},
			popover: {
				Filter: 'Filter',
				'Nothing found': 'Nothing found',
				'Convert to': 'Convert to',
			},
		},
		toolNames: {
			Text: 'Text',
			Heading: 'Heading',
			Image: 'Image',
			'Unordered List': 'Unordered list',
			'Ordered List': 'Ordered list',
			Checklist: 'Checklist',
			Quote: 'Quote',
			Link: 'Link',
			Table: 'Table',
			Bold: 'Bold',
			Italic: 'Italic',
			Underline: 'Underline',
			Delimiter: 'Delimiter',
			Style: 'CSS Styles',
			Columns: 'Columns',
		},
		tools: {
			header: {
				placeholder: 'Enter a header',
			},
			paragraph: {
				placeholder: 'Enter a content',
			},
			quote: {
				quotePlaceholder: 'Enter a quote',
				captionPlaceholder: "Quote's author",
				'Align Left': 'Align left',
				'Align Center': 'Align center',
			},
			list: {
				Unordered: 'Unordered list',
				Ordered: 'Ordered list',
				Checklist: 'Checklist',
			},
			link: {
				'Add a link': 'Add a link',
			},
			image: {
				captionPlaceholder: 'Enter a caption',
				buttonContent: 'Select an image from disc',
				'With border': 'With border',
				'Stretch image': 'Full width',
				'With background': 'With background',
			},
			table: {
				'With headings': 'With headings',
				'Without headings': 'Without headings',
				Stretch: 'Stretch',
				Collapse: 'Collapse',
				'Add row above': 'Add row above',
				'Add row below': 'Add row below',
				'Delete row': 'Delete row',
				'Add column to left': 'Add column to left',
				'Add column to right': 'Add column to right',
				'Delete column': 'Delete column',
			},
			delimiter: {},
		},
		blockTunes: {
			delete: {
				Delete: 'Delete',
				'Click to delete': 'Click to delete',
			},
			moveUp: {
				'Move up': 'Move up',
			},
			moveDown: {
				'Move down': 'Move down',
			},
		},
	},
	direction: 'ltr',
};
