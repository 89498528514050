import React, { ReactNode, HTMLAttributes, forwardRef, Ref } from 'react';
import { classNames } from 'primereact/utils';
import { universalRenderer } from 'utils/universalRenderer';
import './Box.scss';

export type BoxSectionHeader = ReactNode | (() => ReactNode);

export type BoxSectionProps = HTMLAttributes<HTMLDivElement> & {
	header?: BoxSectionHeader;
	headerClassName?: string;
	contentClassName?: string;
	noPad?: boolean;
	horizontal?: boolean;
};

export const BoxSection = forwardRef(
	(
		{
			header,
			headerClassName,
			contentClassName,
			children,
			className,
			noPad = false,
			horizontal = false,
			...restProps
		}: BoxSectionProps,
		ref: Ref<HTMLDivElement>
	) => {
		return (
			<div ref={ref} className={classNames('box-section', 'flex-1', className)} {...restProps}>
				{header && (
					<h3 className={classNames('box-section-header', headerClassName)}>{universalRenderer(header)}</h3>
				)}
				<div
					className={classNames(
						'box-section-content',
						{ 'box-section-pad': !noPad, 'box-section-horizontal': horizontal },
						contentClassName
					)}
				>
					{children}
				</div>
			</div>
		);
	}
);
