import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import LinkedInCard from './userPanel/LinkedInBadgeGenerator';

const LinkedInBadgePage = () => {
	const { t } = useTranslation();
	const { currentApplicationData } = usePanel();

	// if (!userDetails) return <PageMissing />;

	return (
		<>
			<Helmet title={t('userPanel.navigation.linkedInBadge')} />
			<SubPageTitle title={t('userPanel.navigation.linkedInBadge')} />
			<div>
				<LinkedInCard data={currentApplicationData} />
			</div>
		</>
	);
};

export default LinkedInBadgePage;
