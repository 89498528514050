import React, { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Pair } from 'types/Pair';
import { useScope } from 'contexts/scopeContext';
import { usePanel } from 'contexts/panelContext';
import { Dialog } from 'components/_new/Dialog';
import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { InitialMeetingForm } from './components/InitialMeetingForm';
import MentorActions from './components/MentorActions';
import MenteeActions from './components/MenteeActions';

interface PairActionsProps {
	type: string;
	data: Pair;
	pairFullname: string;
	disableButtons?: boolean;
	onAction?: () => void;
}

const PairActions = ({ type, data, pairFullname, disableButtons, onAction }: PairActionsProps) => {
	const { organizationName, programName } = useParams();

	const { currentProgram } = useScope();
	const { panelType } = usePanel();

	const [initialMeetingModal, setInitialMeetingModal] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const { t } = useTranslation();
	const [infoDialogOpen, setInfoDialogOpen] = useState(false);

	const afterAcceptpairMessageLink = useMemo(() => {
		switch (currentProgram?.language) {
			case 'en':
				return `/panel/${organizationName}/${programName}/${panelType}/files/preparation-forms`;
			case 'pl':
			default:
				return `/panel/${organizationName}/${programName}/${panelType}/files/formularze-przygotowawcze`;
		}
	}, [panelType, currentProgram?.language, organizationName, programName]);

	return (
		<>
			<CustomConfirmDialog
				confirmationOpen={confirmModalOpen}
				setConfirmationOpen={setConfirmModalOpen}
				handleAccept={() => setInitialMeetingModal(false)}
				message={t('userPanel.sessions.closeWindowConfirm')}
			/>
			<Dialog
				title={t('userPanel.pairing.initialMeetingTitle', { type: pairFullname })}
				visible={initialMeetingModal}
				onHide={() => setConfirmModalOpen(true)}
			>
				<InitialMeetingForm
					setModalOff={() => setInitialMeetingModal(false)}
					id={data.id}
					menteeMembershipId={data.menteeMembershipId}
					onAction={onAction}
				/>
			</Dialog>
			<Dialog
				title={t('userPanel.pairing.creatingMentoringPair', { type: pairFullname })}
				visible={infoDialogOpen}
				onHide={() => setInfoDialogOpen(false)}
			>
				<div>
					<p>{t('userPanel.pairing.afterAcceptPairMessageLine1')}</p>
					<p>{t('userPanel.pairing.afterAcceptPairMessageLine2')}</p>
					<p>
						<Trans
							i18nKey="userPanel.pairing.afterAcceptPairMessageLine3"
							components={[
								<Link
									to={{
										pathname: afterAcceptpairMessageLink,
									}}
									className="underline"
								/>,
							]}
						/>
					</p>
				</div>
			</Dialog>
			<div className="flex w-full">
				{type === 'mentor' ? (
					<MentorActions
						data={data}
						setInfoDialogOpen={setInfoDialogOpen}
						disabled={disableButtons}
						onAction={onAction}
					/>
				) : (
					<MenteeActions
						data={data}
						setInitialMeetingModal={setInitialMeetingModal}
						setInfoDialogOpen={setInfoDialogOpen}
						disabled={disableButtons}
						onAction={onAction}
					/>
				)}
			</div>
		</>
	);
};

export default PairActions;
