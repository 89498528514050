import { httpClient } from 'api';

export const finalReportApi = {
	updateFinalReportInfo: (pairId: number, data: any) => httpClient.post<any>(`final-report/${pairId}/info`, data),
	updateFinalReportSurvey: (pairId: number, applicationId: number, data: any) =>
		httpClient.post<any>(`final-report/${pairId}/${applicationId}/survey`, data),
	getFinalReport: (pairId: number, applicationId: number) =>
		httpClient.get<any>(`final-report/${pairId}/${applicationId}`),
	prepareFinalReportDownloadLink: ({
		pairId,
		membershipId,
		type,
	}: {
		pairId: number;
		membershipId: number;
		type: string;
	}) => httpClient.post<string>(`final-report/report/${pairId}/${membershipId}/${type}`),
	downloadMaterials: (pairId: number, applicationId: number) =>
		httpClient.get<any>(`final-report/download-materials/${pairId}/${applicationId}/download`, undefined, {
			responseType: 'blob',
		}),
	prepareMaterials: (pairId: number, applicationId: number, force = false) =>
		httpClient.post<boolean>(`final-report/download-materials/${pairId}/${applicationId}/prepare`, { force }),
	checkDownloadMaterials: (pairId: number, applicationId: number) =>
		httpClient.get<
			boolean | 'pending' | { time: string; size: string; canRegenerate: boolean; url: string; expires: string }
		>(`final-report/download-materials/${pairId}/${applicationId}/check`),
};
