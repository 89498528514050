import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { finalReportApi } from 'api';
import { Button } from 'components/_new/Button';
import { usePanel } from 'contexts/panelContext';
import { usePair } from 'contexts/pairContext';
import { Tooltip } from 'primereact/tooltip';

type DownloadMaterialsButtonProps = {
	withInfo?: boolean;
};

export const DownloadMaterialsButton = ({ withInfo }: DownloadMaterialsButtonProps) => {
	const { t } = useTranslation();
	const { currentApplicationData } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const [checkDownloadMaterialsIntervalFetch, setCheckDownloadMaterialsIntervalFetch] = useState<{
		interval: NodeJS.Timer | null;
		enabled: boolean;
	}>({
		interval: null,
		enabled: true,
	});
	const [checkDownloadMaterials, setCheckDownloadMaterials] = useState<
		boolean | 'pending' | { time: string; size: string; canRegenerate: boolean; url: string; expires: string }
	>(false);
	const {
		// data: checkDownloadMaterials,
		isLoading: checkDownloadMaterialsLoading,
		refetch: checkDownloadMaterialsMutate,
		// remove: checkDownloadMaterialsRemove,
	} = useQuery(
		[
			'userfiles-check',
			{
				pairId,
				applicationId: currentApplicationData.id,
			},
		],
		() => finalReportApi.checkDownloadMaterials(pairId, currentApplicationData.id),
		{
			enabled: false,
			onSuccess: (data) => {
				setCheckDownloadMaterials(data);
			},
		}
	);

	const { mutate: prepareMaterials, isLoading: prepareMaterialsLoading } = useMutation(
		['userfiles-prepare', { pairId, applicationId: currentApplicationData.id }],
		(force?: boolean) => finalReportApi.prepareMaterials(pairId, currentApplicationData.id, force),
		{
			onMutate: () => {
				setCheckDownloadMaterials('pending');
				setCheckDownloadMaterialsIntervalFetch((prev) => ({ ...prev, enabled: false }));
			},
			onSuccess: (result) => {
				if (result) {
					setTimeout(() => {
						setCheckDownloadMaterialsIntervalFetch((prev) => ({ ...prev, enabled: true }));
					}, 1000);
				}
			},
		}
	);

	const handleDownload = useCallback(() => {
		if (checkDownloadMaterials && typeof checkDownloadMaterials === 'object') {
			const { url } = checkDownloadMaterials;
			const downloadLink = document.createElement('a');
			downloadLink.href = url;
			downloadLink.rel = 'noreferrer';
			downloadLink.target = '_blank';
			downloadLink.download = 'files.zip';
			downloadLink.href = url;
			document.body.append(downloadLink);
			downloadLink.click();
			setTimeout(() => {
				downloadLink.remove();
			}, 100);
		}
	}, [checkDownloadMaterials]);

	useEffect(() => {
		if (checkDownloadMaterialsIntervalFetch.enabled) {
			checkDownloadMaterialsMutate();
			const interval = setInterval(() => {
				checkDownloadMaterialsMutate();
			}, 5000);
			setCheckDownloadMaterialsIntervalFetch((prev) => ({ ...prev, interval }));
		} else {
			setCheckDownloadMaterialsIntervalFetch((prev) => {
				if (prev.interval) {
					clearInterval(prev.interval);
				}
				return {
					interval: null,
					enabled: false,
				};
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkDownloadMaterialsIntervalFetch.enabled]);

	useEffect(() => {
		return () => {
			if (checkDownloadMaterialsIntervalFetch.interval) {
				setCheckDownloadMaterialsIntervalFetch((prev) => ({ ...prev, enabled: false }));
				clearInterval(checkDownloadMaterialsIntervalFetch.interval);
			}
		};
	}, [checkDownloadMaterialsIntervalFetch]);

	return (
		<>
			{!prepareMaterialsLoading && checkDownloadMaterials && typeof checkDownloadMaterials === 'object' && (
				<div className="flex flex-column gap-1 align-items-center justify-content-center my-2">
					<div className="flex flex-row gap-1 align-items-center">
						<Button
							id="download"
							variant="primary"
							onClick={handleDownload}
							disabled={!checkDownloadMaterials}
							title={
								!checkDownloadMaterials
									? t('userPanel.userFiles.downloadAllMaterialsDisabled')
									: undefined
							}
							loading={prepareMaterialsLoading}
							label={`${t('userPanel.processSummary.downloadMentoringMaterials')} (${
								checkDownloadMaterials.size
							})`}
						/>
						{!withInfo && (
							<>
								<Tooltip
									target="#reprepare-button"
									content={t('userPanel.userFiles.materialsRegenerageAgainInfo')}
									disabled={
										typeof checkDownloadMaterials === 'object' &&
										checkDownloadMaterials.canRegenerate
									}
									className="max300px"
									position="left"
								/>
								<div id="reprepare-button" className="inline-block">
									<Button
										onClick={() => {
											prepareMaterials(true);
										}}
										label={t('userPanel.userFiles.materialsRegenerateAgain')}
										icon="rotate"
										iconOnly
										variant="primary-outlined"
										loading={prepareMaterialsLoading}
										disabled={!checkDownloadMaterials.canRegenerate}
									/>
								</div>
							</>
						)}
					</div>
					{withInfo && (
						<>
							<div className="">
								{t('userPanel.userFiles.materialsGeneratedAt', {
									time: new Date(checkDownloadMaterials.time).toLocaleString(),
									size: checkDownloadMaterials.size,
								})}
							</div>
							<Tooltip
								target="#reprepare-button"
								content={t('userPanel.userFiles.materialsRegenerageAgainInfo')}
								disabled={
									typeof checkDownloadMaterials === 'object' && checkDownloadMaterials.canRegenerate
								}
								className="max300px"
								position="top"
							/>
							<div id="reprepare-button" className="inline-block">
								<Button
									onClick={() => {
										prepareMaterials(true);
									}}
									label={t('userPanel.userFiles.materialsRegenerateAgain')}
									variant="primary-text"
									variantSize="sm"
									loading={prepareMaterialsLoading}
									disabled={!checkDownloadMaterials.canRegenerate}
								/>
							</div>
						</>
					)}
				</div>
			)}

			{(prepareMaterialsLoading ||
				typeof checkDownloadMaterials === 'boolean' ||
				checkDownloadMaterials === 'pending') && (
				<div className="flex flex-column gap-1 align-items-center justify-content-center my-2">
					<Button
						variant="primary"
						onClick={() => {
							prepareMaterials(false);
						}}
						disabled={!checkDownloadMaterials}
						title={
							!checkDownloadMaterials ? t('userPanel.userFiles.downloadAllMaterialsDisabled') : undefined
						}
						loading={
							prepareMaterialsLoading ||
							checkDownloadMaterialsLoading ||
							checkDownloadMaterials === 'pending'
						}
						label={t('userPanel.processSummary.downloadMentoringMaterials')}
					/>
					{withInfo && (
						<div className="text-center text-gray max300px">
							{checkDownloadMaterials === true && (
								<span>{t('userPanel.userFiles.materialsPrepareInfo')}</span>
							)}
							{prepareMaterialsLoading ||
								(checkDownloadMaterials === 'pending' && (
									<span>{t('userPanel.userFiles.materialsBeingPrepared')}</span>
								))}
						</div>
					)}
				</div>
			)}
		</>
	);
};
