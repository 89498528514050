/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/authContext';
import { Avatar } from 'components/_new/Avatar';
import { Button } from 'components/_new/Button';
import { useGlobal } from 'contexts/globalContext';
import { userDisplayName } from 'utils/userDisplayName';
import { Checkbox } from 'primereact/checkbox';
import { usersApi } from 'api';
import { useMutation } from '@tanstack/react-query';
import { Spinner } from 'components/_new/Spinner';
import { Tooltip } from 'primereact/tooltip';
import { Segment } from './components/Segment';
import { ChangeNameDialog } from './components/ChangeNameDialog';
import { ChangeEmailDialog } from './components/ChangeEmailDialog';
import { ChangePasswordDialog } from './components/ChangePasswordDialog';
import { ChangeLanguageDialog } from './components/ChangeLanguageDialog';

export const MyAccount = () => {
	const { t } = useTranslation();
	const { currentUser, currentMemberships, currentUserRefetch } = useAuth();
	const { setAvatarModalIsOpen } = useGlobal();

	const hasOrganizationAdmin =
		currentUser?.organizationMemberships.findIndex((pm) => pm.role === 'organization admin') !== -1 || false;
	const hasProgramAdmin = !hasOrganizationAdmin
		? currentUser?.programMemberships.findIndex((pm) => pm.role === 'program admin') !== -1 || false
		: false;
	const twoFactorAuthAdminForced = hasOrganizationAdmin || hasProgramAdmin;
	const twoFactorAuthProgramForced = currentMemberships
		.map(({ programs }) => programs)
		.flat()
		.some(({ requireTwoFactorAuth }) => requireTwoFactorAuth);

	const { mutate: updateTwoFactorAuthMutate, isLoading: updateTwoFactorAuthLoading } = useMutation(
		(enabled: boolean) => usersApi.editTwoFactorAuth(enabled),
		{
			onSuccess: () => {
				currentUserRefetch();
			},
		}
	);

	const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(currentUser?.twoFactorAuth);
	const handleChangeTwoFactorAuth = (checked: boolean) => {
		updateTwoFactorAuthMutate(checked);
		setTwoFactorAuthEnabled(checked);
	};

	const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
	const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);
	const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
	const [changeLanguageDialogOpen, setChangeLanguageDialogOpen] = useState(false);

	return (
		<>
			<ChangeNameDialog visible={changeNameDialogOpen} onHide={() => setChangeNameDialogOpen(false)} />
			<ChangeEmailDialog visible={changeEmailDialogOpen} onHide={() => setChangeEmailDialogOpen(false)} />
			<ChangePasswordDialog
				visible={changePasswordDialogOpen}
				onHide={() => setChangePasswordDialogOpen(false)}
			/>
			<ChangeEmailDialog visible={changeEmailDialogOpen} onHide={() => setChangeEmailDialogOpen(false)} />
			<ChangeLanguageDialog
				visible={changeLanguageDialogOpen}
				onHide={() => setChangeLanguageDialogOpen(false)}
			/>
			<div className="flex gap-4">
				<div className="flex flex-column align-items-center gap-2">
					<Avatar src={currentUser?.avatar} name={userDisplayName(currentUser!, null)} size="xl" />
					<Button
						label={t('userPanel.myAccount.changeAvatar')}
						onClick={() => {
							if (setAvatarModalIsOpen) {
								setAvatarModalIsOpen(true);
							}
						}}
						variant="primary-text"
						variantSize="sm"
					/>
				</div>
				<div className="flex-1">
					<Segment title={t('userPanel.myAccount.name')}>
						<div>
							{currentUser?.firstName} {currentUser?.lastName}
						</div>
						<Button
							label={t('userPanel.myAccount.changeName')}
							onClick={() => {
								setChangeNameDialogOpen((prev) => !prev);
							}}
							noPad
							variant="primary-text"
						/>
					</Segment>
					<Segment title={t('userPanel.myAccount.email')}>
						<div className="text-wrap-any">{currentUser?.email}</div>
						<Button
							label={t('userPanel.myAccount.changeEmail')}
							onClick={() => {
								setChangeEmailDialogOpen((prev) => !prev);
							}}
							noPad
							variant="primary-text"
						/>
					</Segment>
					<Segment title={t('userPanel.myAccount.password')}>
						<Button
							label={t('userPanel.myAccount.changePassword')}
							onClick={() => {
								setChangePasswordDialogOpen((prev) => !prev);
							}}
							noPad
							variant="primary-text"
						/>
						<div>
							{twoFactorAuthAdminForced ? (
								<Tooltip
									target="#two-factor-auth-label"
									content={t('userPanel.myAccount.twoFactorAuthAdminForced')}
									position="bottom"
									style={{ maxWidth: 300 }}
								/>
							) : twoFactorAuthProgramForced ? (
								<Tooltip
									target="#two-factor-auth-label"
									content={t('userPanel.myAccount.twoFactorAuthProgramForced')}
									position="bottom"
									style={{ maxWidth: 300 }}
								/>
							) : null}

							<label id="two-factor-auth-label" className="flex flex-row gap-2 align-items-center">
								<Checkbox
									checked={
										twoFactorAuthAdminForced || twoFactorAuthProgramForced || twoFactorAuthEnabled
									}
									onChange={({ checked }) => {
										if (!updateTwoFactorAuthLoading) {
											handleChangeTwoFactorAuth(checked);
										}
									}}
									disabled={
										twoFactorAuthAdminForced ||
										twoFactorAuthProgramForced ||
										updateTwoFactorAuthLoading
									}
								/>
								<span>
									{t('userPanel.myAccount.enableTwoFactorAuth')}{' '}
									{updateTwoFactorAuthLoading && <Spinner size="xs" />}
								</span>
							</label>
						</div>
					</Segment>
					<Segment title={t('userPanel.myAccount.defaultLanguage')}>
						<div>{currentUser?.language}</div>
						<Button
							label={t('userPanel.myAccount.changeLanguage')}
							onClick={() => {
								setChangeLanguageDialogOpen((prev) => !prev);
							}}
							noPad
							variant="primary-text"
						/>
					</Segment>
				</div>
			</div>
		</>
	);
};
