import React, { FC, useRef } from 'react';
import { ButtonClean } from 'components/_new/Button';
import { Menu, MenuItem } from 'components/_new/Menu';
import { OverlayElement } from 'components/_new/Overlay';
import { useNavigate } from 'hooks/useNavigate';
import { fixedT } from 'locale';
import { ProgramWithLanding } from 'types/Program';
import { LandingPageFormsAvailability } from 'types/LandingPageFormsAvailability';

interface GuestMenuProps {
	programData: ProgramWithLanding;
	formsAvailability?: LandingPageFormsAvailability;
}

const GuestMenu: FC<GuestMenuProps> = ({ programData, formsAvailability }) => {
	const menuRef = useRef<OverlayElement>(null);
	const navigate = useNavigate();

	const options = [
		formsAvailability?.isMenteeRecruitmentAvailable && {
			key: 'menteeApplication',
			type: 'button',
			label: fixedT(programData.language, 'landingPages.buttons.menteeApplication'),
			onClick: () => navigate(`apply-mentee`),
		},
		formsAvailability?.isMentorRecruitmentAvailable && {
			key: 'mentorApplication',
			type: 'button',
			label: fixedT(programData.language, 'landingPages.buttons.mentorApplication'),
			onClick: () => navigate(`apply-mentor`),
		},
		{
			key: 'login',
			type: 'button',
			label: fixedT(programData.language, 'auth.buttons.login'),
			onClick: () => navigate('login'),
		},
	].filter(Boolean);

	return (
		<>
			<ButtonClean
				className="rounded-xl flex align-items-center justify-content-center"
				onClick={({ currentTarget }) => {
					menuRef?.current?.toggle(currentTarget);
				}}
				style={{
					width: '2.2rem',
					height: '2.2rem',
					backgroundColor: '#6466e9',
				}}
			>
				<i className="pi pi-user text-white" />
			</ButtonClean>
			<Menu ref={menuRef} items={options as MenuItem[]} />
		</>
	);
};

export default GuestMenu;
