import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ActionGoalId } from 'types/Goal';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import SigningField from 'components/_common/panel/SigningField';
import { Button } from 'primereact/button';
import { usePair } from 'contexts/pairContext';
import { PageLoading } from 'components/PageLoading';
import PageMissing from 'components/PageMissing';
import useGoals from 'hooks/useGoals';
import { userDisplayName } from 'utils/userDisplayName';
import { useAuth } from 'contexts/authContext';
import { CurrentUser } from 'types/CurrentUser';
import { Link } from 'components/_new/Link';
import { classNames } from 'primereact/utils';
import { Page } from 'components/_new/Page';
import GoalItem from './GoalItem';

const GoalsWrapper = () => {
	const { t } = useTranslation();

	const { currentUser } = useAuth();
	const { panelType } = usePanel();
	const { pair } = usePair();
	const { getGoals, saveNewGoal, updateGoal, deleteGoal, signGoals, fetchedGoals, goalLoading, goalError } = useGoals(
		pair.id
	);
	const [currentGoals, setCurrentGoals] = useState(fetchedGoals?.goals);
	const [editedGoal, setEditedGoal] = useState<number | null>(null);
	const { currentProgram, panelLinkPrefix } = usePanel();

	const pairFullName = userDisplayName(pair?.application.programMembership.user);
	const userFullName = userDisplayName(currentUser as CurrentUser);
	const menteeFullName = pair?.application.applicationRole === 'mentee' ? pairFullName : userFullName;

	const addNewGoal = () => {
		getGoals();
		setEditedGoal(ActionGoalId.new);
		setCurrentGoals((prevGoals) => [
			...(prevGoals || []),
			{
				id: ActionGoalId.new,
				goal: '',
				change: '',
				achivmentPath: '',
				priorities: '',
				notes: '',
			},
		]);
	};
	useEffect(() => {
		setCurrentGoals(fetchedGoals?.goals);
	}, [fetchedGoals]);

	useEffect(() => {
		if (editedGoal === null) setCurrentGoals(fetchedGoals?.goals);
	}, [editedGoal, setCurrentGoals, fetchedGoals]);

	if (goalError) {
		return <PageMissing />;
	}

	if (goalLoading || !fetchedGoals || !currentProgram) {
		return <PageLoading />;
	}

	return (
		<>
			<SubPageTitle title={`${t('userPanel.navigation.mentoringPlan')}: ${menteeFullName}`} />
			<Page className="flex flex-column gap-4">
				<div className="flex flex-column gap-4">
					<div className="lh-1-6">
						<div className="mb-4">
							<Trans t={t} i18nKey="userPanel.goal.intro" components={[<b />]} />
						</div>
						<div>
							<Trans
								t={t}
								i18nKey="userPanel.goal.text1"
								components={[
									<b className="text-purplish-blue" />,
									<Link
										to={
											currentProgram.language === 'pl'
												? `/${panelLinkPrefix}/files/techniki-i-narzedzia/cele-w-mentoringu-jak-skutecznie-zdefiniowac-cele-na-proces-mentoringowy`
												: `/${panelLinkPrefix}/files/tools-and-techniques/goals-in-mentoring-how-to-effectively-define-goals-for-the-mentoring-process`
										}
										variant="purple"
										icon="file-lines"
										iconStyle="regular"
										className={classNames('link-doc', 'text-nowrap')}
									/>,
								]}
							/>
						</div>
						<div>
							<Trans
								t={t}
								i18nKey="userPanel.goal.text2"
								components={[<b className="text-purplish-blue" />, <b />]}
							/>
						</div>
						<div>
							<Trans
								t={t}
								i18nKey="userPanel.goal.text3"
								components={[<b className="text-purplish-blue" />, <b />]}
							/>
						</div>
					</div>
				</div>

				<div className="flex flex-column gap-2">
					<div className="font-bold text-xl">{t('userPanel.goal.yourGoals')}</div>

					<div className="flex flex-column gap-2">
						<ul className="flex flex-column gap-4" style={{ paddingLeft: 0 }}>
							{currentGoals?.map((goalData, index) => (
								<GoalItem
									editedGoal={editedGoal}
									getGoals={getGoals}
									saveNewGoal={saveNewGoal}
									updateGoal={updateGoal}
									deleteGoal={deleteGoal}
									key={goalData.id}
									goalData={goalData}
									index={index + 1}
									setEditedGoal={setEditedGoal}
									// bold={index === 0}
								/>
							))}
						</ul>

						{editedGoal === null && (
							<div className="flex align-items-center ml-4 font-bold text-xl">
								<Button onClick={addNewGoal} className="p-1 mr-2">
									<i className="pi pi-plus text-xs" />
								</Button>
								<span>{t('userPanel.contract.addGoal')}</span>
							</div>
						)}
					</div>
				</div>

				<div className="flex flex-column gap-4">
					<div>{t('userPanel.goal.goalsSignedBy')}</div>
					<SigningField
						signContract={signGoals}
						pairFullName={pairFullName}
						userFullName={userFullName}
						menteeSign={fetchedGoals?.goalSignDateMentee}
						mentorSign={fetchedGoals?.goalSignDateMentor}
						panelType={panelType}
					/>
				</div>
			</Page>
		</>
	);
};

export default GoalsWrapper;
